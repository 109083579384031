import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import {convertToJapanCurrency} from "../../shared/constants/global";

export default function IncomeChart(props) {
  const { analysisResult } = props;

  const initOptions = {
    labels: ["給与", "その他収入", "公的年金", "貯蓄", "不動産収入"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: '',
              fontWeight: 600,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              formatter: () => '収入内訳'
            },
            value: {
              show: true,
              label: '',
              fontWeight: 600,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              formatter: () => '収入内訳'
            },
            name: {
              show: false,
            }
          }
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return convertToJapanCurrency(val);
        }
      }
    },
    colors: [function({ value, seriesIndex, w }) {
      if(seriesIndex === 0){
        return '#1977E7';
      }else if(seriesIndex === 1) {
        return '#2288EE';
      }else if(seriesIndex === 2) {
        return '#3D99F6';
      }else if(seriesIndex === 3) {
        return '#4AA1F9';
      }else if(seriesIndex === 4) {
        return '#72B7FC';
      }else{
        return '#72B7FC';
      }
    }]
  };

  const initData = [4, 5, 6, 1, 5];

  const [data, setData] = useState([ ...initData ]);
  const [options, setOptions] = useState({ ...initOptions })

  const convertTextCategory = (category_id) => {
    switch (category_id) {
      case 1:
        return "給与"
      case 2:
        return "その他収入"
      case 3:
        return "公的年金"
      case 4:
        return "試算開始時預金額"
      case 5:
        return "不動産収入"
    }
  }

  const calculatorChart = (results) => {
    const data = []
    const labels = []

    results.map(result => {
      if(result.category !== 4 && result.value !== 0 ) {
        labels.push(convertTextCategory(result.category))
        data.push(result.value)
      }
    })

    const options = { labels: labels }

    return { data, options }
  }

  useEffect(async () => {
    try {
      const { data, options } = calculatorChart(analysisResult);
      setOptions(options)
      setData(data)
    } catch (e) {
      console.log(e)
    }
  }, [analysisResult])

  return <React.Fragment>
    <div id="chart">
      <Chart options={options} series={data} type="donut" height="350" />
    </div>
  </React.Fragment>
}
