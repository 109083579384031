import React from "react";
import { stringify, parse } from 'flatted/cjs'
import FunctionStep from "../chatbot/steps_components/custom/FunctionStep";
import OptionStep from "../chatbot/steps_components/custom/OptionStep";
import MultipleOptionStep from "../chatbot/steps_components/custom/MultipleOptionStep";
import InputStep from "../chatbot/steps_components/custom/InputStep";
import SelectDisplayOptionStep from "../chatbot/steps_components/custom/SelectDisplayOptionStep";
import SelectStep from "../chatbot/steps_components/custom/SelectStep";
import AddressForm from "../chatbot/steps_components/custom/AddressForm";
import CustomerAddressForm from "../chatbot/steps_components/custom/CustomerAddressForm";
import StationForm from "../chatbot/steps_components/custom/StationForm"
import RentForm from "../chatbot/steps_components/custom/RentForm"
import CustomerNameForm from "../chatbot/steps_components/custom/CustomerNameForm"
import Summary from "../chatbot/steps_components/custom/Summary"
import SubmitBtn from "../chatbot/steps_components/custom/SubmitBtn"
import { LifePlanService } from '../core/services/life-plans';
import { YupSchema } from '../shared/constants/chatbot-validators';
import { ChatBotImages } from '../assets/images';
const { mascot3 } = ChatBotImages;

const dataBuildingStructure = [
  {
    id: "",
    code: "",
    name: "選択してください"
  },
  {
    id: 1,
    code: 1,
    name: "SRC"
  },
  {
    id: 2,
    code: 2,
    name: "RC"
  },
  {
    id: 3,
    code: 3,
    name: "木造"
  },
  {
    id: 4,
    code: 4,
    name: "鉄骨"
  },
  {
    id: 6,
    code: 6,
    name: "軽量鉄骨造"
  },
  {
    id: 5,
    code: 5,
    name: "その他"
  }
]

const initAddress = {
  pref_code: '',
  prefecture: '',
  city_code: '',
  city: '',
  region_code: '',
  region: '',
  after_town: '',
}

const initStation = {
  line_code: '',
  line_name: '',
  station_code: '',
  station_name: '',
  walking_minutes: '',
}

const initCustomerAddress = {
  customer_zip_code: '',
  customer_prefecture: '',
  customer_city: '',
}

const initCustomerName = {
  last_name: '',
  first_name: '',
  last_name_kana: '',
  first_name_kana: '',
}

const initRentForm = {
  rental_status_total: '',
  rental_status_operation: '',
  rental_status_remarks: '',
}

const dataBuiltDate = () => {
  const currentYear = new Date().getFullYear();

  let result = []
  for (let i = 1950; i <= currentYear; i++) {
    result.push(i)
  }
  return result;
}

const renderBuiltDate = (year) => {
  const year_built = new Date().getFullYear() - year
  if (year_built == 0) {
    return `${year}年`
  } else {
    return `${year}年（築${year_built}年）`
  }
}

const handleAiSaleSubmit = async(formType) => {
  $("#preloader").fadeIn();
  const data = JSON.parse(window.lsSession.getItem(`chatbot-${formType}`))
  const prepareData = { ...data, life_event_flg: "0", built_date: data.built_date + "01" }
  const result = await LifePlanService.propertyAssessmentAndRegisterResult(prepareData);

  if (typeof(result.response) !== typeof(undefined)) {
    window.lsSession.setItem("ai_sale_result", JSON.stringify(result.response.data.result))
    window.lsSession.setItem("ai_sale", JSON.stringify(prepareData))
    window.location.href = "/ai_sale"
  }
  $("#preloader").fadeOut();
}

const handleContactSubmit = async(formType) => {
  $("#preloader").fadeIn();
  const data = JSON.parse(window.lsSession.getItem(`chatbot-${formType}`))
  const prepareData = { ...data, category: "1" }
  const result = await LifePlanService.inputContactInfo(prepareData);

  if (typeof(result.response) !== typeof(undefined)) {
    window.location.href = "/contact/contact_sales/finish"
  }
  $("#preloader").fadeOut();
}

export const start = () => {
  return [
    {
      id: 'start',
      message: 'ようこそいらっしゃいました。<br />私は100年後の未来からやってきたAIロボットです。',
      trigger: 'start-1',
    },
    {
      id: 'start-1',
      message: 'AI査定とお問い合わせのどちらをご希望ですか？',
      trigger: 'start-Opt',
    },
    {
      id: 'start-Opt',
      waitAction: true,
      component: (
        <FunctionStep
          title="ご用件"
          name="function_type"
          class="content wid245"
          options={[
            { value: 1, label: "AI査定", trigger: "aiSale" },
            { value: 2, label: "お問い合わせ", trigger: "contact" },
          ]}
          default="1"
        />
      ),
    }
  ]
}

export const aiSale = () => {
  return [
    {
      id: 'aiSale',
      message: 'AI査定ですね。<br />AI査定にあたって、いくつかご質問させていただきます。<br />しばらくお付き合いください。',
      trigger: 'aiSale-1'
    },
    {
      id: 'aiSale-1',
      message: '物件種別は何でしょうか？',
      trigger: 'aiSale-Opt'
    },
    {
      id: 'aiSale-Opt',
      waitAction: true,
      component: (
        <OptionStep
          formType="aiSale"
          title="物件タイプ"
          name="property_type"
          class="content wid245"
          initialValues={{'property_type': 1}}
          options={[
            { value: 1, label: "区分", trigger: "category" },
            { value: 2, label: "一棟", trigger: "building" },
          ]}
        />
      ),
    }
  ]
}

export const category = (t) => {
  const buildingStructureValidate = new YupSchema(t).buildingStructure;
  const builtDateValidate = new YupSchema(t).builtDate;
  const occupationAreaValidate = new YupSchema(t).occupationArea;
  const floorLocatedValidate = new YupSchema(t).floorLocated;

  return [
    {
      id: 'category',
      message: '住所をご入力ください。',
      trigger: 'category-Address'
    },
    {
      id: 'category-Address',
      waitAction: true,
      component: (
        <AddressForm
          initialValues={initAddress}
          formType="category"
          class="content wid500"
          trigger="category-Address-Sm"
        />
      ),
    },
    {
      id: 'category-Address-Sm',
      message: '路線・駅・徒歩分数をご選択ください。',
      trigger: 'category-Station'
    },
    {
      id: 'category-Station',
      waitAction: true,
      component: (
        <StationForm
          initialValues={initStation}
          formType="category"
          class="content wid500"
          trigger="category-Station-Sm"
        />
      ),
    },
    {
      id: 'category-Station-Sm',
      message: '建物構造をご選択ください。',
      trigger: 'category-BuildingStructure'
    },
    {
      id: 'category-BuildingStructure',
      waitAction: true,
      component: (
        <SelectDisplayOptionStep
          formType="category"
          title="建物構造"
          name="building_structure"
          options={dataBuildingStructure}
          validate={buildingStructureValidate}
          initialValues={{'building_structure': ''}}
          trigger="category-BuildingStructure-Sm"
        />
      ),
    },
    {
      id: 'category-BuildingStructure-Sm',
      message: '築年数をご選択ください。',
      trigger: 'category-BuiltDate'
    },
    {
      id: 'category-BuiltDate',
      waitAction: true,
      component: (
        <SelectStep
          formType="category"
          title="築年数"
          name="built_date"
          options={dataBuiltDate()}
          validate={builtDateValidate}
          initialValues={{'built_date': ''}}
          renderOption={renderBuiltDate}
          trigger="category-BuiltDate-Sm"
        />
      ),
    },
    {
      id: 'category-BuiltDate-Sm',
      message: '専有面積をご選択ください。',
      trigger: 'category-OccupationArea'
    },
    {
      id: 'category-OccupationArea',
      waitAction: true,
      component: (
        <InputStep
          formType="category"
          title="専有面積"
          name="occupation_area"
          validate={occupationAreaValidate}
          initialValues={{'occupation_area': ''}}
          trigger="category-OccupationArea-Sm"
        />
      ),
    },
    {
      id: 'category-OccupationArea-Sm',
      message: '所在階をご入力ください。',
      trigger: 'category-FloorLocated'
    },
    {
      id: 'category-FloorLocated',
      waitAction: true,
      component: (
        <InputStep
          formType="category"
          title="所在階"
          name="floor_located"
          validate={floorLocatedValidate}
          initialValues={{'floor_located': ''}}
          trigger="category-FloorLocated-Sm"
        />
      ),
    },
    {
      id: 'category-FloorLocated-Sm',
      message: '最後の設問でございます。<br />購入時期をご選択ください。',
      trigger: 'category-PurchaseYear'
    },
    {
      id: 'category-PurchaseYear',
      waitAction: true,
      component: (
        <SelectStep
          formType="category"
          title="購入時期"
          name="purchase_year"
          options={dataBuiltDate()}
          initialValues={{'purchase_year': ''}}
          renderOption={renderBuiltDate}
          trigger="category-one-more-step-msg"
        />
      ),
    },
    // One more step
    {
      id: 'category-one-more-step-msg',
      message: 'ご入力ありがとうございました！',
      trigger: 'category-one-more-step-img',
    },
    {
      id: 'category-one-more-step-img',
      message: `<img class="wid100p" src="${mascot3}"></img>`,
      class: 'wid200',
      trigger: 'category-PurchaseYear-Sm',
    },
    {
      id: 'category-PurchaseYear-Sm',
      message: '査定結果はこちらです。',
      trigger: 'viewResultCategory'
    },
    {
      id: 'viewResultCategory',
      component: (
        <SubmitBtn
          formType="category"
          type="submitBtn"
          text="査定結果を見る"
          func={handleAiSaleSubmit}
        />
      ),
    }
  ]
}

export const building = (t) => {
  const buildingStructureValidate = new YupSchema(t).buildingStructure;
  const builtDateValidate = new YupSchema(t).builtDate;
  const numberOfFloorsValidate = new YupSchema(t).numberOfFloors;
  const totalOfFloorsValidate = new YupSchema(t).totalOfFloors;
  const numberOfHousesValidate = new YupSchema(t).numberOfHouses;

  return [
    {
      id: 'building',
      message: '住所をご入力ください。',
      trigger: 'building-Address'
    },
    {
      id: 'building-Address',
      waitAction: true,
      component: (
        <AddressForm
          initialValues={initAddress}
          formType="building"
          class="content wid500"
          trigger="building-Address-Sm"
        />
      ),
      trigger: 'building-Address-Sm'
    },
    {
      id: 'building-Address-Sm',
      message: '路線・駅・徒歩分数をご選択ください。',
      trigger: 'building-Station'
    },
    {
      id: 'building-Station',
      waitAction: true,
      component: (
        <StationForm
          initialValues={initStation}
          formType="building"
          class="content wid500"
          trigger="building-Station-Sm"
        />
      ),
    },
    {
      id: 'building-Station-Sm',
      message: '建物構造をご選択ください。',
      trigger: 'building-BuildingStructure'
    },
    {
      id: 'building-BuildingStructure',
      waitAction: true,
      component: (
        <SelectDisplayOptionStep
          formType="building"
          title="建物構造"
          name="building_structure"
          options={dataBuildingStructure}
          initialValues={{'building_structure': ''}}
          validate={buildingStructureValidate}
          trigger="building-BuildingStructure-Sm"
        />
      ),
    },
    {
      id: 'building-BuildingStructure-Sm',
      message: '築年数をご選択ください。',
      trigger: 'building-BuiltDate'
    },
    {
      id: 'building-BuiltDate',
      waitAction: true,
      component: (
        <SelectStep
          formType="building"
          title="築年数"
          name="built_date"
          options={dataBuiltDate()}
          validate={builtDateValidate}
          initialValues={{'built_date': ''}}
          renderOption={renderBuiltDate}
          trigger="building-BuiltDate-Sm"
        />
      ),
    },
    {
      id: 'building-BuiltDate-Sm',
      message: '総階数をご選択ください。',
      trigger: 'building-NumberOfFloors'
    },
    {
      id: 'building-NumberOfFloors',
      waitAction: true,
      component: (
        <InputStep
          formType="building"
          title="総階数"
          name="number_of_floors"
          validate={numberOfFloorsValidate}
          initialValues={{'number_of_floors': ''}}
          trigger="building-NumberOfFloors-Sm"
        />
      ),
    },
    {
      id: 'building-NumberOfFloors-Sm',
      message: '建物の賃貸面積をご入力ください。',
      trigger: 'building-TotalOfFloors'
    },
    {
      id: 'building-TotalOfFloors',
      waitAction: true,
      component: (
        <InputStep
          formType="building"
          title="建物の賃貸面積"
          name="total_floor_space"
          validate={totalOfFloorsValidate}
          initialValues={{'total_floor_space': ''}}
          trigger="building-TotalOfFloors-Sm"
        />
      ),
    },
    {
      id: 'building-TotalOfFloors-Sm',
      message: '総戸数をご入力ください。',
      trigger: 'building-NumberOfHouses'
    },
    {
      id: 'building-NumberOfHouses',
      waitAction: true,
      component: (
        <InputStep
          formType="building"
          title="総戸数"
          name="number_of_houses"
          validate={numberOfHousesValidate}
          initialValues={{'number_of_houses': ''}}
          trigger="building-NumberOfHouses-Sm"
        />
      ),
    },
    {
      id: 'building-NumberOfHouses-Sm',
      message: '最後の設問でございます。<br />購入時期をご選択ください。',
      trigger: 'building-PurchaseYear'
    },
    {
      id: 'building-PurchaseYear',
      waitAction: true,
      component: (
        <SelectStep
          formType="building"
          title="購入時期"
          name="purchase_year"
          options={dataBuiltDate()}
          initialValues={{'purchase_year': ''}}
          renderOption={renderBuiltDate}
          trigger="building-one-more-step-msg"
        />
      ),
    },
    // One more step
    {
      id: 'building-one-more-step-msg',
      message: 'ご入力ありがとうございました！',
      trigger: 'building-one-more-step-img',
    },
    {
      id: 'building-one-more-step-img',
      message: `<img class="wid100p" src="${mascot3}"></img>`,
      class: 'wid200',
      trigger: 'building-PurchaseYear-Sm',
    },
    {
      id: 'building-PurchaseYear-Sm',
      message: '査定結果はこちらです。',
      trigger: 'viewResultBuiding'
    },
    {
      id: 'viewResultBuiding',
      component: (
        <SubmitBtn
          formType="building"
          type="submitBtn"
          text="査定結果を見る"
          func={handleAiSaleSubmit}
        />
      ),
    },
  ]
}

export const contact = (t) => {
  const companyValidate = new YupSchema(t).company;
  const emailValidate = new YupSchema(t).email;
  const phoneNumberValidate = new YupSchema(t).phoneNumber;

  return [
    {
      id: 'contact',
      message: 'お問い合わせにあたって、<br />査定する物件の情報を教えてください。',
      trigger: 'contact1'
    },
    {
      id: 'contact1',
      message: '物件種別は何でしょうか？',
      trigger: 'contact-Opt'
    },
    {
      id: 'contact-Opt',
      waitAction: true,
      component: (
        <OptionStep
          formType="contact"
          title="物件タイプ"
          name="property_category"
          class="content wid245"
          initialValues={{'property_category': 1}}
          options={[
            { value: 1, label: "マンション（区分）", trigger: "pre-sale-reason" },
            { value: 2, label: "マンション（一棟）", trigger: "pre-sale-reason" },
            { value: 3, label: "アパート", trigger: "pre-sale-reason" },
            { value: 4, label: "一戸建て", trigger: "pre-sale-reason" },
            { value: 5, label: "土地", trigger: "pre-sale-reason" },
            { value: 6, label: "その他", trigger: "pre-sale-reason" },
          ]}
        />
      ),
    },
    {
      id: 'pre-rent-Option',
      message: '賃貸状況をご選択ください。',
      trigger: 'rent-Option'
    },
    {
      id: 'rent-Option',
      waitAction: true,
      component: (
        <OptionStep
          formType="contact"
          title="賃貸状況"
          name="rental_status_segment"
          class="content wid245"
          initialValues={{'rental_status_segment': ''}}
          options={[
            { value: 1, label: "稼働中", trigger: "pre-sale-reason" },
            { value: 2, label: "空室", trigger: "pre-sale-reason" },
          ]}
        />
      ),
    },
    {
      id: 'pre-rent-Form',
      message: '賃貸状況をご選択ください。',
      trigger: 'rent-Form'
    },
    {
      id: 'rent-Form',
      waitAction: true,
      component: (
        <RentForm
        initialValues={initRentForm}
          formType="contact"
          class="content wid500"
          trigger="pre-sale-reason"
        />
      ),
    },
    {
      id: 'pre-sale-reason',
      message: '売却理由を教えてください。<br />（※複数選択できます）',
      trigger: 'reason_for_sale'
    },
    {
      id: 'reason_for_sale',
      waitAction: true,
      component: (
        <MultipleOptionStep
          formType="contact"
          title="売却理由"
          name="reason_for_sale"
          class="content wid300"
          initialValues={{'reason_for_sale': []}}
          options={[
            { value: 1, label: "相続対策" },
            { value: 2, label: "高値での売却見込み" },
            { value: 3, label: "資金の回収" },
            { value: 4, label: "買い替えの検討" },
            { value: 5, label: "空室や維持費用の懸念" },
            { value: 6, label: "その他" },
          ]}
          trigger="pre-Address"
          otherTrigger="other-Reason"
        />
      ),
    },
    {
      id: 'other-Reason',
      waitAction: true,
      component: (
        <InputStep
          type="text"
          formType="contact"
          title="その他"
          name="other_sale_reason"
          initialValues={{'other_sale_reason': ''}}
          trigger="pre-Address"
        />
      ),
    },
    {
      id: 'pre-Address',
      message: '物件の住所を教えてください。',
      trigger: 'address'
    },
    {
      id: 'address',
      waitAction: true,
      component: (
        <AddressForm
          initialValues={initAddress}
          formType="contact"
          class="content wid500"
          trigger="address-Sm"
        />
      ),
    },
    {
      id: 'address-Sm',
      message: 'ご入力ありがとうございます！<br />物件についての質問は以上です。<br /><br />残りはあと「４問」です。',
      trigger: 'one-more-step-img-1'
    },
    {
      id: 'one-more-step-img-1',
      message: `<img class="wid100p" src="${mascot3}"></img>`,
      class: 'wid200',
      trigger: 'next-step',
    },
    {
      id: 'next-step',
      message: '続きまして、お客様の情報を教えてください。',
      trigger: 'pre-individual_or_corporate'
    },
    {
      id: 'pre-individual_or_corporate',
      message: '個人ですか？法人ですか？',
      trigger: 'individual_or_corporate'
    },
    {
      id: 'individual_or_corporate',
      waitAction: true,
      component: (
        <OptionStep
          formType="contact"
          title="個人/法人"
          name="individual_or_corporate"
          class="content wid245"
          initialValues={{'individual_or_corporate': 1}}
          options={[
            { trigger: "pre-fullname", value: 1, label: "個人" },
            { trigger: "pre-company", value: 2, label: "法人" },
          ]}
        />
      ),
    },
    {
      id: 'pre-company',
      message: '会社名を教えてください。',
      trigger: 'company'
    },
    {
      id: 'company',
      waitAction: true,
      component: (
        <InputStep
          type="text"
          formType="contact"
          title="会社名"
          name="company"
          validate={companyValidate}
          initialValues={{'company': ''}}
          trigger="pre-fullname"
        />
      ),
    },
    {
      id: 'pre-fullname',
      message: 'お名前を教えてください。',
      trigger: 'fullname'
    },
    {
      id: 'fullname',
      waitAction: true,
      component: (
        <CustomerNameForm
          initialValues={initCustomerName}
          formType="contact"
          class="content wid500"
          trigger="pre-email"
        />
      ),
    },
    {
      id: 'pre-email',
      message: 'メールアドレスを教えてください。',
      trigger: 'email'
    },
    {
      id: 'email',
      waitAction: true,
      component: (
        <InputStep
          type="text"
          formType="contact"
          title="メールアドレス"
          name="email"
          validate={emailValidate}
          initialValues={{'email': ''}}
          trigger="pre-phone-number"
        />
      ),
    },
    {
      id: 'pre-phone-number',
      message: '電話番号を教えてください。<br/>（※ハイフンはご入力不要です）',
      trigger: 'phone-number'
    },
    {
      id: 'phone-number',
      waitAction: true,
      component: (
        <InputStep
          formType="contact"
          title="電話番号"
          name="phone_number"
          validate={phoneNumberValidate}
          initialValues={{'phone_number': ''}}
          trigger="contact-one-more-step-msg"
        />
      ),
    },
    // {
    //   id: 'pre-customer-address',
    //   message: 'ご住所をご入力ください。',
    //   trigger: 'customer-address'
    // },
    // {
    //   id: 'customer-address',
    //   waitAction: true,
    //   component: (
    //     <CustomerAddressForm
    //       initialValues={initCustomerAddress}
    //       formType="contact"
    //       class="content wid500"
    //       trigger="contact-one-more-step-msg"
    //     />
    //   ),
    // },
    // One more step
    {
      id: 'contact-one-more-step-msg',
      message: 'ご入力ありがとうございました！',
      trigger: 'one-more-step-img-2',
    },
    {
      id: 'one-more-step-img-2',
      message: `<img class="wid100p" src="${mascot3}"></img>`,
      class: 'wid200',
      trigger: 'contact-confirm',
    },
    {
      id: 'contact-confirm',
      message: '以上の入力内容でよろしいですか？',
      trigger: 'summary'
    },
    {
      id: 'summary',
      class: 'content wid500',
      component: (
        <Summary />
      ),
      asMessage: true,
      trigger: 'summary-confirm'
    },
    {
      id: 'summary-confirm',
      message: 'よろしければ、問い合わせ実行ボタンを押してください。<br />修正する場合は、修正したい設問に戻ってもう一度入力してください。',
      trigger: 'viewContactResult'
    },
    {
      id: 'viewContactResult',
      component: (
        <SubmitBtn
          formType="contact"
          type="submitBtn"
          text="問い合わせ実行"
          func={handleContactSubmit}
        />
      ),
    },
  ]
}
