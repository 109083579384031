export const LifePlansImages = {
    aboutCompany: require("../../../../assets/images/about_company-bg.png"),
    aboutInfoBg: require("../../../../assets/images/about_info-bg.jpeg"),
    aboutSolutionBg: require("../../../../assets/images/about_solution-bg.jpg"),
    aboutSolutionBgPic1: require("../../../../assets/images/about_solution-pic1.png"),
    aboutSolutionBgPic2: require("../../../../assets/images/about_solution-pic2.png"),
    aboutSolutionBgPic3: require("../../../../assets/images/about_solution-pic3.png"),
    aboutSolutionBgPic5: require("../../../../assets/images/about_solution-pic5.png"),
    aboutSolutionBgPic6: require("../../../../assets/images/about_solution-pic6.png"),
    aboutStrengthBg: require("../../../../assets/images/about_solution-pic6.png"),
    aboutBg: require("../../../../assets/images/about-bg.jpeg"),
    aboutPic1: require("../../../../assets/images/about-pic1.png"),
    aboutPic2: require("../../../../assets/images/about-pic2.png"),
    aboutPic3: require("../../../../assets/images/about-pic3.png"),
    aiResultBg: require("../../../../assets/images/ai_result-bg.jpeg"),
    aiBg: require("../../../../assets/images/ai-bg.jpg"),
    aiPic: require("../../../../assets/images/ai-pic.png"),
    areaInfo1: require("../../../../assets/images/area-info1.png"),
    areaInfo2: require("../../../../assets/images/area-info2.png"),
    arrowBGrad: require("../../../../assets/images/arrow_b_grad.png"),
    // axisX: require("../../../../assets/images/axis-x.png"),
    caseDet: require("../../../../assets/images/case-det.jpg"),
    caseManager: require("../../../../assets/images/case-manager.png"),
    casePic1: require("../../../../assets/images/case-pic1.png"),
    casePic2: require("../../../../assets/images/case-pic2.png"),
    closeSvg: require("../../../../assets/images/close.svg"),
    commentCustomer: require("../../../../assets/images/comment_customer.png"),
    commentStaff: require("../../../../assets/images/comment_staff.png"),
    commentManager1: require("../../../../assets/images/comment-manager1.png"),
    commentManager2: require("../../../../assets/images/comment-manager2.png"),
    // contactBg: require("../../../../assets/images/contact-bg.jpg"),
    expandLessSvg: require("../../../../assets/images/expand-less.svg"),
    expandMoreSvg: require("../../../../assets/images/expand-more.svg"),
    floorPlan: require("../../../../assets/images/floor_plan.png"),
    // footerContactBg: require("../../../../assets/images/footer-contact-bg.jpg"),
    imgCar1: require("../../../../assets/images/img-car-1.png"),
    imgCar2: require("../../../../assets/images/img-car-2.png"),
    imgCar3: require("../../../../assets/images/img-car-3.png"),
    imgCar4: require("../../../../assets/images/img-car-4.png"),
    imgCar5: require("../../../../assets/images/img-car-5.png"),
    imgCar6: require("../../../../assets/images/img-car-6.png"),
    imgCar7: require("../../../../assets/images/img-car-7.png"),
    imgCar8: require("../../../../assets/images/img-car-8.jpg"),
    imgFamily1: require("../../../../assets/images/img-family-1.jpg"),
    imgFamily2: require("../../../../assets/images/img-family-2.jpg"),
    imgFamily3: require("../../../../assets/images/img-family-3.jpg"),
    imgFamily4: require("../../../../assets/images/img-family-4.jpg"),
    imgFamily5: require("../../../../assets/images/img-family-5.jpg"),
    imgFreeOnlineConsultation: require("../../../../assets/images/img-free-online-consultation.jpg"),
    imgHobby1: require("../../../../assets/images/img-hobby-1.jpg"),
    imgHobby2: require("../../../../assets/images/img-hobby-2.jpg"),
    imgHobby3: require("../../../../assets/images/img-hobby-3.jpg"),
    imgHobby4: require("../../../../assets/images/img-hobby-4.jpg"),
    imgHobby5: require("../../../../assets/images/img-hobby-5.jpg"),
    imgHobby6: require("../../../../assets/images/img-hobby-6.jpg"),
    imgHobby7: require("../../../../assets/images/img-hobby-7.jpg"),
    imgHobby8: require("../../../../assets/images/img-hobby-8.jpg"),
    imgHobby9: require("../../../../assets/images/img-hobby-9.jpg"),
    imgHobby10: require("../../../../assets/images/img-hobby-10.jpg"),
    imgHobby11: require("../../../../assets/images/img-hobby-11.jpg"),
    imgHobby12: require("../../../../assets/images/img-hobby-12.jpg"),
    imgHobby13: require("../../../../assets/images/img-hobby-13.jpg"),
    imgHobby14: require("../../../../assets/images/img-hobby-14.jpg"),
    imgHobby15: require("../../../../assets/images/img-hobby-15.jpg"),
    imgPet1: require("../../../../assets/images/img-pet-1.jpg"),
    imgPet2: require("../../../../assets/images/img-pet-2.jpg"),
    imgPet3: require("../../../../assets/images/img-pet-3.jpg"),
    imgPet4: require("../../../../assets/images/img-pet-4.jpg"),
    imgPet5: require("../../../../assets/images/img-pet-5.jpg"),
    imgPet6: require("../../../../assets/images/img-pet-6.jpg"),
    imgPet7: require("../../../../assets/images/img-pet-7.jpg"),
    imgPet8: require("../../../../assets/images/img-pet-8.jpg"),
    imgRealestate1: require("../../../../assets/images/img-realestate-1.png"),
    imgRealestate2: require("../../../../assets/images/img-realestate-2.png"),
    imgRealestate3: require("../../../../assets/images/img-realestate-3.png"),
    imgRealestate4: require("../../../../assets/images/img-realestate-4.png"),
    imgRealestate5: require("../../../../assets/images/img-realestate-5.png"),
    imgRealestate6: require("../../../../assets/images/img-realestate-6.png"),
    imgRealestate7: require("../../../../assets/images/img-realestate-7.png"),
    imgRealestate8: require("../../../../assets/images/img-realestate-8.png"),
    imgRealestate9: require("../../../../assets/images/img-realestate-9.png"),
    imgRealestate10: require("../../../../assets/images/img-realestate-10.png"),
    imgRealestate11: require("../../../../assets/images/img-realestate-11.png"),
    imgRealestate12: require("../../../../assets/images/img-realestate-12.jpg"),
    imgRegistInfo: require("../../../../assets/images/img-regist-info.png"),
    imgTravel1: require("../../../../assets/images/img-travel-1.jpg"),
    imgTravel2: require("../../../../assets/images/img-travel-2.jpg"),
    imgTravel3: require("../../../../assets/images/img-travel-3.jpg"),
    imgTravel4: require("../../../../assets/images/img-travel-4.jpg"),
    imgTravel5: require("../../../../assets/images/img-travel-5.jpg"),
    imgTravel6: require("../../../../assets/images/img-travel-6.jpg"),
    imgTravel7: require("../../../../assets/images/img-travel-7.jpg"),
    imgTravel8: require("../../../../assets/images/img-travel-8.jpg"),
    imgTravel9: require("../../../../assets/images/img-travel-9.jpg"),
    imgTravel10: require("../../../../assets/images/img-travel-10.jpg"),
    imgTravel11: require("../../../../assets/images/img-travel-11.jpg"),
    imgTravel12: require("../../../../assets/images/img-travel-12.jpg"),
    logoOdakyuPro: require("../../../../assets/images/logo-odakyu_pro.png"),
    logo: require("../../../../assets/images/logo.svg"),
    lpsBuyHousing: require("../../../../assets/images/lps-buy_housing.png"),
    lpsBuyInvesment: require("../../../../assets/images/lps-buy_investment.png"),
    lpsCar:  require("../../../../assets/images/lps-car.png"),
    lpsEatout: require("../../../../assets/images/lps-eatout.png"),
    lpsFamily: require("../../../../assets/images/lps-family.png"),
    lpsGraphDefault: require("../../../../assets/images/lps-graph-default.png"),
    lpsGraph: require("../../../../assets/images/lps-graph.png"),
    lpsHobby: require("../../../../assets/images/lps-hobby.png"),
    lpsIncomeGraph: require("../../../../assets/images/lps-income_graph.png"),
    lpsLifeEventPC: require("../../../../assets/images/lps-life_event_pc.png"),
    lpsLifeEventSP: require("../../../../assets/images/lps-life_event_sp.png"),
    lpsNet: require("../../../../assets/images/lps-pet.png"),
    lpsSecondCarrier: require("../../../../assets/images/lps-secondcarrier.png"),
    lpsSellHousing: require("../../../../assets/images/lps-sell_housing.png"),
    lpsSellInvesment: require("../../../../assets/images/lps-sell_investment.png"),
    lpsSpendingGraph: require("../../../../assets/images/lps-spending_graph.png"),
    lpsTrip: require("../../../../assets/images/lps-trip.png"),
    lpsVsGraphPC: require("../../../../assets/images/lps-vs_graph_pc.png"),
    lpsVsGraphSP: require("../../../../assets/images/lps-vs_graph_sp.png"),
    mvCopy: require("../../../../assets/images/mv-copy.png"),
    mv: require("../../../../assets/images/mv.jpg"),
    propertyPhoto1: require("../../../../assets/images/property_photo1.jpg"),
    propertyPhoto2: require("../../../../assets/images/property_photo2.jpg"),
    propertyPhoto3: require("../../../../assets/images/property_photo3.jpg"),
    propertyPhoto4: require("../../../../assets/images/property_photo4.jpg"),
    propertyPhoto5: require("../../../../assets/images/property_photo5.jpg"),
    propertyPhoto6: require("../../../../assets/images/property_photo6.jpg"),
    propertyInfo: require("../../../../assets/images/property-info.png"),
    property: require("../../../../assets/images/property.jpg"),
    saleAiGraph1_2: require("../../../../assets/images/sale-ai_graph1_2.png"),
    saleAiGraph1: require("../../../../assets/images/sale-ai_graph1.png"),
    saleAiGraph2: require("../../../../assets/images/sale-ai_graph2.png"),
    shopInfo: require("../../../../assets/images/shop-info.png"),
    // simulationBg: require("../../../../assets/images/simulation-bg.jpg"),
    simulationPic: require("../../../../assets/images/simulation-pic.png"),
    imgEatout1: require("../../../../assets/images/img-eatout-1.jpg"),
    imgEatout2: require("../../../../assets/images/img-eatout-2.jpg"),
    imgEatout3: require("../../../../assets/images/img-eatout-3.jpg"),
    imgEatout4: require("../../../../assets/images/img-eatout-4.jpg"),
    imgEatout5: require("../../../../assets/images/img-eatout-5.jpg"),
    imgEatout6: require("../../../../assets/images/img-eatout-6.jpg"),
    imgEatout7: require("../../../../assets/images/img-eatout-7.jpg"),
    imgSecondCarrier1: require("../../../../assets/images/img-secondcarrier-1.jpg"),
    imgSecondCarrier2: require("../../../../assets/images/img-secondcarrier-2.jpg"),
    imgSecondCarrier3: require("../../../../assets/images/img-secondcarrier-3.jpg"),
    imgSecondCarrier4: require("../../../../assets/images/img-secondcarrier-4.jpg"),
    imgSecondCarrier5: require("../../../../assets/images/img-secondcarrier-5.jpg"),
    imgSecondCarrier6: require("../../../../assets/images/img-secondcarrier-6.jpg"),
    imgSecondCarrier7: require("../../../../assets/images/img-secondcarrier-7.jpg"),
    imgSecondCarrier8: require("../../../../assets/images/img-secondcarrier-8.jpg"),
    imgSecondCarrier9: require("../../../../assets/images/img-secondcarrier-9.png"),
    imgSecondCarrier10: require("../../../../assets/images/img-secondcarrier-10.png"),
};

export const ChatBotImages = {
    userBot: require("../../../../assets/images/user.png"),
    mascot1: require("../../../../assets/images/logo.svg"),
    mascot2: require("../../../../assets/images/chatbot-mascot2.png"),
    mascot3: require("../../../../assets/images/mascot3.svg"),
};
