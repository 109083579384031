import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import icoBuy from 'images/ico-buy.png';
import icoSell from 'images/ico-sell.png';
import { setLifeEventSelected, setIsDragging as setIsGlobalDragging, showSelectedModel } from '../../../redux/lifePlan';
import { getEventMapping } from '../../../shared/constants/global';

export default function EventItem(props) {
  const [isDragging, setIsDragging] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const dispatch = useDispatch()

  const handleDragStart = () => {
    setIsDragging(true)
    dispatch(setLifeEventSelected(props.event))
    document.querySelector("#stkr").style.display = "none" // FIXME: remove this line

    setTimeout(() => {
      setIsHide(true)
      dispatch(setIsGlobalDragging(true))
    }, 0);
  }

  const handleDragEnd = () => {
    setLifeEventSelected(null)
    setIsDragging(false)
    dispatch(setIsGlobalDragging(false))
    setIsHide(false)
  }

  const handleOpenModal = () => {
    dispatch(setLifeEventSelected(props.event))
    dispatch(showSelectedModel())
  }

  const [eventMapping, setEventMapping] = useState(getEventMapping(props.event))
  const [amount, setAmount] = useState(eventMapping.getAmount(props.event))
  const [eventTitle, setEventTitle] = useState(eventMapping.getEventTitle(props.event))

  const getEventName = (event) => {
    if (event.custom) return event.custom

    return eventMapping.name
  }


  useEffect(() => {
    setEventMapping(getEventMapping(props.event))
    setAmount(eventMapping.getAmount(props.event))
    setEventTitle(eventMapping.getEventTitle(props.event))
  }, [props.event])

  return (
    <React.Fragment>
      <div
        className={["timelineEvent", isDragging && "dragging", isHide && "hide"].filter(e => !!e).join(' ')}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleOpenModal}
      >
        { eventMapping.is_buy && <img src={icoBuy} alt="BUY" className="ico" /> }
        { eventMapping.is_sell && <img src={icoSell} alt="BUY" className="ico" /> }
        <div className="eventImage" style={{"backgroundImage": `url(${require("images/lps-" + getEventMapping(props.event).type_name + ".png")})`}} />
        <div className="eventTitle">{eventTitle || getEventName(props.event)}</div>
        <div className="eventAmount">{amount}</div>
      </div>
    </React.Fragment>
  )
}
