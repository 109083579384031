import React, { useState } from "react";
import EffectOfPurchasingProfitablePropertiesContentWidget from "../effect-of-purchasing-profitable-properties-content-widget";


function EffectOfPurchasingProfitablePropertiesWidget(props) {
  const { lifePlan, loggedIn, totalIncoming, totalSpending, incomingPercent, spendingPercent } = props;

  const [tabPurchaseIndex, setTabPurchaseIndex] = useState(0);

  const onSelectedItem = (index) => {
    setTabPurchaseIndex(index);
    // props.onSelectedItem && props.onSelectedItem(index);
  };

  const types = ['区分マンション', 'アパート', '一棟マンション'];

  return (
    <div className="effect marB80">
      <h2>投資物件</h2>
      <div className={`tabBox propertyTabBtnSwitch ${loggedIn ? '' : 'blurCont'}`}>
        <ul className="effectPropertyTab areaDetTab tabBtn">
          {types.map((type, index) => (
            <li
              key={index}
              onClick={() => onSelectedItem(index)}
              className={`hoverItem ${
                tabPurchaseIndex === index ? "active" : ""
              }`}
            >
              <span>{type}</span>
            </li>
          ))}
        </ul>
        <EffectOfPurchasingProfitablePropertiesContentWidget
          tabPurchaseIndex={tabPurchaseIndex}
          loggedIn={loggedIn}
          totalIncoming={totalIncoming}
          totalSpending={totalSpending}
          incomingPercent={incomingPercent}
          spendingPercent={spendingPercent}
          lifePlan={lifePlan}
        />
      </div>
    </div>
  );
}

export default React.memo(EffectOfPurchasingProfitablePropertiesWidget);
