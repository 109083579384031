import styled from 'styled-components';
import defaultTheme from '../theme';

const HeaderTitle = styled.div`
`;

HeaderTitle.defaultProps = {
  theme: defaultTheme
};

export default HeaderTitle;
