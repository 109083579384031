import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import EffectOfPurchasingProfitablePropertiesWidget from "../effect-of-purchasing-profitable-properties-widget";
import LpsItemHeadTxtWidget from "../lps-item-head-txt-widget";
import iconHuman from 'images/mascot/mascot7.png';
import iconHuman5 from 'images/mascot/mascot5.png';
import iconHuman1 from 'images/mascot/mascot1.png';
import LpsCaculationResultsWidget from "../lps-caculation-results-widget";
import AssetReplacementTabContentWidget from "../asset-replacement-tab-content-widget";
import SuccessfulSaleTabContentWidget from "../successful-sale-tab-content-widget";
import SuccessfulCaseOfAdditionalPurchaseTabWidget from "../successful-case-of-additional-purchase-tab-widget";
import HomePurchaseCaseTabWidget from "../home-purchase-case-tab-widget";
import HomeSaleCaseTabWidget from "../home-sale-case-tab-widget";
import ResultGraphSpendingWidget from "../result-graph-spending-widget";
import ResultGraphIncomeWidget from "../result-graph-income-widget";
import ResultSpendingWidget from "../result-spending-widget";
import ResultIncomeWidget from "../result-income-widget";
import AiSaleChart from "../ai-sale-chart"
import { LifePlanService } from '../../core/services/life-plans';
import { simulationParams } from '../../shared/constants/simulator';
import {convertToJapanCurrency} from "../../shared/constants/global";
import CompareIncomingAndSpendingGraph from "../compare-incoming-and-spending-graph"
import CompareIncomingAndSpendingLifeEventGraph from "../compare-incoming-and-spending-life-event-graph"
import CompareIncomingAndSpendingInvestmentGraph from "../compare-incoming-and-spending-investment-graph"
import iconFormGreen from "../../assets/icons/ico-formGreen.svg";
import { LifePlansImages } from "../../assets/images";
const { arrowBGrad } = LifePlansImages;

import useVisibility from "../../shared/hooks/visibility";

export default function LpsResultBoxWidget(props) {
  const {loggedIn, setIsFadeIn, lifePlan} = props;
  const [tabChatIndex, setTabChatIndex] = useState(-1);
  const chatTypes = ['資産の入れ替え相談例', '売却相談例','買い増しの相談例', '投資用物件の購入相談例', '相続対策としての相談例'];
  const fullName = gon.full_name

  const tabBoxRef = useRef();
  const isVisible = useVisibility(tabBoxRef);
  const [incomeResult, setIncomeResult] = useState([]);
  const [spendingResult, setSpendingResult] = useState([]);
  const [totalIncoming, setTotalIncoming] = useState(0);
  const [totalSpending, setTotalSpending] = useState(0);
  const [incomingPercent, setIncomingPercent] = useState(0);
  const [spendingPercent, setSpendingPercent] = useState(0);
  const [tabsAvailable, setTabsAvailable] = useState([]);
  const [classTabChat, setClassTabChat] = useState("");
  const [lifePlanSimulationResult, setLifePlanSimulationResult] = useState([]);
  const [titleAnalysisIncomeAndSpending, setTitleAnalysis] = useState("");
  const [contentAnalysisIncomeAndSpending, setContentAnalysis] = useState("");
  const [lastEventSellProperty, setLastEventSellProperty] = useState();

  const [lifeEventTotalIncoming, setLifeEventTotalIncoming] = useState(0);
  const [lifeEventTotalSpending, setLifeEventTotalSpending] = useState(0);
  const [lifeEventIncomingPercent, setLifeEventIncomingPercent] = useState(0);
  const [lifeEventSpendingPercent, setLifeEventSpendingPercent] = useState(0);

  const [investmentTotalIncoming, setInvestmentTotalIncoming] = useState(0);
  const [investmentTotalSpending, setInvestmentTotalSpending] = useState(0);
  const [investmentIncomingPercent, setInvestmentIncomingPercent] = useState(0);
  const [investmentSpendingPercent, setInvestmentSpendingPercent] = useState(0);

  function onWindowScroll() {
    const pageYOffset = window.pageYOffset;
    // đoạn này truyền isVisible sang cho component cha rồi thêm class fadein fadeout chứ không dùng jquery nhóe =))
    if(isVisible || pageYOffset < 1) {
      setIsFadeIn(false);
    } else {
      setIsFadeIn(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onWindowScroll);
    return () =>  window.removeEventListener("scroll", onWindowScroll)
  }, [isVisible])

  const findEvent = (event_type) => {
    return lifePlan.life_event.find((e) => e.event_type === event_type);
  }

  const getLastEventSellProperty = () => {
    const eventSellProperties = lifePlan.life_event.filter((e) => e.event_type === 2);
    eventSellProperties && setLastEventSellProperty(eventSellProperties[eventSellProperties.length - 1])
  }

  const showAiSaleChart = () => {
    const hasEvent1 = findEvent(1);
    const hasEvent2 = findEvent(2);
    const hasEvent3 = findEvent(3);
    const hasEvent4 = findEvent(4);
    const hasEvent34 = findEvent(3) && findEvent(4);
    const notHaveEvent34 = !findEvent(3) && !findEvent(4);

    if((hasEvent2 && notHaveEvent34 && !hasEvent1) || (hasEvent2 && hasEvent3 && !hasEvent1) ||
    (hasEvent2 && notHaveEvent34 && hasEvent1) || (hasEvent2 && hasEvent3 && hasEvent1) ||
    (hasEvent2 && hasEvent4 && !hasEvent1) || (hasEvent2 && hasEvent4 && hasEvent1)) {
      return true;
    }

    return false;
  }

  const showPropertiesWidget = () => {
    const hasEvent1 = findEvent(1);
    const hasEvent2 = findEvent(2);
    const notHaveEvent34 = !findEvent(3) && !findEvent(4);

    if(!hasEvent1 && !hasEvent2 && notHaveEvent34) {
      return true;
    }

    return false;
  }

  const showTabChat = (index) => {
    const hasEvent1 = findEvent(1);
    const hasEvent2 = findEvent(2);
    const hasEvent3 = findEvent(3);
    const hasEvent4 = findEvent(4);
    const notHaveEvent34 = !findEvent(3) && !findEvent(4);

    if(index == 4) {
      if(hasEvent2 || (!hasEvent2 && hasEvent3 && !hasEvent4 && hasEvent1) || (!hasEvent2 && hasEvent3 && !hasEvent4 && !hasEvent1) ||
      (!hasEvent2 && hasEvent4 && !hasEvent3 && !hasEvent1) || (!hasEvent2 && hasEvent3 && hasEvent4 && hasEvent1)){
        return true
      }
    }

    if(index == 3) {
      if(hasEvent2 || (!hasEvent2 && hasEvent3 && !hasEvent4 && hasEvent1) || (!hasEvent2 && notHaveEvent34 && !hasEvent1) ||
      (!hasEvent2 && notHaveEvent34 && hasEvent1) || (!hasEvent2 && hasEvent4 && !hasEvent3 && hasEvent1)){
        return true
      }
    }

    if(index == 2) {
      if(hasEvent2 || (!hasEvent2 && hasEvent3 && !hasEvent4 && hasEvent1)){
        return true
      }
    }

    if(index == 1) {
      if(hasEvent2 || (!hasEvent2 && hasEvent3 && !hasEvent4 && hasEvent1) || (!hasEvent2 && hasEvent3 && !hasEvent4 && !hasEvent1) ||
      (!hasEvent2 && hasEvent4 && !hasEvent3 && !hasEvent1) || (!hasEvent2 && hasEvent3 && hasEvent4 && hasEvent1)){
        return true
      }
    }

    if(index == 0){
      if(hasEvent2 || (!hasEvent2 && hasEvent3 && !hasEvent4 && hasEvent1)) {
        return true
      }
    }

    return false;
  }

  const addTabIndexToList = () => {
    const listTab = [];
    for (let i = 0; i < 5; i++) {
      if(showTabChat(i)) listTab.push(i);
    }

    if(!!listTab.length) {
      setTabChatIndex(listTab[0]);
    }else{
      setTabChatIndex(-1)
    }

    setTabsAvailable(listTab);
    if(listTab.length === 5) {
      setClassTabChat("")
    } else {
      setClassTabChat("tabChat")
    }
  }

  const setTextAnalysis = (contentParams={}) =>{
    const differeceBetweeenIcomingAndSpending = totalIncoming - totalSpending
    if(differeceBetweeenIcomingAndSpending > 2000000) {
      setContentAnalysis(contentParams.content1)
    }else if(differeceBetweeenIcomingAndSpending <= 2000000 && differeceBetweeenIcomingAndSpending >= 0) {
      setContentAnalysis(contentParams.content2)
    }else if(differeceBetweeenIcomingAndSpending >= -2000000 && differeceBetweeenIcomingAndSpending <= 0) {
      setContentAnalysis(contentParams.content2)
    }else if(differeceBetweeenIcomingAndSpending < -2000000) {
      setContentAnalysis(contentParams.content3)
    }
  }

  const setTextAnalysisWithCondition = () => {
    const buyPropertyEvent = findEvent(1)
    const sellPropertyEvent = findEvent(2)
    if(buyPropertyEvent && sellPropertyEvent) {
      const contentParams = {
        content1: `${fullName}さまが所有されている投資用物件の現市場価値を把握された上で、これからの資産の組み替えをご検討されてはいかがでしょう。`,
        content2: `${fullName}さま、中長期的な資産の組み替えもよろしいですが、今は効果的な資産形成をご検討いただけますでしょうか。`,
        content3: `ふむふむ。<br />${fullName}さまの所有されている投資用物件を早期に売却なされることで、収支が改善できる場合もございます。`
      }

      setTextAnalysis(contentParams)

      return
    }

    if(buyPropertyEvent) {
      const contentParams = {
        content1: `${fullName}さま、追加で物件のご購入はいかがでしょう。`,
        content2: `${fullName}さま、投資用不動産のご購入から中⻑期的な資産の組み替えを含めて、これからの出口戦略をご検討いただけますでしょうか。`,
        content3: `ふむふむ。<br />${fullName}さま、金利や自己資金のお見直しがご必要ではないでしょうか。`
      }

      setTextAnalysis(contentParams)

      return
    }

    if(sellPropertyEvent) {
      const contentParams = {
        content1: `${fullName}さまが所有されている投資用物件の現市場価値を把握されて、資産の組み替えを含め、ご検討されてはいかがでしょう。`,
        content2: `${fullName}さま、投資不動産売却や中長期的な資産の組み替えなど、より効果的な資産形成をご検討いただけますでしょうか。`,
        content3: `ふむふむ。<br />${fullName}さまの所有されている投資用物件を早期に売却なされて、収支を改善されるのはいかがでしょう。`
      }

      setTextAnalysis(contentParams)

      return
    }

    if(!buyPropertyEvent && !sellPropertyEvent) {
      const contentParams = {
        content1: `${fullName}さまは収支に余裕がありますので、より安定した将来のために今から資産形成はいかがでしょう。`,
        content2: `${fullName}さま、投資不動産を購入されて、安定した老後の資産形成をご検討されてはいかがでしょう。`,
        content3: `${fullName}さま、投資不動産を購入されることで収支を改善できる場合もございます。小規模な物件から資産形成をお早くはじめられるのはいかがでしょう。`
      }

      setTextAnalysis(contentParams)

      return
    }
  }

  const handleRegisterSimulation = async () => {
    if (lifePlan.person_age) {
      try {
        addTabIndexToList();

        const results = (await LifePlanService.registerSimulation(simulationParams(lifePlan))).response.data;
        const incomingResult = results.cumulative_balance.income_breakdown;
        const spendingResult = results.cumulative_balance.expenditure_breakdown;
        const lifePlanSimulationResult = results.life_plan_simulation_result;
        setLifePlanSimulationResult(lifePlanSimulationResult)

        const totalIncomingMoney = incomingResult.reduce((total, {value}) => total + value, 0);
        const totalSpendingMoney = spendingResult.reduce((total, {value}) => total + value, 0);
        const incomingPercent = (totalIncomingMoney/(totalIncomingMoney + totalSpendingMoney) * 100);
        const spendingPercent = 100 - incomingPercent;

        setTotalIncoming(totalIncomingMoney);
        setTotalSpending(totalSpendingMoney);

        setIncomeResult(incomingResult);
        setSpendingResult(spendingResult);

        setIncomingPercent(incomingPercent);
        setSpendingPercent(spendingPercent);

        // investment
        const investmentTotalIncomingMoney = incomingResult.find(i => i.category == 5).value;
        const investmentTotalSpendingMoney = spendingResult.find(i => i.category == 6).value;
        const investmentIncomingPercent = investmentTotalIncomingMoney == investmentTotalSpendingMoney ? 50 : (investmentTotalIncomingMoney/(investmentTotalIncomingMoney + investmentTotalSpendingMoney) * 100);
        const investmentSpendingPercent = investmentTotalIncomingMoney == investmentTotalSpendingMoney ? 50 : 100 - incomingPercent;

        setInvestmentTotalIncoming(investmentTotalIncomingMoney);
        setInvestmentTotalSpending(investmentTotalSpendingMoney);
        setInvestmentIncomingPercent(investmentIncomingPercent);
        setInvestmentSpendingPercent(investmentSpendingPercent);

        // lifeEvent
        const lifeEventTotalIncomingMoney = totalIncomingMoney - investmentTotalIncomingMoney
        const lifeEventTotalSpendingMoney = totalSpendingMoney - investmentTotalSpendingMoney
        const lifeEventIncomingPercent = (lifeEventTotalIncomingMoney/(lifeEventTotalIncomingMoney + lifeEventTotalSpendingMoney) * 100);
        const lifeEventSpendingPercent = 100 - incomingPercent;

        setLifeEventTotalIncoming(lifeEventTotalIncomingMoney);
        setLifeEventTotalSpending(lifeEventTotalSpendingMoney);
        setLifeEventIncomingPercent(lifeEventIncomingPercent);
        setLifeEventSpendingPercent(lifeEventSpendingPercent);
      }catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    setTextAnalysisWithCondition()
  }, [totalIncoming, totalSpending])

  useEffect(() => {
    addTabIndexToList()
    getLastEventSellProperty()
  }, [lifePlan])

  useEffect(async () => {
    handleRegisterSimulation()
  }, [lifePlan])

  return (
    <>
      <LpsItemHeadTxtWidget loggedIn={loggedIn} lifePlan={lifePlan} lifePlanSimulationResult={lifePlanSimulationResult}
                            showPropertiesWidget={showPropertiesWidget()} totalIncoming={totalIncoming}
                            totalSpending={totalSpending} />
      <h2 className="marB14">
        <img src={iconHuman1} alt="" width="100" />
        総合収支
      </h2>
      <div className="resultGraphBox">
        <ResultGraphIncomeWidget loggedIn={loggedIn} lifePlan={lifePlan} results={incomeResult} />
        <ResultGraphSpendingWidget loggedIn={loggedIn} lifePlan={lifePlan} results={spendingResult} />
      </div>
      <CompareIncomingAndSpendingGraph loggedIn={loggedIn} incomingPercent={incomingPercent}
        totalIncoming={totalIncoming} spendingPercent={spendingPercent} totalSpending={totalSpending} />
      <h2 className="marB14">【内訳】ライフプラン収支</h2>
      <div className="resultGraphBox">
        <ResultIncomeWidget loggedIn={loggedIn} lifePlan={lifePlan} results={incomeResult} />
        <ResultSpendingWidget loggedIn={loggedIn} lifePlan={lifePlan} results={spendingResult} />
      </div>
      <CompareIncomingAndSpendingLifeEventGraph loggedIn={loggedIn} incomingPercent={lifeEventIncomingPercent}
        totalIncoming={lifeEventTotalIncoming} spendingPercent={lifeEventSpendingPercent} totalSpending={lifeEventTotalSpending} />

      <CompareIncomingAndSpendingInvestmentGraph loggedIn={loggedIn} incomingPercent={investmentIncomingPercent}
        totalIncoming={investmentTotalIncoming} spendingPercent={investmentSpendingPercent} totalSpending={investmentTotalSpending} />

      <div className="point marB80">
        <h2>
          <img src={iconHuman} alt="" width="125" />
          改善のポイント
        </h2>
        <div className="gradBluBg gradBluBg4">
          <div className={`whiteBox pad24 ${loggedIn ? '' : "blurCont"}`}>
            <p dangerouslySetInnerHTML={{ __html: contentAnalysisIncomeAndSpending }}></p>
          </div>
        </div>
      </div>

      { showPropertiesWidget() ? <EffectOfPurchasingProfitablePropertiesWidget
        lifePlan={lifePlan} totalIncoming={totalIncoming} totalSpending={totalSpending}
        incomingPercent={incomingPercent} spendingPercent={spendingPercent}
        loggedIn={loggedIn}
        /> : null }

      { lastEventSellProperty ? <AiSaleChart lastEventSellProperty={lastEventSellProperty} loggedIn={loggedIn} incomingPercent={incomingPercent}
      totalIncoming={totalIncoming} spendingPercent={spendingPercent} totalSpending={totalSpending} /> : null }
      { <LpsCaculationResultsWidget loggedIn={loggedIn} lifePlan={lifePlan} incomeResult={incomeResult}
        spendingResult={spendingResult} totalIncoming={totalIncoming} totalSpending={totalSpending}
        lifePlanSimulationResult={lifePlanSimulationResult} /> }

      <div className="tabBox example aiResultSummary" ref={tabBoxRef}>
        <h2 className={`tabPanel marB14 ${[0, 1, 2, 3, 4].includes(tabChatIndex) && "active"}`}>
          <img src={iconHuman5} alt="" width="125" />
          よくあるご質問<br className="sr380"/>・ご相談
        </h2>
        <div className={`${tabsAvailable.length > 2 ? "spScroll" : ""}`}>
          <ul className={`tabBtn tabStyle ${classTabChat}`}>
            {chatTypes.map((chat, index) => tabsAvailable.includes(index) ? (
              <li key={chat} onClick={() => setTabChatIndex(index)} className={`hoverItem tabChatContainer ${index === tabChatIndex && "active"}`}>
                <span>{chat}</span>
              </li>
            ) : null)}
          </ul>
        </div>
        <div className="tabContents">
          <div className={`tabPanel exampleTabBox1 ${tabChatIndex === 0 && "active"}`} >
            <AssetReplacementTabContentWidget />
          </div>
          <div className={`tabPanel ${tabChatIndex === 1 && "active"}`}>
            <SuccessfulSaleTabContentWidget />
          </div>
          <div className={`tabPanel ${tabChatIndex === 2 && "active"}`}>
            <HomePurchaseCaseTabWidget />
          </div>
          <div className={`tabPanel ${tabChatIndex === 3 && "active"}`}>
            <HomeSaleCaseTabWidget />
          </div>
          <div className={`tabPanel ${tabChatIndex === 4 && "active"}`} >
            <SuccessfulCaseOfAdditionalPurchaseTabWidget />
          </div>
        </div>
      </div>
    </>
  );
}
