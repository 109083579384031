import React from 'react';
import { useTranslation } from 'react-i18next';
import lpsGraphDefault from 'images/lps-graph-default.png';
import icoHumanL from 'images/mascot/mascot1.png';
import { useSelector } from 'react-redux';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';
import { useNavigate } from "react-router-dom";

export default function TutorialModal(props) {
  const navigate = useNavigate();
  const { t } = useTranslation('tutorialModal');
  const modalOpenName = useSelector(state => state.modalOpenName);

  const handleSubmit = () => {
    props.handleNextModal && props.handleNextModal(4)
    navigate('results')
  }
  return <LifePlanModal name={ModalName.LIFEPLAN_TUTORIAL} >
    <div className={`popupBox ${modalOpenName === ModalName.LIFEPLAN_TUTORIAL ? 'tutorial_lpsGraph_popup tutorial' : ''}`}>
      <div className="popupInner">
        <div className="lifeplanPopup">
          <div className="graph"><img src={lpsGraphDefault} alt="lps-graph-image" /></div>
          <div className="popupContents explanation">
            <div className="popupContentsCover figBox">
              <figure><img src={icoHumanL} className="step_image" /></figure>
              <div className="popupContentsRight">
                <div className="popupContentsTxt">
                  <h2 className="alL padT0">{t('label')}</h2>
                  <p className="marB32">{t('poupContentsTxt')}</p>
                </div>
                <div className="contactBtnBox">
                  <ul className="btnList">
                    <li><button type="button" onClick={handleSubmit} className="btnSty-send">{t('send')}</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LifePlanModal>;
}
