import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal, openSubModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import LifePlanModal from '../../shared/components/modal';
import { generateYearsByMemberAge, ModalName } from '../../shared/constants/global';
import { LifePlansImages } from '../../assets/images';
import SelectFieldSecond from '../../shared/components/select-field-second';
import InputText from '../../shared/components/input-field';
import CommaInput from '../../shared/components/comma-input-field';
import { YupSchema } from '../../shared/constants/validators';
import { Form, Field, Formik } from 'formik';
import { cloneDeep } from "lodash";

const { imgSecondCarrier1, imgSecondCarrier2, imgSecondCarrier5, imgSecondCarrier6, imgSecondCarrier7, imgSecondCarrier8, imgSecondCarrier9, imgSecondCarrier10 } = LifePlansImages;
const arrImages = [imgSecondCarrier1, imgSecondCarrier2, imgSecondCarrier10, imgSecondCarrier9, imgSecondCarrier5, imgSecondCarrier6, imgSecondCarrier7, imgSecondCarrier8];

export default function EventSecondCarrierModal(props) {
  const { t } = useTranslation('eventSecondCarrierModal');
  const tGlobal = useTranslation('global').t;
  const [checkShow, setCheckShow] = useState(false);
  const dispatch = useDispatch();

  const { lifeEvent } = props;
  const [initialValues, setInitialValues] = useState({ ...props.lifeEvent, other: { ...props.lifeEvent?.other } });
  const formState = useRef();

  const validateSchema = useMemo(() => {
    return new YupSchema(t).eventSecondCarrierModal;
  }, [])

  useEffect(() => {
    const values = { ...props.lifeEvent, other: { ...props.lifeEvent?.other } }
    setInitialValues(values)
    setCheckShow(values.other.detail == 8)
  }, [props.lifeEvent]);

  const handleClose = () => {
    dispatch(closeModal());
  }

  const getDetailName = (detail, data) => {
    switch (detail) {
      case 1:
        return "（開業）パン屋"
      case 2:
        return "（開業）カフェ"
      case 3:
        return "（開業）蕎麦屋"
      case 4:
        return "（開業）ラーメン屋"
      case 5:
        return "（開業）コンビニ"
      case 6:
        return "（開業）不動産業"
      case 7:
        return "大学入学"
      case 8:
        return data.other?.custom || ""
      default:
        return ""
    }
  }

  const prepareData = (values) => {
    const tempData = cloneDeep(values);
    const params = {
      ...tempData,
      other: {
        ...tempData.other,
        detail_name: getDetailName(+tempData.other?.detail, tempData)
      }
    }
    return params;
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      const data = prepareData(values);
      dispatch(saveSelectedLifeEvent(data));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    return `${subYear(year)}歳（${year}年）`
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age;
  }

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_SECONDCARRIER} >
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>セカンドキャリア</h2>
                  <div className="popupContents">
                    <div className="popupContentsCover">
                      <div id="checkshow1" className="whiteBox checkShowToggle radioImgBox pad24 padBottom8">
                        <ul className="radioImgBtnList rowList">
                          {[...Array(8)].map((e, i) => {
                            return <li key={i}><label className={`radioBtn ${i + 1 == values.other.detail ? "checked" : ""}`}>
                                    <Field onClick={() => setCheckShow(i == 7)}
                                      type="radio" value={i + 1} name="other.detail" className="toggle_hide" />
                                    <img src={arrImages[i]} /><span>{t(`options.${i}`)}{t(`cost.${i}`)}</span>
                                  </label></li>
                          })}
                        </ul>
                      </div>
                    </div>
                    <dl className="formSty lifeplanImgBtnBox marB40">
                      <dt id="checkShow1-ttl" className={`checkShow1 ${!checkShow ? 'hiddenTtl' : ''}`}>{t('custom')}</dt>
                      <dd id="checkShow1-item" className={`checkShow1 ${!checkShow ? 'hiddenItem' : ''} formTtlSty nowrap`}>
                        <div className="wid400">
                          <Field className="toggle-item" type="text" component={InputText} name="other.custom" placeholder={t('customPlaceholder')} disabled={!checkShow} dF={true} />
                        </div>
                        <dl className="formTtlSty nowrap wid380 marL40">
                          <dt>{t('initialCost')}</dt>
                          <dd>
                            <Field className="wid120" placeholder="100" type="text" component={CommaInput} name="other.cost_at_first" disabled={!checkShow} secondLabel={t('unit')} setFieldValue={setFieldValue} />
                          </dd>
                        </dl>
                      </dd>
                      <dt>{t('startTime')}</dt>
                      <dd>
                        <div className="dF aisC">
                          <Field component={SelectFieldSecond} width="wid200" name="year" options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                        </div>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList col2">
                        <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                        <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                        { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
