import { ROUTES_URL } from "../shared/constants";
import React from "react";

import LifeEvent from "../pages/life-plans/index";

export const ROUTES = [
    {
        path: ROUTES_URL.HOME,
        element: <LifeEvent />
    },
]