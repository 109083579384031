import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { YupSchema } from '../../../shared/constants/chatbot-validators';
import SelectPrefecture from './CustomField/SelectPrefecture';
import TextInput from './CustomField/TextInput';
import { LifePlanService } from '../../../core/services/life-plans';

export default function CustomerAddressForm(props) {
  const { t } = useTranslation('saleModal');
  const validateSchema = new YupSchema(t).customerAddressForm;
  const formState = useRef();
  const prefectures = gon.prefectures
  const initialValues = props.initialValues

  const searchZipCode = async(zipcode) => {
    if (zipcode.length != 7) return

    $("#preloadCustomerAddress").fadeIn();
    const result = await LifePlanService.getAddressByZipcode(zipcode);
    
    if (result.status && result.response.result != null) {
      formState.current.setFieldValue("customer_prefecture", result.response.result.prefecture)
      formState.current.setFieldValue("customer_city", result.response.result.city + result.response.result.town)
    }
    $("#preloadCustomerAddress").fadeOut();
  }

  const handleTriggerNextStep = async(values) => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const storage = JSON.parse(window.lsSession.getItem(`chatbot-${props.formType}`))
    const newObj = {...storage, ...values}
    const storageStr = JSON.stringify(newObj)
    window.lsSession.setItem(`chatbot-${props.formType}`, storageStr)
    props.triggerNextStep({ trigger: props.trigger, value: values, replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            {/* Component Preloader */}
            <div id="preloadCustomerAddress" className="preload">
              <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>

            <div className="selectOption">
              <div className="titleSelect">郵便番号</div>
              <Field name="customer_zip_code" component={TextInput} numberonly="true" type="text" placeholder="入力してください。" onInput={(e) => {searchZipCode(e.target.value)}} />
            </div>
            <div className="selectOption">
              <div className="titleSelect">都道府県</div>
              <Field name="customer_prefecture" component={SelectPrefecture} options={prefectures} format="long" blank="都道府県をお選びください" />
            </div>
            <div className="selectOption">
              <div className="titleSelect">市区町村以下</div>
              <Field name="customer_city" component={TextInput} type="text" placeholder="入力してください。" />
            </div>
            <button type="submit" className="btnSubmit">
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
