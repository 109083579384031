import { useState, useEffect } from 'react'

export default function useVisibility(ref) {

  const [isIntersecting, setIntersecting] = useState(false)
  
  const observer = new IntersectionObserver(
    ([entry]) => {
        return setIntersecting(entry.isIntersecting)
    }
  )
  
  useEffect(() => {
    observer.observe(ref.current)
    return () => { observer.disconnect() }
  }, [])
  
  return isIntersecting;
}