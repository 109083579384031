import React from 'react';
import BgPaginationItem from "./BgPaginationItem";

export default function BgPagination(props) {
  const PAGINATION_SIZE = 10

  const eventsByYear = {}
  props.events.forEach(event => {
    eventsByYear[event.year] ||= []
    eventsByYear[event.year].push(event)
  })

  const pagination = {}
  let page = 0
  props.labels.forEach(label => {
    if (label.age % PAGINATION_SIZE === 0) {
      page += 1
      pagination[page] = {
        label: label,
        eventGroups: {}
      }
    }

    pagination[page]["eventGroups"][label.year] ||= { label: label }
    pagination[page]["eventGroups"][label.year]["events"] = eventsByYear[label.year] || []
  })

  return (
    <React.Fragment>
      <div className="timelineBgPagination">
        { Object.keys(pagination).map((page) => <BgPaginationItem page={page} item={pagination[page]} key={page} />) }
      </div>
    </React.Fragment>
  )
}
