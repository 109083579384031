import React from "react";
import { LifePlansImages } from "../../assets/images";
import iconFormGreen from "../../assets/icons/ico-formGreen.svg";
const { commentCustomer, commentStaff, arrowBGrad } = LifePlansImages;

function HomePurchaseCaseTabWidget() {
  return (
    <>
      <div className="commentLine">
        <div className="commentDet staff">
          <figure>
            <img src={commentCustomer} alt="" />
            <figcaption>お客様</figcaption>
          </figure>
          <div className="comment">
            <p>投資不動産の買い増しを考えていますが、税制や法人化のタイミング等も含めて漠然としており、具体的に購入に踏み切れません。</p>
          </div>
        </div>
        <div className="commentDet staff">
          <figure>
            <img src={commentStaff} alt="" />
            <figcaption>
              営業スタッフ
            </figcaption>
          </figure>
          <div className="comment">
            <p>司法書士、税理士等、提携士業との無料相談会も定期的に
              開催しております。個別でのご相談も承りますので、
              是非、お気軽にご相談くださいませ。これまで法人化を
              活用されてきたお客様の事例など、お役に立てる情報を
              お伝えさせていただきます。</p>
          </div>
        </div>
      </div>
      <div className="arrow">
        <img src={arrowBGrad} alt="" />
        <p>
          上記内容も無料で相談。
          <br className="sp" />
          安心のサポートをいたします。
        </p>
      </div>
      <div className="contactGreenSty">
        <a href="/contact/contact_generals/new">
          <h3>
            無料相談は<span className="inlBlock fontWB">かんたん1分で完了</span>
          </h3>
          <span className="whiteLineBtn">
            <img src={iconFormGreen} alt="" width="32" height="26" />
            お問い合わせはこちら
          </span>
          <span className="ttlSty">CONTACT</span>
        </a>
      </div>
    </>
  );
}
export default React.memo(HomePurchaseCaseTabWidget);
