import React from 'react';
import {convertToJapanCurrency} from "../../shared/constants/global";

export default function CompareIncomingAndSpendingGraph(props) {
  const {loggedIn, incomingPercent, totalIncoming, spendingPercent, totalSpending} = props

  return <React.Fragment>
    <div className={`resultGraphTxt marB80 ${loggedIn ? '' : "blurCont"}`}>
      <div className="vsGraph vsGraphContainer">
        <div className="incomeRatio" style={{width: `${incomingPercent}%`}}>
          <div className="incomeRatioTitle">ライフプラン収入</div>
          <div className="incomeRatioMoney">{convertToJapanCurrency(totalIncoming)}</div>
        </div>
        <div className="spendingRatio" style={{width: `${spendingPercent}%`}}>
          <div className="spendingRatioTitle">ライフプラン支出</div>
          <div className="spendingRatioMoney">{convertToJapanCurrency(totalSpending)}</div>
        </div>
      </div>
      <p>収支合計<strong>{convertToJapanCurrency(totalIncoming - totalSpending)}</strong></p>
    </div>
  </React.Fragment>
}
