import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik, useFormikContext } from 'formik';
import { ModalName } from '../../shared/constants/global';
import { useDispatch, useSelector } from 'react-redux';
import LifePlanModal from '../../shared/components/modal';
import { setInputLifePlan } from '../../redux/lifePlan/slice';
import CommaInput from '../../shared/components/comma-input-field';
import RadioField from '../../shared/components/radio-field';
import ChildField from './child';
import { FlowCurrent } from '../../shared/components/flow-current';
import icoSave from 'images/ico-save.svg';
import { YupSchema } from '../../shared/constants/validators';

const FormObserver = (props) => {
  const { values } = useFormikContext();

  useEffect(() => {
    props.handleFormChangeValues && props.handleFormChangeValues(values)
  }, [values]);

  return null;
};

export default function FamilyModal(props) {
  const { t } = useTranslation('familyModal');
  const tGlobal = useTranslation('global').t;
  const dispatch = useDispatch();
  const lifePlan = useSelector(state => state.life_plan);

  const [isSave, setIsSave] = useState(false);
  const [initialValues, setInitialValues] = useState({
    partner: 2,
    person_age: '',
    person_pension: 1,
    partner_age: '',
    partner_pension: 2,
    child: []
  });

  const formRef = useRef();
  const timeoutId = useRef();

  const validateSchema = new YupSchema(t).familyModal;

  useEffect(() => {
    timeoutId.current && clearTimeout(timeoutId.current);
  }, [])

  const handleNextModal = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    const child = values.child.map(ele => {
      return { ...ele }
    })
    dispatch(setInputLifePlan({ ...values, child }))
    document.getElementById('lifeplanPopup').classList.remove('savePopup');
    timeoutId.current && clearTimeout(timeoutId.current);
    props.handleNextModal && props.handleNextModal(1)
  }

  const handlePrevModal = () => {
    document.getElementById('lifeplanPopup').classList.remove('savePopup');
    timeoutId.current && clearTimeout(timeoutId.current);
    props.handlePrevModal && props.handlePrevModal(1)
  }

  const handleSubmit = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    const child = values.child.map(ele => {
      return { ...ele }
    })
    setIsSave(true)
    dispatch(setInputLifePlan({ ...values, child }))
  }

  const handleSave = async () => {
    setIsSave(false)
    try {
      props.handleSubmitLifePlan && props.handleSubmitLifePlan(false);
      document.getElementById('lifeplanPopup').classList.add('savePopup');

      timeoutId.current = setTimeout(() => {
        document.getElementById('lifeplanPopup').classList.remove('savePopup');
      }, 3000)
    } catch (e) {
      console.log(e)
    }
  }

  const onPartnerChange = (partner) => {
    if (partner === 2) {
      formRef.current.setFieldValue("partner_age", '');
    }
  }

  useEffect(() => {
    var updateValues = {}
    Object.keys(initialValues).forEach(key => {
      if (lifePlan[key] !== null) {
        if (key === 'child') {
          const child = lifePlan[key].map(ele => {
            return { ...ele }
          })
          updateValues[key] = child
        }
        updateValues[key] = lifePlan[key]
      }
    });
    setInitialValues({
      ...initialValues,
      ...updateValues
    })

    if (isSave) handleSave();
  }, [lifePlan])

  const handleFormChangeValues = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    if (props.step != 0) {
      const newObj = {...lifePlan, ...values}
      const lifePlanStr = JSON.stringify(newObj)
      window.lsSession.setItem("life_plans", lifePlanStr)
    }
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_FAMILY}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        formRef.current = formikProps;

        return (
          <Form>
            <div className="popupBox">
              <div className="popupInner">
                <div id="lifeplanPopup" className="lifeplanPopup">
                  <h2 className="padB44">{t('title')}</h2>
                  <div className="popupContents">
                    <FlowCurrent flow="ご家族" />
                    <div className="marB14">{t('currentFamilyStatus')}</div>
                    <dl className="formSty marB40">
                      <dt className="padT24">{t('spouse.label')}</dt>
                      <dd id="checkShow1">
                        <Field name="partner" options={[1, 2]} labels={t('spouse.option', { returnObjects: true })} component={RadioField} onChange={() => onPartnerChange(values.partner)} />
                      </dd>
                      <dt>{t('age.label')}</dt>
                      <dd className="noFlex">
                        <dl className="formTtlSty nowrap marB1em">
                          <dt>{t('age.identity')}</dt>
                          <dd>
                            <Field className="wid120"type="text" component={CommaInput} name="person_age" secondLabel={t('ageNum')} setFieldValue={setFieldValue} />
                          </dd>
                          <dt className="spMarB0">{t('age.persion.label')}</dt>
                          <dd>
                            <Field name="person_pension" options={[1, 2]} labels={t('age.persion.option', { returnObjects: true })} component={RadioField} />
                          </dd>
                        </dl>
                        <dl id="checkShow1-item" className={`formTtlSty nowrap checkShowItem ${values.partner === 1 ? 'hiddenItem' : ''}`}>
                          <dt>{t('age.wife')}</dt>
                          <dd>
                            <Field type="text"className="wid120 toggle-item" disabled={values.partner === 1} component={CommaInput} name="partner_age" secondLabel={t('ageNum')} setFieldValue={setFieldValue} />
                          </dd>
                          <dt className="spMarB0">{t('age.persion.label')}</dt>
                          <dd>
                            <Field name="partner_pension" options={[1, 2]} labels={t('age.persion.option', { returnObjects: true })} component={RadioField} disabled={values.partner === 1} />
                          </dd>
                        </dl>
                      </dd>
                      <Field name="child" component={ChildField} />
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList reverse">
                        <li><button type="submit" onClick={() => handleSubmit(values)} className="linkBtn wid200"><figure><img src={icoSave} alt="" width="16" /></figure><span>{tGlobal('save')}</span></button></li>
                        <li><button type="button" onClick={handlePrevModal} className="btnSty-back">{tGlobal('back')}</button></li>
                        <li><button type="submit" onClick={() => handleNextModal(values)} className="btnSty-send">{tGlobal('next')}</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <FormObserver handleFormChangeValues={handleFormChangeValues} />
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
