import React from 'react';
import { generateYearsByMemberAge } from '../../shared/constants/global';
import Axis from './Axis';
import Content from './Content';

export default function TimelineVerticalWidget(props) {
  const labels = generateYearsByMemberAge({forVerticalTimeLine: true}).map((y) => { return {year: y.year, age: y.age} })

  return (
    <React.Fragment>
      <div className="timelineVertical">
        <Axis />
        <Content labels={labels} events={props.events} />
      </div>
    </React.Fragment>
  )
}
