import React from "react";
import iconFormGreen from "../../assets/icons/ico-formGreen.svg";
import { LifePlansImages } from "../../assets/images";
const { commentCustomer, commentStaff, arrowBGrad } = LifePlansImages;

function SuccessfulSaleTabContentWidget() {
  return (
    <>
      <div className="commentLine">
        <div className="commentDet staff">
          <figure>
            <img src={commentCustomer} alt="" />
            <figcaption>お客様</figcaption>
          </figure>
          <div className="comment">
            <p>
              初めての投資物件の売却ですが、どこの会社にお願いすべきでしょうか？？各社査定金額がバラバラで迷ってしまいます。
            </p>
          </div>
        </div>
        <div className="commentDet staff">
          <figure>
            <img src={commentStaff} alt="" />
            <figcaption>
              営業スタッフ
            </figcaption>
          </figure>
          <div className="comment">
            <p>必ずしも一番査定価格が高い会社が最も高く物件を高く
              売れるとは限りません。高額で売ったが故に売却期間が
              長期化し、結果としてお取引内容が悪くなるリスクもあ
              ります。大切なのは、価格の根拠であり、お客様を第一に
              考えた誠実なご提案を行う会社です。</p>
          </div>
        </div>
      </div>
      <div className="arrow">
        <img src={arrowBGrad} alt="" />
        <p>
          はじめて売却する方にも無料で相談。
          <br className="sp" />
          安心のサポートをいたします。
        </p>
      </div>
      <div className="contactGreenSty">
        <a href="/contact/contact_sales/new">
          <h3>
            無料相談は<span className="inlBlock fontWB">かんたん1分で完了</span>
          </h3>
          <span className="whiteLineBtn">
            <img src={iconFormGreen} alt="" width="32" height="26" />
            お問い合わせはこちら
          </span>
          <span className="ttlSty">CONTACT</span>
        </a>
      </div>
    </>
  );
}

export default React.memo(SuccessfulSaleTabContentWidget);
