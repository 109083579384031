import React from "react";
import { LifePlansImages } from "../../assets/images";
import iconFormGreen from "../../assets/icons/ico-formGreen.svg";
const { commentCustomer, commentStaff, arrowBGrad } = LifePlansImages;

function SuccessfulCaseOfAdditionalPurchaseTabWidget() {
  return (
    <>
      <div className="commentLine">
        <div className="commentDet staff">
          <figure>
            <img src={commentCustomer} alt="" />
            <figcaption>お客様</figcaption>
          </figure>
          <div className="comment">
            <p>
              今後のことを考え、相続対策として不動産
              投資を考えております。どのような物件に
              投資をするかアドバイスいただけないでしょうか？
            </p>
          </div>
        </div>
        <div className="commentDet staff">
          <figure>
            <img src={commentStaff} alt="" />
            <figcaption>
              営業スタッフ
            </figcaption>
          </figure>
          <div className="comment">
            <p>お客様の財産状況を整理し、有効な相続対策をトータル的に
            ご提案いたします。手続き面も含め、専門の相続相談チーム
            がお手伝いさせていただきます。ROA診断等、納得のいく
            相続対策をご提案いたします。</p>
          </div>
        </div>
      </div>
      <div className="arrow">
        <img src={arrowBGrad} alt="" />
        <p>
          上記内容も無料で相談。
          <br className="sp" />
          安心のサポートをいたします。
        </p>
      </div>
      <div className="contactGreenSty">
        <a href="/contact/contact_generals/new">
          <h3>
            無料相談は<span className="inlBlock fontWB">かんたん1分で完了</span>
          </h3>
          <span className="whiteLineBtn">
            <img src={iconFormGreen} alt="" width="32" height="26" />
            お問い合わせはこちら
          </span>
          <span className="ttlSty">CONTACT</span>
        </a>
      </div>
    </>
  );
}
export default React.memo(SuccessfulCaseOfAdditionalPurchaseTabWidget);
