import React from 'react';
import Event from "./Event";

export default function EventGhost(props) {
  return (
    <React.Fragment>
      <div className={`timelineEventGhost timelineEventGhost_${props.event.event_type}`}>
        <Event event={props.event} />
      </div>
    </React.Fragment>
  )
}
