import React, { useRef } from "react";
import { Form, Field, Formik } from 'formik';

export default function MultipleOptionStep(props) {
  const formState = useRef();
  const initialValues = props.initialValues

  const handleTriggerNextStep = async() => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const values = formState.current.values

    if (props.formType == "contact") {
      const contact = JSON.parse(window.lsSession.getItem('chatbot-contact'))
      const newObj = {...contact, ...values}
      const contactStr = JSON.stringify(newObj)
      window.lsSession.setItem('chatbot-contact', contactStr)
    }

    props.triggerNextStep({ trigger: values.reason_for_sale.includes("6") ? props.otherTrigger : props.trigger, value: values, replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { values, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="titleOption">{props.title}</div>
            {props.options.map((opt) => (
              <div className="option checkBox" key={opt.value}>
                <Field type="checkbox" id={props.name + opt.value} name={props.name} value={opt.value} checked={Object.values(values)[0].some(v => v == opt.value)} />
                <label htmlFor={props.name + opt.value}>{opt.label}</label>
              </div>
            ))}
            <button type="submit" className="btnSubmit" >
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
