import React, { useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { YupSchema } from '../../../shared/constants/chatbot-validators';
import { ErrorMessage } from "formik";
import TextError from '../../../shared/components/error-message';
import TextInput from './CustomField/TextInput';

export default function CustomerNameForm(props) {
  const { t } = useTranslation('saleModal');
  const validateSchema = new YupSchema(t).customerNameForm;
  const formState = useRef();
  const initialValues = props.initialValues

  const handleTriggerNextStep = async(values) => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const storage = JSON.parse(window.lsSession.getItem(`chatbot-${props.formType}`))
    const newObj = {...storage, ...values}
    const storageStr = JSON.stringify(newObj)
    window.lsSession.setItem(`chatbot-${props.formType}`, storageStr)
    props.triggerNextStep({ trigger: props.trigger, value: values, replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { handleSubmit, touched, errors } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="selectOption">
              <div className="titleSelect">お名前</div>
              {((errors.last_name && touched.last_name) || (errors.first_name && touched.first_name)) && (
                <ErrorMessage name={errors.last_name ? 'last_name' : 'first_name'} render={TextError} />
              )}
              <div className="dF">
                <div className="input1">
                  <div className="selectOption">
                    <div className="wid100 pd-r-15">
                      <Field name="last_name" component={TextInput} type="text" placeholder="姓" hideerror="true" />
                    </div>
                  </div>
                </div>
                <div className="input1">
                  <div className="selectOption">
                    <div className="wid100 pd-r-15">
                      <Field name="first_name" component={TextInput} type="text" placeholder="名" hideerror="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="selectOption">
              <div className="titleSelect">ふりがな</div>
              {((errors.last_name_kana && touched.last_name_kana) || (errors.first_name_kana && touched.first_name_kana)) && (
                <ErrorMessage name={errors.last_name_kana ? 'last_name_kana' : 'first_name_kana'} render={TextError} />
              )}
              <div className="dF">
                <div className="input1">
                  <div className="selectOption">
                    <div className="wid100 pd-r-15">
                      <Field name="last_name_kana" component={TextInput} type="text" placeholder="せい" hideerror="true" />
                    </div>
                  </div>
                </div>
                <div className="input1">
                  <div className="selectOption">
                    <div className="wid100 pd-r-15">
                      <Field name="first_name_kana" component={TextInput} type="text" placeholder="めい" hideerror="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btnSubmit">
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
