import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { generateYearsByMemberAge, ModalName } from '../../shared/constants/global';
import LifePlanModal from '../../shared/components/modal';
import { closeModal, openSubModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import { LifePlansImages } from '../../assets/images';
import { Form, Field, Formik } from 'formik';
import InputText from '../../shared/components/input-field';
import CommaInput from '../../shared/components/comma-input-field';
import SelectFieldSecond from '../../shared/components/select-field-second';
import { YupSchema } from '../../shared/constants/validators';
import { cloneDeep } from "lodash";

const {imgPet1, imgPet2, imgPet3, imgPet4, imgPet5, imgPet6, imgPet7, imgPet8} = LifePlansImages;
const arrImages = [imgPet1, imgPet2, imgPet3, imgPet4, imgPet5, imgPet6, imgPet7, imgPet8];

export default function EventPetModal(props) {
  const { t } = useTranslation('eventPetModal');
  const tGlobal = useTranslation('global').t;
  const dispatch = useDispatch();
  const { lifeEvent } = props;
  const uuid = useSelector(state => state.lifeEventSelected.uuid);
  const storeLifeEvents = useSelector(state => state.life_plan.life_event);

  const formState = useRef();

  const [checkShow, setCheckShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [initialValues, setInitialValues] = useState({ ...props.lifeEvent, other: { ...props.lifeEvent.other } });

  const validateSchema = useMemo(() => {
    return new YupSchema(t).eventPetModal;
  }, [])

  useEffect(() => {
    const values = { ...props.lifeEvent, other: { ...props.lifeEvent.other } }
    setInitialValues(values)
    setCheckShow(values.other.detail == 8)
    if (uuid) {
      let cycleYear = storeLifeEvents.find((event) => event.uuid === uuid).year
      formState.current.setFieldValue("year", cycleYear);
    }
  }, [props.lifeEvent])

  const handleClose = () => {
    dispatch(closeModal());
  }

  const getDetailName = (detail, data) => {
    switch (detail) {
      case 1:
        return "熱帯魚"
      case 2:
        return "猫"
      case 3:
        return "小型犬"
      case 4:
        return "大型犬"
      case 5:
        return "ハリネズミ"
      case 6:
        return "うさぎ"
      case 7:
        return "鳥"
      case 8:
        return data.other?.custom || ""
      default:
        return ""
    }
  }

  const prepareData = (values) => {
    const tempData = cloneDeep(values);
    const params = {
      ...tempData,
      other: {
        ...tempData.other,
        detail_name: getDetailName(+tempData.other?.detail, tempData)
      }
    }
    return params;
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      const data = prepareData(values);
      dispatch(saveSelectedLifeEvent(data));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    return `${subYear(year)}歳（${year}年）`
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age;
  }

  const onSelect = (data) => {
    setSelectedValue(+data)
    if (+formState.current.values.end_year < +data) {
      formState.current.setFieldValue("end_year", +data);
    }
  }

  const dataEndYear = useMemo(() => {
    if (!selectedValue) {
      return Object.keys(yearMapping).filter(ele => {
        if (uuid) {
          let cycleYear = storeLifeEvents.find((event) => event.uuid === uuid).year
          return +ele >= cycleYear
        } else {
          return +ele >= initialValues.year
        }
      });
    } else {
      return Object.keys(yearMapping).filter(ele => {
        return +ele >= +selectedValue;
      });
    }
  }, [selectedValue, initialValues.year])

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_PET}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>{t('label')}</h2>
                  <div className="popupContents">
                    <div className="popupContentsCover">
                      <div id="checkshow1" className="whiteBox checkShowToggle radioImgBox pad24 padBottom8">
                        <ul className="radioImgBtnList rowList">
                          {[...Array(8)].map((e, i) => {
                            return <li key={i}><label className={`radioBtn ${i + 1 == values.other.detail ? "checked" : ""}`}>
                                    <Field onClick={() => setCheckShow(i == 7)}
                                      type="radio" value={i + 1} name="other.detail" className="toggle_hide" />
                                    <img src={arrImages[i]} /><span>{t(`options.${i}`)}{t(`cost.${i}`)}</span>
                                  </label></li>
                          })}
                        </ul>
                      </div>
                    </div>
                    <dl className="formSty lifeplanImgBtnBox marB40">
                      <dt id="checkShow1-ttl" className={`checkShow1 ${!checkShow ? 'hiddenTtl' : ''}`}>{t('custom')}</dt>
                      <dd id="checkShow1-item" className={`checkShow1 noFlex ${!checkShow ? 'hiddenItem' : ''}`}>
                        <div className="wid400 marB1em">
                          <Field className="toggle-item" type="text" component={InputText} name="other.custom" placeholder={t('customPlaceholder')} disabled={!checkShow} />
                        </div>
                        <div className="dF">
                          <dl className="formTtlSty nowrap">
                            <dt>{t('purcharseCost')}</dt>
                            <dd>
                              <Field className="wid120" placeholder="5" type="text" component={CommaInput} name="other.cost_at_first" disabled={!checkShow} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                            </dd>
                            <dt>{t('breedingCost')}</dt>
                            <dd>
                              <Field className="wid120" placeholder="1" type="text" component={CommaInput} name="other.price" disabled={!checkShow} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                            </dd>
                          </dl>
                        </div>
                      </dd>
                      <dt>{t('breedinPeriod')}</dt>
                      <dd className="formTtlSty nowrap">
                        <div className="dF aisC spFlexWrap">
                          <div className="dF">
                            <Field component={SelectFieldSecond} width="wid190" name="year" customChange={onSelect} options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                            <span className="mT15">　から　</span>
                          </div>
                          <div className="dF mLPc15">
                            <Field component={SelectFieldSecond} width="wid190" name="end_year" options={dataEndYear} display_options={renderYearFromNow} />
                            <span className="mT15 formUnit">{t('keepToUp')}</span>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList col2">
                        <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                        <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                        { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
