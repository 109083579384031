import React, { useState, useEffect } from "react";
import {convertToJapanCurrency} from "../../shared/constants/global";

const LpsItemHeadTxtWidget = (props) => {
  const { loggedIn, lifePlan, lifePlanSimulationResult, showPropertiesWidget, totalIncoming, totalSpending } = props;
  const [ageSelected, setAgeSelected] = useState(100)
  const [listAge, setListAge] = useState([])
  const [diagnosisResult, setDiagnosisResult] = useState()

  const handleChange = (e) => {
    props.handleChange && props.handleChange(e);

    setAgeSelected(e.target.value);
    setDiagnosisResult(getSavingIncludeRealEstate(e.target.value));
  }

  const getSavingIncludeRealEstate = (age) => {
    return lifePlanSimulationResult.find((result) => result.age_of_person === parseInt(age)).savings_include_real_estate;
  }

  useEffect(() => {
    if (lifePlanSimulationResult.length) {
      setDiagnosisResult(getSavingIncludeRealEstate(ageSelected))
    }
  }, [lifePlanSimulationResult])

  useEffect(() => {
    const listItemAge = [];
    const personAge = lifePlan.person_age;

    for (let i = 100; i >= personAge; i--) {
      listItemAge.push(<option key={i} value={i}>{i}歳</option>)
    }

    setListAge(listItemAge);
  }, [lifePlan])

  return (
    <div className="headTxt">
      <div className={loggedIn ? '' : "blurCont"}>
        <div className="selectStyBox">
          <select value={ageSelected} onChange={handleChange}>
            {listAge}
          </select>
        </div>
        {
          diagnosisResult >= 0 ? (
            <p>
              歳時点で <strong><span className="positiveNumber">{convertToJapanCurrency(diagnosisResult)}</span></strong>
              の貯蓄が残ります。
            </p>
          ) : (
            <p>
              歳時点で <strong><span className="errorColor">{convertToJapanCurrency(diagnosisResult).substring(1)}</span></strong>
              の手元資金が足りなくなります。​
            </p>
          )
        }

      </div>
      <div className={loggedIn ? '' : "blurCont"}>
        {
          (!showPropertiesWidget && diagnosisResult < 0) ? (
            <span>ライフイベントにて安定した人生プランを再検討することが可能です。​</span>
          ) : null
        }

        {
          (totalIncoming > totalSpending) ? (
            <span>ライフイベントにてより豊かな人生プランを再検討することが可能です。​</span>
          ) : null
        }

      </div>
    </div>
  );
}

export default React.memo(LpsItemHeadTxtWidget);
