import React from 'react';
import { getIn, ErrorMessage } from 'formik';
import TextError from '../../../../shared/components/error-message';

export default function SelectField(props) {
  const { field, form, options, width, display_options, customChange, blank, format, ...selectProps } = props;

  const name = field.name;

  function getStyles(errors, touched, name) {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  const handleChange = (e) => {
    const changeEvent = {
      target: {
        name: e.target.name,
        value: e.target.value
      }
    }
    if (customChange) {
      field && field.onChange(changeEvent);
      customChange(e.target.value);
    } else {
      field && field.onChange(changeEvent);
    }
  }

  return <React.Fragment>
    <ErrorMessage name={name} render={TextError} />
    <div className={format == "long" ? "formSelect formSelect--long" : "formSelect"}>
      <select
        {...selectProps}
        id={name}
        {...field}
        className="select"
        style={getStyles(form.errors, form.touched, name)}
        onChange={handleChange}
      >
        {blank && <option value=''>{blank}</option>}
        {options.map((opt, index) => <option key={opt + index} value={opt}>{opt}</option>)}
      </select>
    </div>
  </React.Fragment>
}
