import { useSelector } from "react-redux";

export const ModalName = {
  LIFEPLAN_WELCOME: 'welcome',
  LIFEPLAN_FAMILY: 'family',
  LIFEPLAN_INPUT_SPOUSE: 'input_spouse',
  LIFEPLAN_INPUT_SPENDING: 'spending',
  LIFEPLAN_TUTORIAL: 'tutorial',
  LIFEPLAN_EVENT_PURCHASE_REALESTATE: 'buy_investment',
  LIFEPLAN_SALE: 'sell_investment',
  LIFEPLAN_SALE_2: 'sell_investment_2',
  LIFEPLAN_EVENT_PURCHASE_HOME: 'buy_housing',
  LIFEPLAN_EVENT_SELL_HOME: 'sell_housing',
  LIFEPLAN_EVENT_TRAVEL: 'trip',
  LIFEPLAN_EVENT_CAR: 'car',
  LIFEPLAN_EVENT_HOBBY: 'hobby',
  LIFEPLAN_EVENT_EATOUT: 'eatout',
  LIFEPLAN_EVENT_PET: 'pet',
  LIFEPLAN_EVENT_FAMILY: 'event_family',
  LIFEPLAN_EVENT_SECONDCARRIER: 'secondcarrier',
  LIFEPLAN_SAVE: 'save',
  LIFEPLAN_CANNOT_ADD_EVENT: 'cannot_add_event',
  LIFEPLAN_CONFIRM_DELETE: 'confirm_delete',
  LIFEPLAN_GRAPH_MODAL: 'graph_modal'
}

export const ValidateField = {
  FamilyModal: {
    MIN_AGE: 20,
    MAX_AGE: 99,
    CHILD_MIN_AGE: 0
  },
  InputSpouseModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000
  },
  InputSpendingModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000
  },
  EventPurchaseHomeModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    MIN_RATE: 0,
    MAX_RATE: 100,
    CUSTOM_FORM: 128,
    MAX_LOAN_YEAR: 80
  },
  EventPurchaseRealesateModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    MIN_RATE: 0,
    MAX_RATE: 100,
    MAX_LOAN_YEAR: 80
  },
  EventSaleHomeModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000
  },
  EventSaleModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000
  },
  EventTravelModal: {
    MAX_FREQUENCY: 50,
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  EventCarModal: {
    MAX_FREQUENCY: 50,
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  EventHobbyModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  EventEatoutModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  EventPetModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  EventFamilyModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  EventSecondcarrierModal: {
    MIN_MONEY: 0,
    MAX_MONEY: 100000,
    CUSTOM_FORM: 128
  },
  Global: {
    MIN_LOAN_YEAR: 35
  }
}

export const DefaultLifePlan = {
  member_id: null,
  partner: null,
  person_age: null,
  number_of_people: null, // TODO: remove this line
  person_pension: null,
  partner_age: null,
  partner_pension: null,
  child: [],
  person_income: null,
  person_retirement_money: null,
  partner_income: null,
  partner_retirement_money: null,
  other_income: null,
  other_income_end: null,
  saving_amount: null,
  maturity: null,
  future_sum: null,
  reserve_monthly: null,
  reserve_end: null,
  residence_type: null,
  rent: null,
  housing_loan: null,
  housing_monthly_repayment: null,
  housing_loan_balance: null,
  housing_bonus_repayment: null,
  housing_repayment_end: null,
  term_insurance_monthly: null,
  term_insurance_end: null,
  life_event: []
}

export const DefaultLifeEvent = {
  event_type: null,
  year: null,
  end_year: null,
  re_buy: {
    buy_property: null,
    buy_property_name: null,
    own_fund: null,
    loan_rate: null
  },
  re_sell: {
    property_id: null,
    property_type: null,
    pref_code: null,
    city_code: null,
    region_code: null,
    after_town: null,
    line_code: null,
    station_code: null,
    walking_minutes: null,
    building_structure: null,
    built_date: null,
    occupation_area: null,
    floor_located: null,
    number_of_floors: null,
    total_floor_space: null,
    number_of_houses: null,
    purchase_time: null,
    investment_property_loan: null,
    investment_monthly_repayment: null,
    investment_loan_balance: null,
    investment_bonus_repayment: null,
    investment_repayment_end: null,
    operating_costs: null,
    renovation_reserves: null,
    market_rents: [],
    gross_rate_error_rate_std: null,
    rent_error_rate_std: null,
    sell_price_error_rate_std: null,
    market_gross_rates: [],
    market_sell_prices: [],
    vacancy_rates: [],
    min_vacancy_rates: [],
    max_vacancy_rates: []
  },
  house_buy: {
    buy_property: null,
    buy_property_name: null,
    custom: null,
    buy_cost: null,
    own_fund: null,
    loan_rate: null
  },
  house_sell: {
    sell_price: null,
    rent: null
  },
  other: {
    detail: null,
    detail_name: null,
    custom: null,
    price: null,
    frequency_year: null,
    frequency_count: null,
    cost_at_first: null,
    number_of_people: null,
    frequency_car_buy: null,
    elementary_school: null,
    middle_school: null,
    high_school: null,
    university: null
  }
}

export const convertCurrency = (val) => {
  const pattern = /\B(?=(\d{3})+(?!\d))/g;
  if (Number.isNaN(val)) {
    return
  } else if (val == 0) {
    return `0円`
  } else if (val < 10000) {
    return `${val.toString().replace(pattern, ",")}万円`
  } else {
    return `${val / 10000}${(val / 10000).toString().length > 5 ? "<br>" : ""}億円`
  }
}

const currentYear = new Date().getFullYear()
const getEndYearBuyInvestment = (age) => {
  return currentYear + ValidateField.EventPurchaseRealesateModal.MAX_LOAN_YEAR - age
}

const getEndYearBuyHousing = (age) => {
  return currentYear + ValidateField.EventPurchaseHomeModal.MAX_LOAN_YEAR - age
}

const getEndYearTrip = (age) => {
  if (age + 10 > 99) {
    return currentYear - age + 99
  } else if (80 > age+ 10) {
    return currentYear - age + 80
  } else {
    return currentYear + 10
  }
}

const getEndYearCar = (age) => {
  if (age + 6 > 99) {
    return currentYear - age + 99
  } else {
    return currentYear + 6
  }
}

const getEndYearHobby = (age) => {
  if (age + 10 > 99) {
    return currentYear - age + 99
  } else if (70 > age+ 10) {
    return currentYear - age + 70
  } else {
    return currentYear + 10
  }
}

const getEndYearEatout = (age) => {
  if (age + 10 > 99) {
    return currentYear - age + 99
  } else if (80 > age+ 10) {
    return currentYear - age + 80
  } else {
    return currentYear + 10
  }
}

const getEndYearPet = (age) => {
  if (age + 15 > 99) {
    return currentYear - age + 99
  } else {
    return currentYear + 15
  }
}

const getYearSecondcarrier = (age) => {
  return currentYear - age + 65
}

export function getLifeEventMapping(data={}) {
  const age = data.age
  const number_of_people = data.number_of_people

  return {
    buy_investment: {
      id: 1,
      type_name: "buy_investment",
      name: "投資不動産購入",
      is_buy: true,
      modal_name: ModalName.LIFEPLAN_EVENT_PURCHASE_REALESTATE,
      getAmount: (event) => {
        switch(+event.re_buy.buy_property) {
          case 1: return 1000
          case 2: return 3000
          case 3: return 5000
          case 4: return 4000
          case 5: return 9000
          case 6: return 15000
          case 7: return 10000
          case 8: return 20000
          case 9: return 30000
          default: return 1000
        }
      },
      getCycleAmount: () => {
        return 0
      },
      getEventTitle: () => {},
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 1,
        year: currentYear,
        end_year: getEndYearBuyInvestment(+age),
        re_buy: {
          ...DefaultLifeEvent.re_buy,
          buy_property: 1,
          buy_property_name: "",
          own_fund: 600,
          loan_rate: 2,
        }
      })
    },
    sell_investment: {
      id: 2,
      type_name: "sell_investment",
      name: "投資不動産売却",
      is_sell: true,
      modal_name: ModalName.LIFEPLAN_SALE,
      getAmount: () => {},
      getCycleAmount: () => {},
      getEventTitle: () => {},
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 2,
        year: currentYear,
        re_sell: {
          ...DefaultLifeEvent.re_sell
        },
        prediction_result: {}
      })
    },
    buy_housing: {
      id: 3,
      type_name: "buy_housing",
      name: "住宅購入",
      is_buy: true,
      modal_name: ModalName.LIFEPLAN_EVENT_PURCHASE_HOME,
      getAmount: (event) => {
        switch(+event.house_buy.buy_property) {
          case 1: return 4600
          case 2: return 3000
          case 3: return 4000
          case 4: return 5000
          case 5: return 3500
          case 6: return 2500
          case 7: return 3000
          case 8: return 4500
          case 9: return 4000
          case 10: return 5500
          case 11: return 4000
          case 12: return event.house_buy.buy_cost || 0
          default: return 4500
        }
      },
      getCycleAmount: () => {
        return 0
      },
      getEventTitle: () => {},
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 3,
        year: currentYear,
        end_year: getEndYearBuyHousing(+age),
        house_buy: {
          ...DefaultLifeEvent.house_buy,
          buy_property: 8,
          buy_property_name: "",
          custom: null,
          buy_cost: null,
          own_fund: 300,
          loan_rate: 1,
        }
      })
    },
    sell_housing: {
      id: 4,
      type_name: "sell_housing",
      name: "住宅売却",
      is_sell: true,
      modal_name: ModalName.LIFEPLAN_EVENT_SELL_HOME,
      getAmount: (event) => {
        return event.house_sell.sell_price
      },
      getCycleAmount: () => {
        return 0
      },
      getEventTitle: () => {},
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 4,
        year: currentYear,
        end_year: null,
        house_sell: {
          ...DefaultLifeEvent.house_sell,
          sell_price: 15,
          rent: 10
        }
      })
    },
    trip: {
      id: 6,
      type_name: "trip",
      name: "旅行",
      modal_name: ModalName.LIFEPLAN_EVENT_TRAVEL,
      getAmount: () => {
        return 0
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 3
          case 2: return 5
          case 3: return 5
          case 4: return 8
          case 5: return 10
          case 6: return 40
          case 7: return 50
          case 8: return 60
          case 9: return 70
          case 10: return 300
          case 11: return 1300
          case 12: return event.other.price || 0
          default: return 5
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "国内温泉旅行"
          case 2: return "国内リゾート旅行"
          case 3: return "沖縄旅行"
          case 4: return "韓国旅行"
          case 5: return "グアム旅行"
          case 6: return "アメリカ旅行"
          case 7: return "ハワイ旅行"
          case 8: return "オーロラ鑑賞ツアー"
          case 9: return "ヨーロッパ列車の旅"
          case 10: return "世界一周旅行"
          case 11: return "宇宙旅行"
          case 12: return event.other.custom
          default: return "沖縄旅行"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 6,
        year: currentYear,
        end_year: getEndYearTrip(+age),
        other: {
          ...DefaultLifeEvent.other,
          detail: 3,
          frequency_year: 1,
          number_of_people: number_of_people,
        }
      })
    },
    car: {
      id: 7,
      type_name: "car",
      name: "自動車",
      modal_name: ModalName.LIFEPLAN_EVENT_CAR,
      getAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 200
          case 2: return 400
          case 3: return 500
          case 4: return 800
          case 5: return 600
          case 6: return 1000
          case 7: return 2000
          case 8: return event.other.cost_at_first || 0
          default: return 500
        }
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 30
          case 2: return 30
          case 3: return 30
          case 4: return 40
          case 5: return 40
          case 6: return 40
          case 7: return 40
          case 8: return 0
          default: return 30
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "軽自動車"
          case 2: return "国産セダン"
          case 3: return "国産ミニバン"
          case 4: return "国産高級車"
          case 5: return "外車"
          case 6: return "高級外車"
          case 7: return "スーパーカー"
          case 8: return event.other.custom
          default: return "国産ミニバン"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 7,
        year: currentYear,
        end_year: getEndYearCar(+age),
        other: {
          ...DefaultLifeEvent.other,
          detail: 3,
          frequency_year: 7,
        }
      })
    },
    hobby: {
      id: 11,
      type_name: "hobby",
      name: "趣味・遊戯費",
      modal_name: ModalName.LIFEPLAN_EVENT_HOBBY,
      getAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 1
          case 2: return 10
          case 3: return 5
          case 4: return 7
          case 5: return 7
          case 6: return 700
          case 7: return 10
          case 8: return 150
          case 9: return 1000
          case 10: return 800
          case 11: return 1
          case 12: return 5
          case 13: return 1.5
          case 14: return 50
          case 15: return event.other.cost_at_first || 0
          default: return 7
        }
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 9.6
          case 2: return 1
          case 3: return 10
          case 4: return 12
          case 5: return 10
          case 6: return 40
          case 7: return 18
          case 8: return 7
          case 9: return 840
          case 10: return 100
          case 11: return 60
          case 12: return 9.6
          case 13: return 12
          case 14: return 10
          case 15: return event.other.price || 0
          default: return 1
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "ガーデニング"
          case 2: return "カメラ"
          case 3: return "釣り"
          case 4: return "ゴルフ"
          case 5: return "キャンプ"
          case 6: return "キャンピングカー"
          case 7: return "ダイビング"
          case 8: return "高級腕時計"
          case 9: return "競馬馬主"
          case 10: return "ボート"
          case 11: return "ヨガ"
          case 12: return "英会話"
          case 13: return "ジム・筋トレ"
          case 14: return "バイクツーリング"
          case 15: return event.other.custom
          default: return "ゴルフ"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 11,
        year: currentYear,
        end_year: getEndYearHobby(+age),
        other: {
          ...DefaultLifeEvent.other,
          detail: 4
        }
      })
    },
    eatout: {
      id: 5,
      type_name: "eatout",
      name: "外食",
      modal_name: ModalName.LIFEPLAN_EVENT_EATOUT,
      getAmount: () => {
        return 0
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 7.2
          case 2: return 14.4
          case 3: return 24
          case 4: return 48
          case 5: return 48
          case 6: return 96
          case 7: return event.other.price * 12 || 0
          default: return 14.4
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "手軽な外食（月２回程度）"
          case 2: return "手軽な外食（毎週末）"
          case 3: return "少し高級な外食（月2回程）"
          case 4: return "少し高級な外食（それぞれ毎週末）"
          case 5: return "高級外食（月２回程度）"
          case 6: return "高級外食（毎週末）"
          case 7: return event.other.custom
          default: return "手軽な外食（毎週末）"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 5,
        year: currentYear,
        end_year: getEndYearEatout(+age),
        other: {
          ...DefaultLifeEvent.other,
          detail: 2,
          number_of_people: number_of_people
        }
      })
    },
    pet: {
      id: 8,
      type_name: "pet",
      name: "ペット",
      modal_name: ModalName.LIFEPLAN_EVENT_PET,
      getAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 5
          case 2: return 15
          case 3: return 20
          case 4: return 30
          case 5: return 5
          case 6: return 8
          case 7: return 8
          case 8: return event.other.cost_at_first || 0
          default: return 20
        }
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 3
          case 2: return 16
          case 3: return 34
          case 4: return 34
          case 5: return 5
          case 6: return 15
          case 7: return 10
          case 8: return event.other.price || 0
          default: return 34
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "熱帯魚"
          case 2: return "猫"
          case 3: return "小型犬"
          case 4: return "大型犬"
          case 5: return "ハリネズミ"
          case 6: return "うさぎ"
          case 7: return "鳥"
          case 8: return event.other.custom
          default: return "小型犬"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 8,
        year: currentYear,
        end_year: getEndYearPet(+age),
        other: {
          ...DefaultLifeEvent.other,
          detail: 3
        }
      })
    },
    family: {
      id: 9,
      type_name: "family",
      name: "家族の費用",
      modal_name: ModalName.LIFEPLAN_EVENT_FAMILY,
      getAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 350
          case 2: return 50
          case 3: return 200
          case 4: return 100
          case 5: return event.other.cost_at_first || 0
          default: return 200
        }
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 4: return 180
          case 5: return event.other.price || 0
          default: return 0
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "結婚"
          case 2: return "子供の誕生"
          case 3: return "子供の結婚式"
          case 4: return "介護施設入居費"
          case 5: return event.other.custom
          default: return "子供の結婚式"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 9,
        year: currentYear,
        end_year: currentYear,
        other: {
          ...DefaultLifeEvent.other,
          detail: 3,
          elementary_school: 2,
          middle_school: 2,
          high_school: 2,
          university: 1,
        }
      })
    },
    secondcarrier: {
      id: 10,
      type_name: "secondcarrier",
      name: "セカンドキャリア",
      modal_name: ModalName.LIFEPLAN_EVENT_SECONDCARRIER,
      getAmount: (event) => {
        switch(+event.other.detail) {
          case 1: return 900
          case 2: return 1000
          case 3: return 1000
          case 4: return 1500
          case 5: return 300
          case 6: return 800
          case 7: return 30
          case 8: return event.other.cost_at_first || 0
          default: return 1000
        }
      },
      getCycleAmount: (event) => {
        switch(+event.other.detail) {
          case 7: return 150
          default: return 0
        }
      },
      getEventTitle: (event) => {
        switch (+event.other.detail) {
          case 1: return "パン屋（開業）"
          case 2: return "カフェ（開業）"
          case 3: return "蕎麦屋（開業）"
          case 4: return "ラーメン屋（開業）"
          case 5: return "コンビニ（開業）"
          case 6: return "不動産業（開業）"
          case 7: return "大学入学"
          case 8: return event.other.custom
          default: return "蕎麦屋（開業）"
        }
      },
      getDefaultValues: () => Object.assign({}, DefaultLifeEvent, {
        event_type: 10,
        year: getYearSecondcarrier(+age),
        other: {
          ...DefaultLifeEvent.other,
          detail: 3,
        }
      })
    }
  }
}

export const getEventMapping = (event) => {
  return Object.values(getLifeEventMapping()).find((val) => val.id === event.event_type)
}

export const generateYearsByMemberAge = (options = {forVerticalTimeLine: false}) => {
  const PAGINATION_SIZE = 10
  const MAX_AGE = 99
  const currentAge = useSelector(state => state.life_plan.person_age)
  let fromAge = +currentAge
  let toAge = MAX_AGE
  if (options.forVerticalTimeLine) {
    fromAge = Math.floor(fromAge / PAGINATION_SIZE) * PAGINATION_SIZE
    toAge = Math.ceil((MAX_AGE + 1) / PAGINATION_SIZE) * PAGINATION_SIZE - 1
  }
  let year = currentYear - currentAge + fromAge
  let result = []
  for (let i = fromAge; i <= toAge; i++) {
    result.push({ age: i, year: year })
    year += 1
  }

  return result
}

export const convertToJapanCurrency = (value) => {
  if(!value) return '0円';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "円";
}

const arrCycleColor = [
  "#b9fbc0", "#97d9e1" ,"#98f5e1", "#e0c3fc", "#8eecf5",
  "#80d0c7", "#fbf8cc", "#90dbf4", "#a3c4f3", "#cfbaf0",
  "#fde4cf", "#f1c0e8", "#ffcfd2"
]

let indexColor = 0
export const getCycleEventColor = () => {
  const color = arrCycleColor[indexColor]
  indexColor = arrCycleColor[indexColor + 1] ? indexColor + 1 : 0
  return color
}

export const toInt = (number) => {
  return isNaN(parseInt(number)) ? 0 : parseInt(number)
}
