import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import CompareIncomingAndSpendingGraph from "../compare-incoming-and-spending-graph"

export default function AiSaleChart(props) {
  const {lastEventSellProperty, loggedIn, incomingPercent, totalIncoming, spendingPercent, totalSpending} = props
  const today = new Date()
  const year = today.getFullYear()
  const pattern = /\B(?=(\d{3})+(?!\d))/g;

  const calculateEstimatedYearAndStepRecord = () => {
    const totalYear = lastEventSellProperty.year - year
    let estimatedYear

    if(totalYear < 10){
      estimatedYear = 10
    }else if(totalYear >= 10 && totalYear <= 50){
      estimatedYear = totalYear
    }else if(totalYear > 50){
      estimatedYear = 50
    }

    const stepRecord = +(estimatedYear / 10.2).toFixed(0)

    return { estimatedYear, stepRecord }
  }

  const { estimatedYear, stepRecord } = calculateEstimatedYearAndStepRecord()

  function convertNumber(val, fix = 0) {
    return (val / 10000).toFixed(fix).replace(pattern, ",")
  }

  function convertNumber2(val, fix = 0) {
    return val.toFixed(fix).replace(pattern, ",")
  }

  function convertOku(val, bold) {
    if (val >= 100000000) {
      if (val % 100000000 == 0) {
        return `${convertNumber2(val / 100000000)}<span className="${bold ? 'fontWB' : ''}">億</span>`
      } else {
        return `${convertNumber2(Math.floor(val / 100000000))}<span className="${bold ? 'fontWB' : ''}">億</span>${convertNumber(val % 100000000)}<span className="${bold ? 'fontWB' : ''}">万円</span>`
      }
    } else {
      return `${convertNumber(val)}<span className="${bold ? 'fontWB' : ''}">万円</span>`
    }
  }

  function convertOkuChart(val) {
    if (val >= 100000000) {
      if (val % 100000000 == 0) {
        return `${convertNumber2(val / 100000000)}億`
      } else {
        return `${convertNumber2(Math.floor(val / 100000000))}億${convertNumber(val % 100000000)}万円`
      }
    } else {
      return `${convertNumber(val)}万円`
    }
  }

  const updateYaxisOption = (options, values) => {
    const yaxisValues = values.split(',').map(v => convertOkuChart(v))
    const minWidth = (yaxisValues.find(str => str.includes('億')) && yaxisValues.find(str => str.includes('万円'))) ? 60 : 0

    return {...options,
              yaxis: {
                ...options.yaxis,
                labels: {...options.yaxis.labels, minWidth: minWidth}
              }}
  }

  const getInitOptions = (estimatedYear, stepRecord) => {
    const initOptions = {
      chart: {
        height: 400,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: ["#FF5151", "#0575E6"],
      dataLabels: {enabled: false},
      stroke: {curve: "straight"},
      title: {
        text: "\xa0",
        align: "left"
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        },
      },
      markers: {
        size: 6,
        hover: {
          size: 8
        }
      },
      xaxis: {
        categories: [...Array(50).keys()],
        yAxisIndex: 0,
        strokeDashArray: 0,
        labels: {
          rotate: 0,
          show: true,
          formatter: function (val) {
            if (val === 0) {
              return "現在"
            } else if (val == estimatedYear) {
              return estimatedYear + "年後"
            } else if (parseInt((estimatedYear) / 2 + 0.5) == val) {
              return val + "年後"
            } else {
              return ""
            }
          },
          style: {
            fontSize: "16px",
            fontWeight: "bold",
          }
        }
      },
      yaxis: {
        tickAmount: 4.1,
        labels: {
          show: true,
          formatter: function (val) {
            let values = localStorage.getItem('yaxis')
            values = !values ? val : values.indexOf(val) >= 0 ? values : `${values},${val}`
            localStorage.setItem('yaxis', values)
            return convertOkuChart(val)
          }
        }
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -20,
        offsetX: -15,
        fontSize: "16px",
        fontWeight: "bold",
        itemMargin: {
          horizontal: 20,
          vertical: 0
        },
        showForSingleSeries: true
      },
      tooltip: {
        enabled: true,
        x: {
          formatter: (seriesName) => {
            return seriesName - 1 + year + "年"
          }
        },
        y: {
          formatter: (val) => convertOku(val)
        },
      }
    };
    return initOptions
  }

  const getRecords = (dataArray, stepRecord, estimatedYear) => {
    let records = []

    for(let i = 0; i <= estimatedYear; i++ ){
      records.push(dataArray[i]);
    }

    return records;
  }

  const calculateData = (stepRecord, estimatedYear) => {
    const upsideMarketSellPricesData = getRecords(lastEventSellProperty.prediction_result.upside_market_sell_prices.slice(0, 51), stepRecord, estimatedYear);
    const downsideMarketSellPricesData = getRecords(lastEventSellProperty.prediction_result.downside_market_sell_prices.slice(0, 51), stepRecord, estimatedYear);

    const data = [{
      name: "上振れ",
      data: upsideMarketSellPricesData
    },
    {
      name: "下振れ",
      data: downsideMarketSellPricesData
    }]
    return data
  }


  const [data, setData] = useState(calculateData(stepRecord, estimatedYear));

  const [options, setOptions] = useState(() => {
    const opt = getInitOptions(estimatedYear, stepRecord)
    const yaxisValues = localStorage.getItem('yaxis');
    if(yaxisValues) return updateYaxisOption(opt, yaxisValues);
    return opt;
  });

  useEffect(() => {
    try {
      const { estimatedYear, stepRecord } = calculateEstimatedYearAndStepRecord()

      const data = calculateData(stepRecord, estimatedYear)
      setData(data)

      const options = getInitOptions(estimatedYear, stepRecord)
      setOptions(options)

      localStorage.setItem('event2', JSON.stringify({'estimatedYear': estimatedYear, 'stepRecord': stepRecord}))
    } catch (e) {
      console.log(e)
    }
  }, [lastEventSellProperty])


  useEffect(() => {
    let timeout = null;
    window.addEventListener('activeResult', () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        const event2 = JSON.parse(localStorage.getItem('event2'));
        let options;
        if (event2) {
          options = getInitOptions(event2.estimatedYear, event2.stepRecord)
        } else {
          options = getInitOptions(estimatedYear, stepRecord)
        }
        const yaxisValues = localStorage.getItem('yaxis');
        if (yaxisValues) return setOptions(updateYaxisOption(options, yaxisValues))
      }, 2000)

    })

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('activeResult')
    }
  }, [])

  return <React.Fragment>
    <section className="aiResult">
      <div className="investmentPropertyResult">
        <p className="bgGtxB">収益物件売却による効果</p>
        <h2 className="marB32">投資用保有物件のAI査定価格</h2>
        <h3 className="resultTtl marB40">
          推定される売却価格は  <br className="sp" />
          {
            <strong dangerouslySetInnerHTML={{__html: convertOku(lastEventSellProperty.prediction_result.downside_market_sell_prices[0])}} />
          }
          <span>～</span>
          {
            <strong dangerouslySetInnerHTML={{__html: convertOku(lastEventSellProperty.prediction_result.upside_market_sell_prices[0])}} />
          }
        </h3>
        <div className="resultGraph marB40">
          <div className="graphBox lifePlanGraphBox">
            <Chart options={options} series={data} type="line" height="350" />
          </div>
          <div className="txt">
            <p className="font24">
              5年後は
              {
                <strong className='colRed' dangerouslySetInnerHTML={{__html: convertOku(lastEventSellProperty.prediction_result.downside_market_sell_prices[0] - lastEventSellProperty.prediction_result.downside_market_sell_prices[5], true)}} />
              }
              <strong className='colRed'>～</strong>
              {
                <strong className='colRed' dangerouslySetInnerHTML={{__html: convertOku(lastEventSellProperty.prediction_result.upside_market_sell_prices[0] - lastEventSellProperty.prediction_result.upside_market_sell_prices[5], true)}} />
              }
              <br />
              価格が
              <strong className='colRed'>下落</strong>
              する恐れがあります。
            </p>
          </div>
        </div>
        <CompareIncomingAndSpendingGraph loggedIn={loggedIn} incomingPercent={incomingPercent}
          totalIncoming={totalIncoming} spendingPercent={spendingPercent} totalSpending={totalSpending}/>
      </div>
    </section>
  </React.Fragment>
}
