import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { DomApi } from "../shared/utils";
import AppMain from "./main";

export default function AppLayout() {
    const selectedModal = useSelector(state => state.modalOpenName);
    
    useEffect(() => {
        if(!!selectedModal?.length) {
            DomApi.disabledScrollBodyWhenShowModal();
        } else {
            DomApi.enabledScrollWhenCloseModal();
        }
    }, [selectedModal]);

    return (
        <div className="app-layout">
            {/* <header className="header">
                
            </header> */}
            <main>
                <AppMain />
            </main>
        </div>
    )
}