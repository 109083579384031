import React from 'react';
import { getIn } from "formik";
import NumberFormat from "react-number-format";

export default function NumberInput(props) {
  const { field, form, type, placeholder, disabled, secondLabel, label, decimal, setfieldvalue } = props;

  const name = field.name;
  const errors = form.errors;
  const touched = form.touched;

  const showError = errors[name] && touched[name]

  const FIRST_DOT_NOTHING_BEFORE = /^\.+/

  function getStyles(errors, touched, name) {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  return <React.Fragment>
    {showError && <div className="error_msg">{errors[name]}</div>}
    <NumberFormat
      {...props}
      id={name}
      value={field.value}
      className="input"
      style={getStyles(form.errors, form.touched, name)}
      type={type}
      placeholder={placeholder || ''}
      disabled={disabled}
      thousandSeparator={true}
      inputMode="numeric"
      allowNegative={false}
      decimalScale={decimal || 0}
      onInput={(e) => {
        if (decimal && e.target.value !== '') {
          e.target.value = e.target.value.replace(FIRST_DOT_NOTHING_BEFORE, '')
        }
      }}
      onChange={(e) => {
        setfieldvalue(
          name,
          e.target.value.replace(/,/g, "")
        );
      }}
    />
  </React.Fragment>
}
