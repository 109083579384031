import React, { useState, useEffect } from 'react';
import SpendingChart from "../lps-expenditure-chart"

export default function ResultGraphSpendingWidget(props) {
    const { loggedIn, results } = props;

    return (
        <div className="resultGraph spending">
            <div className={loggedIn ? 'graph' : ' graph blurCont'}>
                <SpendingChart analysisResult={results} />
            </div>
        </div>
    )
}
