import React from "react";
import CanNotAddEventModal from "../../modals/cannot-add-event-modal";
import ConfirmDeleteModal from "../../modals/confirm-delete-modal";
import EventCarModal from "../../modals/event-car-modal";
import EventEatoutModal from "../../modals/event-eatout-modal";
import EventFamilyModal from "../../modals/event-family-modal";
import EventGhostList from "../../modals/event-ghost-list-modal";
import EventHobbyModal from "../../modals/event-hobby-modal";
import EventPetModal from "../../modals/event-pet-modal";
import EventPurchaseRealestateModal from "../../modals/event-purcharse-real-estate";
import EventPurchaseHomeModal from "../../modals/event-purchase-home-modal";
import EventSaleHomeModal from "../../modals/event-sale-home-modal";
import EventSecondCarrierModal from "../../modals/event-second-carrier-modal";
import EventTravelModal from "../../modals/event-travel-modal";
import FamilyModal from "../../modals/family-modal";
import GraphModal from "../../modals/graph-modal";
import InputSpendingModal from "../../modals/input-spending-modal";
import InputSpouseModal from "../../modals/input-spouse-modal";
import SaleModal from "../../modals/sale-modal";
import Sale2Modal from "../../modals/sale2-modal";
import SaveModal from "../../modals/save-modal";
import TutorialModal from "../../modals/tutorial-modal";
import WelcomeModal from "../../modals/welcome-modal";

export default function PrepareModalWidget(props) {
  const {lifeEventSelected} = props;

  const handleSubmitLifePlan = (openSaveModal) => {
    props.handleSubmitLifePlan && props.handleSubmitLifePlan(openSaveModal)
  }

  const handleNextModal = (step) => {
    props.handleNextModal && props.handleNextModal(step)
  }

  const handlePrevModal = (step) => {
    props.handlePrevModal && props.handlePrevModal(step)
  }

  return (
    <>
      <WelcomeModal handleNextModal={handleNextModal} handlePrevModal={handlePrevModal} />
      <FamilyModal handleNextModal={handleNextModal} handlePrevModal={handlePrevModal} handleSubmitLifePlan={handleSubmitLifePlan} step={props.step} />
      <InputSpouseModal handleNextModal={handleNextModal} handlePrevModal={handlePrevModal} handleSubmitLifePlan={handleSubmitLifePlan} step={props.step} />
      <InputSpendingModal handleNextModal={handleNextModal} handlePrevModal={handlePrevModal} handleSubmitLifePlan={handleSubmitLifePlan} step={props.step} />
      <TutorialModal handleNextModal={handleNextModal} handlePrevModal={handlePrevModal} />
      {lifeEventSelected && lifeEventSelected.event_type === 1 && <EventPurchaseRealestateModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 2 && <SaleModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 2 && <Sale2Modal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 3 && <EventPurchaseHomeModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 4 && <EventSaleHomeModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 6 && <EventTravelModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 7 && <EventCarModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 11 && <EventHobbyModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 5 && <EventEatoutModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && <EventPetModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 9 && <EventFamilyModal lifeEvent={lifeEventSelected} />}
      {lifeEventSelected && lifeEventSelected.event_type === 10 && <EventSecondCarrierModal lifeEvent={lifeEventSelected} />}
      <GraphModal />
      <SaveModal />
      <CanNotAddEventModal />
      <EventGhostList />
      <ConfirmDeleteModal />
    </>
  )
}
