import React from 'react';
import { useTranslation } from 'react-i18next';

export function FlowCurrent(props) {
  const { t } = useTranslation('flowCurrent');
  const flows = t('flows', { returnObjects: true });

  return <ul className="flowCurrent">
    {flows.map((flow) => <li key={flow} className={flow === props.flow ? 'current' : ''}>{flow}</li> )}
  </ul>;
}