import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../common/Loading';
import CustomStepContainer from './CustomStepContainer';
import Bubble from './Bubble';
import Image from './Image';
import ImageContainer from './ImageContainer';
import { ChatBotImages } from '../../../assets/images';
const { userBot } = ChatBotImages;

class CustomStep extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { speak, step, previousValue, triggerNextStep } = this.props;
    const { delay, waitAction } = step;

    setTimeout(() => {
      this.setState({ loading: false }, () => {
        if (!waitAction && !step.rendered) {
          triggerNextStep();
        }
        speak(step, previousValue);
      });
    }, delay);
  }

  renderComponent = () => {
    const { step, steps, previousStep, triggerNextStep } = this.props;
    const { component } = step;

    return React.cloneElement(component, {
      step,
      steps,
      previousStep,
      triggerNextStep
    });
  };

  render() {
    const {
      step
    } = this.props;
    const { loading } = this.state;
    const { user, botName } = step;

    const imageAltText = user ? "Your avatar" : `${botName}'s avatar`;
    if (step.component.props.type == "submitBtn") {
      return (
        <CustomStepContainer className="viewResultBtn">
          {loading ? <Loading /> : this.renderComponent()}
        </CustomStepContainer>
      )
    } else {
      return (
        <CustomStepContainer className="customMsg">
          <ImageContainer className="avatar" user={user}>
            <Image
              className="wid100p"
              user={user}
              src={userBot}
              alt={imageAltText}
            />
          </ImageContainer>
          <Bubble
            className={step.component.props.class || "content"}
            user={user}
          >
            {loading ? <Loading /> : this.renderComponent()}
          </Bubble>
        </CustomStepContainer>
      );
    }
  }
}

CustomStep.propTypes = {
  previousStep: PropTypes.objectOf(PropTypes.any),
  previousValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  speak: PropTypes.func,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  steps: PropTypes.objectOf(PropTypes.any),
  triggerNextStep: PropTypes.func.isRequired
};
CustomStep.defaultProps = {
  previousStep: {},
  previousValue: '',
  speak: () => {},
  steps: {}
};

export default CustomStep;
