import React from 'react';
import { useTranslation } from 'react-i18next';
import icoHumanL from 'images/mascot/mascot3.png';
import { useDispatch } from 'react-redux';
import { closeModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';

function GraphModal(props) {
	const { t } = useTranslation('graphModal');
	const tGlobal = useTranslation('global').t;
	const dispatch = useDispatch();

	const handleSubmit = () => {
		dispatch(saveSelectedLifeEvent());
	}

  const handleClose = () => {
    dispatch(closeModal());
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_GRAPH_MODAL}>
    <div className="popupBox">
		<div className="popupInner">
			<div className="lifeplanPopup">
				<h2>{t('label')}</h2>
				<div className="popupContents">
					<div className="popupContentsCover figBox">

						<figure><img src={icoHumanL} className="step_image" /></figure>
						<div className="popupContentsRight">
							<div className="popupContentsTxt">
								<p>{t('popupContentsTxt')}</p>
								<p>{t('popupContentsTxt2')}</p>
								<br />
								<p>{t('popupContentsTxt3')}</p>
								<p>{t('popupContentsTxt4')}</p>
								<ul className="noteList font14">
									<li>{t('noteList')}</li>
								</ul>
								<p className="marT32">{t('noteList2')}<a href="/contact/contact_generals/new" target="_blank" className="decoUdrLine colBlu">{t('contactUs')}</a></p>
							</div>
							<div className="popupBtnBox">
								<ul className="btnList">
									<li><button href="#" className="btnSty-back mar0Auto" onClick={handleClose}>{tGlobal('close')}</button></li>
								</ul>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
  </LifePlanModal>;
}

export default React.memo(GraphModal);
