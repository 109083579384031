import React from 'react';
import icoHumanL from 'images/mascot/mascot6.png';


function CloseModal(props) {
  const handleClose = () => {
    $('.popupBox').addClass('hide')
  }

  const handleBackToTop = () => {
    history.back()
  }

  return <>
    <div className="popupBox hide">
      <div className="popupInner alsC">
        <div className="favoritePopup favoritePopupCustom justify-content-evenly">
          <div className="wid150"><img src={icoHumanL} className="" /></div>
          <div className="dF aisC flDCl" >
            <p className="alL">{props.msg}</p>
            <div className="dF">
              <button type="button" className="btnSty-blue" onClick={handleBackToTop}>はい</button>
              <button type="button" className="btnSty-gray" onClick={handleClose}>いいえ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default React.memo(CloseModal);
