import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import LifePlanModal from '../../shared/components/modal';
import { Form, Field, Formik } from 'formik';
import { generateYearsByMemberAge, ModalName } from '../../shared/constants/global';
import { closeModal, openSubModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import { LifePlansImages } from '../../assets/images';
import { YupSchema } from '../../shared/constants/validators';
import EducationField from '../../shared/components/education-field';
import SelectFieldSecond from '../../shared/components/select-field-second';
import InputText from '../../shared/components/input-field';
import CommaInput from '../../shared/components/comma-input-field';
import { cloneDeep } from "lodash";

const {imgFamily1, imgFamily2, imgFamily3, imgFamily4, imgFamily5} = LifePlansImages;

export default function EventFamilyModal(props) {
  const { t } = useTranslation('eventFamilyModal');
  const tGlobal = useTranslation('global').t;
  const dispatch = useDispatch();
  const { lifeEvent } = props;
  const uuid = useSelector(state => state.lifeEventSelected.uuid);
  const storeLifeEvents = useSelector(state => state.life_plan.life_event);

  const formState = useRef();

  const [checkShow, setCheckShow] = useState(0);
  const [selectedValue, setSelectedValue] = useState('');
  const [initialValues, setInitialValues] = useState({ ...props.lifeEvent, other: { ...props.lifeEvent.other } });

  const EDUCATIONS = ['elementary_school', 'middle_school', 'high_school', 'university'];

  const validateSchema = useMemo(() => {
    return new YupSchema(t).eventFamilyModal;
  }, [])

  useEffect(() => {
    const values = { ...props.lifeEvent, other: { ...props.lifeEvent.other, time: props.lifeEvent.year } }
    setInitialValues(values)
    setCheckShow(values.other.detail)
    if (uuid) {
      let cycleYear = storeLifeEvents.find((event) => event.uuid === uuid).year
      formState.current.setFieldValue("year", cycleYear);
    }
  }, [props.lifeEvent]);

  const handleClose = () => {
    dispatch(closeModal());
  }

  const getDetailName = (detail, data) => {
    switch (detail) {
      case 1:
        return "結婚"
      case 2:
        return "子供の誕生"
      case 3:
        return "子供の結婚式"
      case 4:
        return "介護施設入居費"
      case 5:
        return data.other?.custom || ""
      default:
        return ""
    }
  }

  const prepareData = (values) => {
    const tempData = cloneDeep(values);
    const params = {
      ...tempData,
      other: {
        ...tempData.other,
        detail_name: getDetailName(+tempData.other?.detail, tempData)
      }
    }
    return params;
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      const data = prepareData(values);
      dispatch(saveSelectedLifeEvent(data));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    return `${subYear(year)}歳（${year}年）`
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age;
  }

  const onSelect = (data) => {
    setSelectedValue(+data)
    formState.current.setFieldValue("other.time", +data);
    formState.current.setFieldValue("year", +data);
    if (+formState.current.values.end_year < +data) {
      formState.current.setFieldValue("end_year", +data);
    }
  }

  const dataEndYear = useMemo(() => {
    if (!selectedValue) {
      return Object.keys(yearMapping).filter(ele => {
        if (uuid) {
          let cycleYear = storeLifeEvents.find((event) => event.uuid === uuid).year
          return +ele >= cycleYear
        } else {
          return +ele >= initialValues.year
        }
      });
    } else {
      return Object.keys(yearMapping).filter(ele => {
        return +ele >= +selectedValue;
      });
    }
  }, [selectedValue, initialValues.year])

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_FAMILY}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>{t('label')}</h2>
                  <div className="popupContents">
                    <div className="popupContentsCover">
                      <div className="whiteBox radioImgBox pad24 padBottom8 fullWidth">
                        <div className="radioImgBtnList rowList activeBtnBox">
                          <div className="familyLeftCol">
                            <li className={`fullWidth p-relative ${values.other.detail == 1 ? "checked" : ""}`}>
                              <label className="radioBtn">
                                <Field onClick={() => setCheckShow(1)} type="radio" value="1" name="other.detail" className="toggle_hide" />
                                  <img src={imgFamily1} /><span>{t('options.0')}{t('cost.0')}</span>
                              </label>
                            </li>
                            <li className={`fullWidth p-relative ${values.other.detail == 3 ? "checked" : ""}`}>
                              <label className="radioBtn">
                                <Field onClick={() => setCheckShow(3)} type="radio" value="3" name="other.detail" className="toggle_hide" />
                                  <img src={imgFamily3} /><span>{t('options.2')}{t('cost.2')}</span>
                              </label>
                            </li>
                            <li className={`fullWidth p-relative ${values.other.detail == 4 ? "checked" : ""}`}>
                              <label className="radioBtn">
                                <Field onClick={() => setCheckShow(4)} type="radio" value="4" name="other.detail" className="toggle_hide" />
                                  <img src={imgFamily4} /><span>{t('options.3')}{t('cost.3')}</span>
                              </label>
                            </li>
                          </div>
                          <div className="familyRightCol">
                            <li className={`fullWidth p-relative ${values.other.detail == 2 ? "checked" : ""}`}>
                              <label className="radioBtn">
                                <Field onClick={() => setCheckShow(2)} type="radio" value="2" name="other.detail" className="toggle_hide" />
                                  <img src={imgFamily2} /><span>{t('options.1')}{t('cost.1')}</span>
                              </label>
                                <dl className="familyTime formTtlSty pad24">
                                  <dt>{tGlobal('time')}</dt>
                                  <dd>
                                    <div className="dF aisC spFlexWrap">
                                      <div className="dF aisC">
                                        <Field component={SelectFieldSecond} width="wid200" name="other.time" customChange={onSelect} options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              <div className="AccountCooperationBox dF spFlexWrap pad16">
                                {EDUCATIONS.map((edu, eduIndex) => (
                                  <Field
                                    component={EducationField}
                                    key={eduIndex}
                                    labelProp={edu}
                                    index={eduIndex}
                                    name={`other.${edu}`}
                                  />
                                ))}
                              </div>
                            </li>
                          </div>
                          <div className="familyLeftCol">
                            <li className={`fullWidth p-relative ${values.other.detail == 5 ? "checked" : ""}`}>
                              <label className="radioBtn">
                                <Field onClick={() => setCheckShow(5)} type="radio" value="5" name="other.detail" className="toggle_hide" />
                                  <img src={imgFamily5} /><span>{t('options.4')}</span>
                              </label>
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                    <dl className="formSty lifeplanImgBtnBox lifeplanFamily marB40">
                      <dt id="activeBtn-item2-ttl" className={`activeBtnTtl ${!(checkShow == 5) ? 'hiddenTtl' : ''}`}>{t('custom')}</dt>
                      <dd id="activeBtn-item2-item" className={`activeBtnItem ${!(checkShow == 5) ? 'hiddenItem' : ''}`}>
                        <div className="wid100p">
                          <div className="wid400 marB1em">
                            <Field className="toggle-item" type="text" component={InputText} name="other.custom" placeholder={t('customPlaceholder')} disabled={!(checkShow == 5)} />
                          </div>
                          <dl className="formTtlSty nowrap">
                            <dt>{t('initialCost')}</dt>
                            <dd><Field className="wid120" placeholder="3" type="text" component={CommaInput} name="other.cost_at_first" disabled={!(checkShow == 5)} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} /></dd>
                            <dt>{t('annualCost')}</dt>
                            <dd><Field className="wid120" placeholder="10" type="text" component={CommaInput} name="other.price" disabled={!(checkShow == 5)} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} /></dd>
                          </dl>
                        </div>
                      </dd>
                      <dt className={`${checkShow == 2 ? 'hiddenItem' : ''}`}>{t('startTime')}</dt>
                      <dd id="activeBtn-item1-item" className="activeBtnItem">
                        <dl className="wid100p">
                          <dd>
                            <div className="dF aisC spFlexWrap">
                              <div className={`dF aisC ${checkShow == 2 ? 'hiddenItem' : ''}`}>
                                <Field component={SelectFieldSecond} width="wid200" name="year" customChange={onSelect} options={Object.keys(yearMapping)} display_options={renderYearFromNow} disabled={checkShow == 2} />
                                <span>{tGlobal('from')}</span>
                              </div>
                              <div className={`dF aisC ${!(checkShow == 4 || checkShow == 5) ? 'hiddenItem' : ''}`}>
                                <Field component={SelectFieldSecond} width="wid200" name="end_year" options={dataEndYear} display_options={renderYearFromNow} disabled={!(checkShow == 4 || checkShow == 5)} />
                                <span>{tGlobal('to')}</span>
                              </div>
                            </div>
                          </dd>
                        </dl>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList col2">
                        <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                        <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                        { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
