import React from 'react';
import useThrottle from '../../shared/hooks/throttle';

export default function NextButton(props) {
  const handleNextPage = () => {
    const pagination = document.querySelector(".timelineVertical .timelineBgPagination")
    const paginationLeft = pagination.scrollLeft
    const pages = document.querySelectorAll(".timelineVertical .timelineBgPaginationItem")
    const currentPage = [...pages].find(el => paginationLeft >= el.offsetLeft - 10 && paginationLeft < el.offsetLeft + el.offsetWidth - 10 )
    if (currentPage.nextSibling) {
      if (currentPage.nextSibling.nextSibling) {
        pagination.scrollTo({ left: currentPage.nextSibling.nextSibling.offsetLeft, behavior: 'smooth'})
      } else {
        pagination.scrollTo({ left: currentPage.nextSibling.offsetLeft, behavior: 'smooth'})
      }
    }
  }

  const invokeThrottleNextButton = useThrottle(() => {
    handleNextPage();
  }, 500);

  const invokeHandleDrag = useThrottle(() => {
    handleNextPage();
  }, 700);

  return (
    <React.Fragment>
      <div
        className={["arrowBox", "next", "pc", props.disabled ? "disabled" : ""].filter(e => !!e).join(' ')}
        onDragOver={invokeHandleDrag}
      >
        <button className="btn-next arrowBtn" onClick={invokeThrottleNextButton} disabled={props.disabled}><span className="arrow"></span></button>
        <p>次の20年</p>
      </div>
    </React.Fragment>
  )
}
