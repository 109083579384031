import IncomeChart from "../lps-income-chart"
import React, { useEffect } from 'react';

export default function ResultGraphIncomeWidget(props) {
    const { loggedIn, results } = props;

    const incomeInformation = () => {
        if(!results.length) return null;
        return (
            <div className="resultGraph income">
            <div className={loggedIn ? 'graph' : ' graph blurCont'}>
                <IncomeChart analysisResult={results} />
            </div>
         </div>
        )
    }

    return (
        incomeInformation()
    )
}
