import React from "react";
import { presetToOptions } from "webpack/lib/Stats";

export default function SubmitBtn(props) {
  const handleSubmit = () => {
    props.func(props.formType)
  }

  return (
    <>
      <button type="submit" onClick={() => handleSubmit()}>
        {props.text}
      </button>
    </>
  )
}
