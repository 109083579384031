import React from "react";
import { LifePlansImages } from "../../assets/images";
import iconFormGreen from "../../assets/icons/ico-formGreen.svg";
const { commentCustomer, commentStaff, arrowBGrad } = LifePlansImages;

function HomeSaleCaseTabWidget() {
  return (
    <>
      <div className="commentLine">
        <div className="commentDet staff">
          <figure>
            <img src={commentCustomer} alt="" />
            <figcaption>お客様</figcaption>
          </figure>
          <div className="comment">
            <p>サラリーマンですが、ローンを組めるうちに老後資金の対策をしたい気持ちもありますが、投資物件を本当に買ってよいのか不安もあります</p>
          </div>
        </div>
        <div className="commentDet staff">
          <figure>
            <img src={commentStaff} alt="" />
            <figcaption>
              営業スタッフ
            </figcaption>
          </figure>
          <div className="comment">
            <p>これまで数多くのお客様の資産形成のお手伝いをさせて
              いただいており、ご資金面でも個別コンサルティングも
              無料で行っております。是非、お気軽にご相談くださいませ。
              無理のない投資計画をご提案いたします。</p>
          </div>
        </div>
      </div>
      <div className="arrow">
        <img src={arrowBGrad} alt="" />
        <p>
          無料で相談。
          <br className="sp" />
          安心のサポートをいたします。
        </p>
      </div>
      <div className="contactGreenSty">
        <a href="/contact/contact_investments/new">
          <h3>
            無料相談は<span className="inlBlock fontWB">かんたん1分で完了</span>
          </h3>
          <span className="whiteLineBtn">
            <img src={iconFormGreen} alt="" width="32" height="26" />
            お問い合わせはこちら
          </span>
          <span className="ttlSty">CONTACT</span>
        </a>
      </div>
    </>
  );
}

export default React.memo(HomeSaleCaseTabWidget);
