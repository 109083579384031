import React from 'react';
import Event from "./Event";

export default function EventList(props) {
  return (
    <React.Fragment>
      <div className="timelineEventList">
        { props.events.map(event => <Event event={event} key={event.uuid} />)  }
      </div>
    </React.Fragment>
  )
}
