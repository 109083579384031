import styled from 'styled-components';

const FloatButton = styled.a`
`;

FloatButton.defaultProps = {
  theme: {
    headerBgColor: '#6e48aa',
    headerFontColor: '#fff'
  }
};

export default FloatButton;
