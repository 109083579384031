
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function LifePlanSubModal(props) {
  const subModalOpenName = useSelector(state => state.subModalOpenName);

  return <div style={subModalOpenName === props.name ? {} : { visibility: 'hidden', display: 'none' }}>
    {props.children}
  </div>;
}
