export const LifePlansIcons = {
    iconAiBtn: require("../icons/ico-ai_btn.png"),
    iconAI: require("../icons/ico-ai.png"),
    iconApart: require("../icons/ico-apart.svg"),
    iconArrowB: require("../icons/ico-arrow_b.png"),
    iconArrowR: require("../icons/ico-arrow_r.svg"),
    iconBlank: require("../icons/ico-blank.svg"),
    iconBldgApartBig: require("../icons/ico-bldg-apart-big.png"),
    iconBldgApartMiddle: require("../icons/ico-bldg-apart-middle.png"),
    iconBldgApartSmall: require("../icons/ico-bldg-apart-small.png"),
    iconBldgBig: require("../icons/ico-bldg-big.png"),
    iconBldgBig01: require("../icons/ico-bldg-big01.png"),
    iconBldgMiddle: require("../icons/ico-bldg-middle.png"),
    iconBldgSmall: require("../icons/ico-bldg-small.png"),
    iconBldg: require("../icons/ico-bldg.png"),
    iconBuy: require("../icons/ico-buy.png"),
    iconCaution: require("../icons/ico-caution.png"),
    iconCircle: require("../icons/ico-circle.svg"),
    iconEntry: require("../icons/ico-entry.svg"),
    iconForm: require("../icons/ico-form.svg"),
    iconFormGreen: require("../icons/ico-formGreen.svg"),
    iconFromL: require("../icons/ico-formL.png"),
    iconGraph: require("../icons/ico-graph.png"),
    iconHuman: require("../icons/ico-human.png"),
    iconHumanL: require("../icons/ico-humanL.png"),
    iconLogin: require("../icons/ico-login.svg"),
    iconLpsBtn: require("../icons/ico-lps_btn.png"),
    iconMailGreenL: require("../icons/ico-mailGreenL.png"),
    iconMansion: require("../icons/ico-mansion.svg"),
    iconSave: require("../icons/ico-save.svg"),
    iconSell: require("../icons/ico-sell.png"),
    iconStarL: require("../icons/ico-starL.png"),
    iconSter: require("../icons/ico-ster.png"),
    iconTelGreen: require("../icons/ico-tel-green.svg"),
    iconTel: require("../icons/ico-tel.svg"),
    iconWindow: require("../icons/ico-window.svg"),
    iconYen: require("../icons/ico-yen.svg"),
    iconLoop: require("../icons/ico-loop.svg")
};