import React, { useRef } from "react";
import { Form, Field, Formik } from 'formik';
import RadioField from './CustomField/RadioField';

export default function OptionStep(props) {
  const formState = useRef();
  const initialValues = props.initialValues

  const handleTriggerNextStep = async(values) => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const value = values[props.name]
    if (props.formType == "contact") {
      const contact = JSON.parse(window.lsSession.getItem('chatbot-contact'))
      const newObj = {...contact, ...values}
      const contactStr = JSON.stringify(newObj)
      window.lsSession.setItem('chatbot-contact', contactStr)
    } else if (props.formType == 'aiSale') {
      const aiSale = JSON.parse(window.lsSession.getItem(`chatbot-${value == 1 ? "category" : "building"}`))
      const newObj = {...aiSale, ...values}
      const aiSaleStr = JSON.stringify(newObj)
      window.lsSession.setItem(`chatbot-${value == 1 ? "category" : "building"}`, aiSaleStr)
    }

    props.triggerNextStep({ trigger: value == '' ? props.options[0].trigger : props.options.find(e => e.value == value).trigger,
                            value: values,
                            replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="titleOption">{props.title}</div>
            <Field name={props.name} options={props.options.map(opt => opt.value)} labels={props.options.map(opt => opt.label)} component={RadioField} />
            <button type="submit" className="btnSubmit" >
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
