import React from "react";
import { useTranslation } from 'react-i18next';

export default function Summary(props) {
  const { t } = useTranslation('summary');
  const storage = JSON.parse(window.lsSession.getItem('chatbot-contact'))

  const {
    property_category,
    rent_status,
    rental_status_operation,
    rental_status_remarks,
    rental_status_total,
    reason_for_sale,
    other_sale_reason,
    prefecture,
    city,
    region,
    after_town,
    individual_or_corporate,
    company,
    last_name,
    first_name,
    last_name_kana,
    first_name_kana,
    email,
    phone_number,
    customer_zip_code,
    customer_prefecture,
    customer_city
  } = storage

  return (
    <>
      <div>
        <label className="titleAttr">ご用件</label>
        <div>AI査定</div>
      </div>
      <div>
        <label className="titleAttr">物件タイプ</label>
        <div>{t(`property_category.${property_category - 1}`)}</div>
      </div>
      {/* { !(property_category == 5) && (<div>
        <label className="titleAttr">賃貸状況</label>
        { [1, 4, 6].includes(property_category) && (<div>{rent_status ? t(`rent_status.${rent_status}`) : "-"}</div>) }
        { [2, 3].includes(property_category) && (
          <>
            <div className="dF">
              <div>{rental_status_total || "-"}室中</div>
              <div>{rental_status_operation || "-"}室稼働中</div>
            </div>
            <div>{rental_status_remarks || "-"}</div>
          </>
        ) }
      </div>) } */}
      <div>
        <label className="titleAttr">売却理由</label>
        { reason_for_sale.length != 0 &&
        (<div>
          <div className="whiteSpace">{ reason_for_sale.filter(r => r != 6).map(r => t(`reason_for_sale.${r - 1}`)).join('\n') }</div>
          { reason_for_sale.includes('6') && `その他：${other_sale_reason}`}
        </div>) }
        { reason_for_sale.length == 0  &&
        (<div>-</div>) }
      </div>
      <div>
        <div className="dF">
          <div>
            <label className="titleAttr">都道府県</label>
            <div>{prefecture}</div>
          </div>
          <div>
            <label className="titleAttr">市区町村</label>
            <div>{city}</div>
          </div>
        </div>
      </div>
      <div>
        <label className="titleAttr">住所</label>
        <div>{region}</div>
      </div>
      <div>
        <label className="titleAttr">丁目より後の住所</label>
        <div>{after_town}</div>
      </div>
      <div>
        <label className="titleAttr">個人 / 法人</label>
        <div>{t(`individual_or_corporate.${individual_or_corporate - 1}`)}</div>
      </div>
      { individual_or_corporate == 2 && (<div>
        <label className="titleAttr">会社名</label>
        <div>{company}</div>
      </div>) }
      <div>
        <label className="titleAttr">名前</label>
        <div className="dF">
          <div>{last_name}</div>
          <div>{first_name}</div>
        </div>
      </div>
      <div>
        <label className="titleAttr">ふりがな</label>
        <div className="dF">
          <div>{last_name_kana}</div>
          <div>{first_name_kana}</div>
        </div>
      </div>
      <div>
        <label className="titleAttr">メールアドレス</label>
        <div>{email}</div>
      </div>
      <div>
        <label className="titleAttr">電話番号</label>
        <div>{phone_number}</div>
      </div>
      {/* <div>
        <label className="titleAttr">ご住所</label>
        { (customer_zip_code || customer_prefecture || customer_city) &&
        (<>
          <div>〒{customer_zip_code}</div>
          <div className="dF">
            <div>{customer_prefecture}</div>
            <div>{customer_city}</div>
          </div>
        </>) }
        { (!customer_zip_code && !customer_prefecture && !customer_city) &&
        (<div>-</div>) }
      </div> */}
    </>
  )
}
