import React from 'react';
import { getLifeEventMapping } from '../../shared/constants/global';
import EventGhost from "./EventGhost";

const eventList = Object.values(getLifeEventMapping()).map((v) => v.getDefaultValues())

export default function EventGhostList(props) {
  return (
    <React.Fragment>
      <div className="timelineEventGhostList">
        {eventList.map((event, index) => <EventGhost event={event} key={index} />)}
      </div>
    </React.Fragment>
  )
}
