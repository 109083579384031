import React from 'react';
import { useSelector } from 'react-redux';

export default function LifePlanModal(props) {
  const modalOpenName = useSelector(state => state.modalOpenName);

  return <div style={modalOpenName === props.name ? {} : { visibility: 'hidden', display: 'none' }}>
    {props.children}
  </div>;
}
