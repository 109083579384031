import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import iconHuman from 'images/mascot/mascot7.png';
import { getEventMapping, toInt } from '../../shared/constants/global';

function LpsCaculationResultsWidget(props) {
    const {loggedIn, lifePlan, totalIncoming, incomeResult, totalSpending, spendingResult, lifePlanSimulationResult} = props
    const [diagnosisesContent, setDiagnosisContent] = useState([])
    const fullName = gon.full_name
    const tax = 0.8
    const currentYear = new Date().getFullYear()

    const cycleEvent = useSelector(state => state.cycleEvent)

    const cycleEventGetAmount = (event) => {
        const eventMapping = getEventMapping(event)
        if (event.isClone && event.event_type != 7) {
          return eventMapping.getCycleAmount(event)
        } else {
          return (+eventMapping.getAmount(event) + +eventMapping.getCycleAmount(event)) * (event.other?.number_of_people || 1)
        }
    }

    const findEventCurrentYear = (event_type) => {
        return lifePlan.life_event.find((e) => e.event_type === event_type && e.year == currentYear)
    }

    const findEvent = (event_type) => {
        return lifePlan.life_event.find((e) => e.event_type === event_type)
    }

    const findEventType = (eventType, eventDetail) => {
        return lifePlan.life_event.find((e) => e.event_type === eventType && e.other.detail == eventDetail)
    }

    const calculateTotalSpendingForEvent = (eventType) => {
        const listAllEvent = lifePlan.life_event.concat(cycleEvent)
        const filterEvents = listAllEvent.filter((e) => e.event_type === eventType && e.year == currentYear)
        let totalSpending = 0
        filterEvents.forEach(function(e) {
            totalSpending += cycleEventGetAmount(e)
        })

        return totalSpending
    }

    const calculatePercentWithIncomeInMonth = (spendingForEventOfMonth, incomeInMonth) => {
        if(incomeInMonth === 0 && spendingForEventOfMonth === 0) {
            return 0
        }else if(spendingForEventOfMonth !== 0 && incomeInMonth === 0) {
            return 100
        }else {
            return spendingForEventOfMonth/incomeInMonth * 100
        }
    }

    useEffect(() => {
        const incomeInMonth = ((toInt(lifePlan.person_income) + toInt(lifePlan.partner_income))*tax + toInt(lifePlan.other_income))/12
        const buyPropertyEvent = findEvent(1)
        const sellPropertyEvent = findEvent(2)
        const buyHouseEvent = findEvent(3)
        const sellHouseEvent = findEvent(4)
        const travelEvent = findEvent(6)
        const hobbyEvent = findEvent(11)
        const hobbyEventCurrentYear = findEventCurrentYear(11)
        const eatingEvent = findEvent(5)
        const golfHobby = findEventType(11, 4)
        const fishingHobby = findEventType(11, 3)
        const spaceTravel = findEventType(6, 11)
        const horseRacingHobby = findEventType(11, 9)
        const totalSpendingForBuyCar = spendingResult.length === 0 ? 0 : spendingResult.find((e) => e.category === 12).value
        const spendingForHobbiesOfMonth = calculateTotalSpendingForEvent(11)/12
        const percentSpendingForTravelAndHobby =  calculatePercentWithIncomeInMonth(spendingForHobbiesOfMonth, incomeInMonth)
        const spendingForEating = calculateTotalSpendingForEvent(5)/12
        const percentSpendingForEating = spendingForEating/incomeInMonth * 100
        const buyCarEvent = findEvent(7)
        const buyLuxuryForeignCarEvent = findEventType(7, 6)
        const buyLuxurySuperCarEvent = findEventType(7, 7)
        const hasChild = lifePlan.child.length > 0
        const savingIncludeRealEstate = lifePlanSimulationResult.find(e => e.savings_include_real_estate < 0)
        const ageCashOut = savingIncludeRealEstate ? savingIncludeRealEstate.age_of_person : 0
        const housingMonthlyRepayment = toInt(lifePlan.housing_monthly_repayment)
        const percentRentOfYear = calculatePercentWithIncomeInMonth(housingMonthlyRepayment, incomeInMonth)
        const hasHouse = lifePlan.residence_type === 1
        const rentHouse = lifePlan.residence_type === 2

        const diagnosisesContent = []

        const differenceIncomeAndSpending = totalIncoming - totalSpending

        // 趣味について
        if((percentSpendingForTravelAndHobby > 20) ||
            (percentSpendingForTravelAndHobby <= 20 && travelEvent && differenceIncomeAndSpending >= 0) ||
            (percentSpendingForTravelAndHobby <= 20 && hobbyEventCurrentYear && !golfHobby && differenceIncomeAndSpending >= 0) ||
            (percentSpendingForTravelAndHobby <= 20 && hobbyEventCurrentYear && !fishingHobby && differenceIncomeAndSpending >= 0) ||
            (!travelEvent && !hobbyEvent && differenceIncomeAndSpending >= 0) ||
            (spaceTravel && differenceIncomeAndSpending >= 0) ||
            (horseRacingHobby)) {
            diagnosisesContent.push(`<p class="diagnosis-title-first">＜趣味について＞</p>`)
        }
        if(percentSpendingForTravelAndHobby > 20) {
            diagnosisesContent.push(`${fullName}さまはご趣味が充実されていてステキですね！<br />没頭できるご趣味があるのはすばらしいです！`)
        }
        if((percentSpendingForTravelAndHobby <= 20 && travelEvent && differenceIncomeAndSpending >= 0) ||
            (percentSpendingForTravelAndHobby <= 20 && hobbyEventCurrentYear && !golfHobby && differenceIncomeAndSpending >= 0) ||
            (percentSpendingForTravelAndHobby <= 20 && hobbyEventCurrentYear && !fishingHobby && differenceIncomeAndSpending >= 0) ||
            (!travelEvent && !hobbyEvent && differenceIncomeAndSpending >= 0)) {
            diagnosisesContent.push(`${fullName}さまの所得でしたらご趣味にもう少しお金をかけてみるのはどうでしょう。<br />趣味にお金をかけられる人生ほど素晴らしいものはありません。`)
        }
        if(spaceTravel && differenceIncomeAndSpending >= 0) {
            diagnosisesContent.push(`宇宙旅行はロマンがありますね。<br />  将来、1300万円ありましたら行かれるようになると言われております。<br />  投資不動産で資産を増やされて、${fullName}さまが宇宙に行かれるのはいかがでしょう。`)
        }
        if(horseRacingHobby) {
            diagnosisesContent.push(`競馬の馬主さま、ステキです！個人で馬主さまになられるには条件がありまして、年間の所得が2年続けて1700万円以上であることと、7500万円以上の資産がご必要になるそうです。${fullName}さまは大丈夫でしょうか？`)
        }

        // ライフイベントについて
        if((percentSpendingForEating > 20) ||
            (eatingEvent && percentSpendingForEating <= 20 && differenceIncomeAndSpending >= 0) ||
            (!eatingEvent && differenceIncomeAndSpending >= 0) ||
            (totalSpendingForBuyCar > 40000000) ||
            (!buyCarEvent && differenceIncomeAndSpending >= 0) ||
            (buyLuxuryForeignCarEvent || buyLuxurySuperCarEvent)) {
            diagnosisesContent.push(`<p class="diagnosis-title">＜ライフイベントについて＞</p>`)
        }
        if(percentSpendingForEating > 20) {
            diagnosisesContent.push(`外食をたくさんしてらして、羨ましいです。<br />食生活の充実は豊かな人生の基本になります！<br />・・・こう見えて私の好物はお豆腐ではございません！`)
        }
        if(eatingEvent && percentSpendingForEating <= 20 && differenceIncomeAndSpending >= 0) {
            diagnosisesContent.push(`外食が少ないですね。もう少しゼイタクされてもよろしいのではないでしょうか。`)
        }
        if(!eatingEvent && differenceIncomeAndSpending >= 0) {
            diagnosisesContent.push(`最近だと1ヶ月に平均3回～4回程度外食されている方が多いそうです。<br />家での食事もよろしいですが、たまには気分転換に外食されるのはいかがでしょう。`)
        }
        if(totalSpendingForBuyCar > 40000000) {
            diagnosisesContent.push(`お車はよろしいですね！新車でしたらだいたい7～9年に1回買い替えをされる方が多いようですが、いかがでしょう。`)
        }
        if(!buyCarEvent && differenceIncomeAndSpending >= 0) {
            diagnosisesContent.push(`そうえいば一般的なお車は年間の支出は40万円のようです。お車はストレス解消にもなりますし、マイカーはいかがでしょう`)
        }
        if(buyLuxuryForeignCarEvent || buyLuxurySuperCarEvent) {
            diagnosisesContent.push(`高級車は夢があってステキです！長旅するときは高級車でしたら座りごごちがよろしいので最高のお時間を過ごせますね。`)
        }

        // 住宅の購入・売却について
        if((buyHouseEvent && percentRentOfYear < 25) ||
            (buyHouseEvent && percentRentOfYear >= 25) ||
            (sellHouseEvent)) {
            diagnosisesContent.push(`<p class="diagnosis-title">＜住宅の購入・売却について＞</p>`)
        }
        if(buyHouseEvent && percentRentOfYear < 25) {
            diagnosisesContent.push(`一般的に住宅ローンの返済額は年収の25%未満が安全と言われております。ここはテストに出るので覚えておいてくださいませ。<br /> ${fullName}さまの条件は返済額が25%未満に収まっておられるので、安全な返済計画になっていらっしゃいますね。`)
        }
        if(buyHouseEvent && percentRentOfYear >= 25) {
            diagnosisesContent.push(`一般的に住宅ローンの返済額は年収の25%未満が安全と言われております。ふむふむ。${fullName}さまのご条件は返済額が25%以上ですので、無理のない返済計画を再度検討されるのがよろしいかと思いますが、いかがでしょう。`)
        }
        if(sellHouseEvent) {
            diagnosisesContent.push(`${fullName}さまが今後不動産のご売却を考えていらっしゃるなら、小田急不動産は売却査定が可能になりますので、ご相談してみてはいかがでしょう。`)
        }

        // 総合収支について
        if((differenceIncomeAndSpending > 0 && hasChild && (buyPropertyEvent || sellPropertyEvent)) ||
        (differenceIncomeAndSpending < 0 && hasChild && (buyPropertyEvent || sellPropertyEvent)) ||
        (differenceIncomeAndSpending > 0 && !hasChild && (buyPropertyEvent || sellPropertyEvent)) ||
        (differenceIncomeAndSpending < 0 && !hasChild && (buyPropertyEvent || sellPropertyEvent)) ||
        (differenceIncomeAndSpending > 0 && hasChild && !buyPropertyEvent && !sellPropertyEvent) ||
        (differenceIncomeAndSpending < 0 && hasChild && !buyPropertyEvent && !sellPropertyEvent) ||
        (differenceIncomeAndSpending > 0 && !hasChild && !buyPropertyEvent && !sellPropertyEvent) ||
        (differenceIncomeAndSpending < 0 && !hasChild && !buyPropertyEvent && !sellPropertyEvent && hasHouse) ||
        (differenceIncomeAndSpending < 0 && !hasChild && !buyPropertyEvent && !sellPropertyEvent && rentHouse)) {
            diagnosisesContent.push(`<p class="diagnosis-title">＜総合収支について＞</p>`)
        }
        if(differenceIncomeAndSpending > 0 && hasChild && (buyPropertyEvent || sellPropertyEvent)) {
            diagnosisesContent.push(`すばらしいです！！<br /> 最終的な収支にも問題がなく、豊かな老後を送れる人生設計となっております。<br /> ガンジーも「明日死ぬかのように生きよ。永遠に生きるかのように学べ」と申していました。<br />将来発生しますお子さまへのご相続に今からご準備してみてはいかがでしょう。専門のコンサルタントにご相談ください。`)
        }
        if(differenceIncomeAndSpending < 0 && hasChild && (buyPropertyEvent || sellPropertyEvent)) {
            diagnosisesContent.push(`大変です！最終的な収支では、${fullName}さまが${ageCashOut}歳時点でキャッシュアウトしてしまいます。ふむふむ。<br />  アインシュタインも「人生とは自転車のようなものだ。倒れないようにするには走らなければならない」と申していました。<br />  収益不動産の買い替えなど対策をご検討いただけますでしょうか。専門のコンサルタントにご相談ください。`)
        }
        if(differenceIncomeAndSpending > 0 && !hasChild && (buyPropertyEvent || sellPropertyEvent)) {
            diagnosisesContent.push(`最終的な収支に問題はなく、豊かな老後を送れる人生設計となっております。すばらしいです！<br />   あのナポレオンも、「人生という試合で最も重要なのは、休憩時間の得点である。」と申していました。<br />ご旅行やご趣味などにエンジョイされるのはいかがでしょう。`)
        }
        if(differenceIncomeAndSpending < 0 && !hasChild && (buyPropertyEvent || sellPropertyEvent)) {
            diagnosisesContent.push(`大変です！最終的な収支では、${ageCashOut}歳時点でキャッシュアウトしてしまいます。ふむふむ。収益不動産の買い替えなど対策をご検討いただけますでしょうか。専門のコンサルタントへのご相談ください。<br />アメリカの女性コメディアン、ルシルポールを知っていますか。「自分になにができるかを知るより、なにができないかを知ることのほうが重要よ」と申していました。私はアドバイスと大豆を育てること以外はなにもできません。`)
        }
        if(differenceIncomeAndSpending > 0 && hasChild && !buyPropertyEvent && !sellPropertyEvent) {
            diagnosisesContent.push(`最終的な収支に問題はなく、豊かな老後を送れる人生設計となっております。すばらしいです！<br />経営の神様松下幸之助は、「いくつになってもわからないものが人生というものである。わからない人生を、わかったようなつもりで歩むほど危険なことはない」と申していました。<br />お子さまへ資産を残される相続対策に収益不動産をお持ちになることを専門のコンサルタントにご相談してみてはいかがでしょう。`)
        }
        if(differenceIncomeAndSpending < 0 && hasChild && !buyPropertyEvent && !sellPropertyEvent) {
            diagnosisesContent.push(`最終的な収支では、${ageCashOut}歳時点でキャッシュアウトしてしまいます。ふむふむ。「悲観主義者は風にうらみを言う。楽観主義者は風が変わるのを待つ。現実主義者は、帆を動かす。」とウィリアム・アーサー・ウォードも申します。<br />今すぐ家計を見直されて収益不動産で本業以外の収入を得られるなど、帆を動かすために、対策をご検討いただけますでしょうか。専門のコンサルタントにご相談ください。`)
        }
        if(differenceIncomeAndSpending > 0 && !hasChild && !buyPropertyEvent && !sellPropertyEvent) {
            diagnosisesContent.push(`最終的な収支に問題はなく、豊かな老後を送れる人生設計となっております。すばらしいです！バーバラ・ブラハムをご存知でしょうか？<br />「大抵の人は本当に何がほしいのか、心の中でわかっている。人生の目標を教えてくれるのは直感だけ。ただ、それに耳を傾けない人が多すぎる」と申しているので、${fullName}さまご自身の直感に従われてご趣味などを充実されるのはいかがでしょう。`)
        }
        if(differenceIncomeAndSpending < 0 && !hasChild && !buyPropertyEvent && !sellPropertyEvent && hasHouse) {
            diagnosisesContent.push(`最終的な収支では、${ageCashOut}歳時点でキャッシュアウトしてしまいます。ふむふむ。今すぐ家計を見直され、住宅ローンの借り換えや住み替えなどの対策をご検討してみてはいかがでしょう。専門のコンサルタントにぜひご相談ください。<br/>ヘンリー・デイビッド・ソローが「楽しみに金のかからない人が最も裕福である。」と申しておりました。<br />お金のかからないお楽しみをお探しになられませんか。`)
        }
        if(differenceIncomeAndSpending < 0 && !hasChild && !buyPropertyEvent && !sellPropertyEvent && rentHouse) {
            diagnosisesContent.push(`最終的な収支では、${ageCashOut}歳時点でキャッシュアウトしてしまいます。ふむふむ。物件を購入された方が支出を抑えられる場合があります。<br />「自分の内なるものも外なるものも、見ているものを変える必要はない。ただ見方を変えればいいのだ」とタデウス・ゴラスも申します。<br />家計の見方を変えられるために専門のコンサルタントにご相談してみてはいかがでしょう。`)
        }

        // 注釈テキスト
        if(toInt(lifePlan.person_income) > 0 && toInt(lifePlan.partner_income) > 0){
            diagnosisesContent.push(`<p class="font14">※収支には配偶者の収入も考慮している為、配偶者が途中で退職することを想定していて、物件の購入などローンを予定している方は、<br />　与信があるうちにローンを組むことをお勧め致します。</p`)
        }

        setDiagnosisContent(diagnosisesContent)
    }, [spendingResult])

    return (
        <div className="diagnosis result marB80">
            <h2>
                <img src={iconHuman} alt="" width="125" /> 診断結果
            </h2>
            <div className="gradBluBg gradBluBg4">
            <div className={`whiteBox pad24 ${loggedIn ? '' : "blurCont"}`}>
                { diagnosisesContent.map((text, index) =>
                    <p key={index} style={{"marginBottom": "unset"}} dangerouslySetInnerHTML={{ __html: text }}></p>
                )}
            </div>
            </div>
        </div>
    )
}

export default React.memo(LpsCaculationResultsWidget);
