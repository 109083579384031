import React from 'react';
import EventGroup from "./EventGroup";

export default function BgPaginationItem(props) {
  return (
    <React.Fragment>
      <div className="timelineBgPaginationItem">
        <div className="label">
          {props.item.label.age}<span>代</span>
        </div>
        { Object.keys(props.item.eventGroups).map(key => <EventGroup eventGroup={props.item.eventGroups[key]} key={key}/>) }
      </div>
    </React.Fragment>
  )
}
