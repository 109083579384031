import React, { useState, useEffect } from 'react';
import {convertToJapanCurrency} from "../../shared/constants/global";

export default function ResultGraphIncomeWidget(props) {
    const { loggedIn, results } = props;
    const [total, setTotal] = useState(0)

    const convertTextCategory = (category_id) => {
        switch (category_id) {
          case 1:
            return "給与"
          case 2:
            return "その他収入"
          case 3:
            return "公的年金"
          case 4:
            return " 試算開始時預金額"
        }
      }

    const calculateTotal = () => {
      let total = 0;
      results.forEach((result) => {
        if(result.category !== 4){
          total = total + result.value;
        }
      })

      return total;
    }

    useEffect(() => {
      try {
        setTotal(calculateTotal())
      } catch (e) {
        console.log(e)
      }
    }, [results])


    const incomeInformation = () => {
        if(!results.length) return null;
        return (
          <div className="resultGraph income">
            <table>
              <tbody>
                <tr>
                    <th>{convertTextCategory(results[0].category)}</th>
                    <td className={loggedIn ? '' : 'blurCont'}>{convertToJapanCurrency(results[0].value)}</td>
                </tr>
                <tr>
                    <th>{convertTextCategory(results[1].category)}</th>
                    <td className={loggedIn ? '' : 'blurCont'}>{convertToJapanCurrency(results[1].value)}</td>
                </tr>
                <tr>
                    <th>{convertTextCategory(results[2].category)}</th>
                    <td className={loggedIn ? '' : 'blurCont'}>{convertToJapanCurrency(results[2].value)}</td>
                </tr>
                <tr>
                    <th>合計</th>
                    <td className={loggedIn ? '' : 'blurCont'}>{convertToJapanCurrency(total)}</td>
                </tr>
                <tr>
                    <td className={loggedIn ? '' : 'blurCont col2'} colSpan={2} >
                      <span className="ico-plus"></span>
                    </td>
                </tr>
                <tr>
                    <th>{convertTextCategory(results[3].category)}</th>
                    <td className={loggedIn ? '' : 'blurCont'}>{convertToJapanCurrency(results[3].value)}</td>
                </tr>
              </tbody>
            </table>
         </div>
        )
    }

    return (
        incomeInformation()
    )
}
