import { BrowserRouter } from "react-router-dom";
import React from "react";
import { Provider } from 'react-redux';
import {store} from "./redux/store";
import "./assets/i18n/i18n";
import ChatBot from "./chatbot/ChatBot";
import { ChatbotSteps } from "./chatbot-step"
import { ChatBotImages } from './assets/images';
import { useTranslation } from 'react-i18next';
const { mascot1, mascot2, userBot } = ChatBotImages;

export default function Index(props) {
  const { t } = useTranslation('saleModal');

  const steps= [
    // ...ChatbotSteps.start(),
    // ...ChatbotSteps.aiSale(),
    // ...ChatbotSteps.category(t),
    // ...ChatbotSteps.building(t),
    ...ChatbotSteps.contact(t)
  ]

  return <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ChatBot
          steps={steps}
          headerTitle="お問い合わせチャットボット<br class='brsp' />（小田急不動産）"
          hideFooter={true}
          enableSmoothScroll={true}
          className="aiSale"
          headerAvatar={mascot1}
          botAvatar={mascot2}
          userAvatar={userBot}
          cache={true}
          cacheName="chatbot"
        />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
}
