import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { simulationParams } from '../../shared/constants/simulator';
import { LifePlanService } from '../../core/services/life-plans';

export default function MainChart(props) {
  const lifePlan = useSelector(state => state.life_plan)
  const personAge = parseInt(lifePlan.person_age) || 20
  const currentYear = new Date().getFullYear()

  const initOptions = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    fill: {
      colors: ['#1780eb', '#ff5555', '#00ab00', '#ffd943']
    },
    colors: [
      '#1780eb', '#ff5555', '#00ab00', '#ffd943'
    ],
    plotOptions: {
      bar: {
        columnWidth: '75%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 3, 3],
      colors: ['#1780eb', '#ff5555', '#00ab00', '#ffd943']
    },
    xaxis: {
      categories: [
        ['30歳', '2022'], ['35歳', '2027'], ['40歳', '2032'], ['45歳', '2037'],
        ['50歳', '2042'], ['55歳', '2047'], ['60歳', '2052'], ['65歳', '2057'],
        ['70歳', '2062'], ['75歳', '2067'], ['80歳', '2072']
      ],
      labels: {
        rotate: 0,
        formatter: function(value) {
          if (value !== undefined && parseInt(value[0]) % 5 === 0) {
            return value;
          } else {
            return '';
          }
        }
      },
      tooltip: {
        enabled: false
      },
    },
    tooltip: {
      x: {
        formatter: (value) => `${value + personAge - 1}歳 ${value + currentYear - 1}`
      }
    },
    yaxis: [
      {
        showAlways: true,
        forceNiceScale: true,
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: '#000000',
            fontWeight: 400
          },
          formatter: (value) => {
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + "万円";
          }
        }
      },
      {
        forceNiceScale: true,
        show: false,
        labels: {
          style: {
            colors: '#000000',
            fontWeight: 400
          },
          formatter: (value) => {
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + "万円";
          }
        }
      },
      {
        showAlways: true,
        forceNiceScale: true,
        opposite: true,
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: '#000000',
            fontWeight: 400
          },
          formatter: (value) => {
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + "万円";
          }
        }
      },
      {
        forceNiceScale: true,
        opposite: true,
        show: false,
        labels: {
          style: {
            colors: '#000000',
            fontWeight: 400
          },
          formatter: (value) => {
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + "万円";
          }
        }
      }
    ],
    markers: {
      size: [3]
    },
    legend: {
      position: 'top',
      offsetX: 500,
      onItemClick: {
        toggleDataSeries: false
      },
      markers: {
        radius: 0
      },
      fontWeight: 'bold'
    }
  }

  const initData = [
    {
      name: '収入',
      type: 'column',
      data: []
    }, {
      name: '支出',
      type: 'column',
      data: []
    }, {
      name: '貯蓄額（不動産収支を除く）',
      type: 'line',
      data: []
    },{
      name: '貯蓄額（不動産収支を含む）',
      type: 'line',
      data: []
    }
  ]

  const [data, setData] = useState([ ...initData ]);
  const [options, setOptions] = useState({ ...initOptions })

  const calculatorChart = (results) => {
    var xaxis = []
    var incomeData = []
    var expenditureData = []
    var savingData = []
    var notSavingData = []
    results.map(result => {
      xaxis.push([`${result.age_of_person}歳`, currentYear + result.age_of_person - personAge])
      if (result.age_of_person % 1 === 0) {
        incomeData.push(Math.round(result.total_income / 10000))
        expenditureData.push(Math.round(result.total_expenditure / 10000))
      } else {
        incomeData.push(undefined)
        expenditureData.push(undefined)
      }
      savingData.push(Math.round(result.savings_include_real_estate / 10000))
      notSavingData.push(Math.round(result.savings_not_include_real_estate / 10000))
    });

    const data = [
      {
        name: '収入',
        type: 'column',
        data: incomeData
      }, {
        name: '支出',
        type: 'column',
        data: expenditureData
      }, {
        name: '貯蓄額（不動産収支を除く）',
        type: 'line',
        data: notSavingData
      },{
        name: '貯蓄額（不動産収支を含む）',
        type: 'line',
        data: savingData
      }
    ]

    const min = Math.min(...incomeData, ...expenditureData, ...notSavingData, ...savingData)
    const max = Math.max(...incomeData, ...expenditureData, ...notSavingData, ...savingData)

    const options = {
      ...initOptions,
      xaxis: {
        ...initOptions.xaxis,
        categories: xaxis
      },
      yaxis: [
        {
          ...initOptions.yaxis[0],
          min: min,
          max: max
        },
        {
          ...initOptions.yaxis[1],
          min: min,
          max: max
        },
        {
          ...initOptions.yaxis[2],
          min: min,
          max: max
        },
        {
          ...initOptions.yaxis[3],
          min: min,
          max: max
        }
      ]
    }

    return { data, options }
  }

  const hiddenTitleChart = () => {
    $('.btnChart').hide()
    $('.leftCol').hide()
    $('.rightCol').hide()
  }

  const showTitleChart = () => {
    $('.btnChart').show()
    $('.leftCol').show()
    $('.rightCol').show()
  }

  const handleRegisterSimulation = async () => {
    if (lifePlan.person_age) {
      try {
        const results = (await LifePlanService.registerSimulation(simulationParams(lifePlan))).response.data.life_plan_simulation_result;
        const { data, options } = calculatorChart(results);
        setOptions(options)
        setData(data)
        showTitleChart()
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    hiddenTitleChart()
    handleRegisterSimulation()
  }, [lifePlan])

  return <React.Fragment>
    <div id="chart">
      <Chart options={options} series={data} type="line" height="350" />
    </div>
  </React.Fragment>
}
