import React from 'react';

export default function EventGroupHeader(props) {
  const currentYear = new Date().getFullYear()

  return (
    <React.Fragment>
      <div
        className="timelineEventGroupHeader"
        style={{"display": props.isShow ? "inline-flex" : "none", "borderRadius": props.isShowCurrentYearLabelBorder ? "0 8px 8px 0" : "0 8px 0 0"}}>
        <div className="labels">
          <div className="year">{props.label.year}</div>
          <div className="sub">({props.label.age}歳)</div>
        </div>
        {props.label.year === currentYear &&
          <div className="timelineCurrentYearLabel">
            現在
          </div>
        }
      </div>
    </React.Fragment>
  )
}
