import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icoHumanL from 'images/mascot/mascot2.png';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';

export default function WelcomeModal(props) {
  const { t } = useTranslation('welcomeModal');

  const handleNextModal = () => {
    props.handleNextModal && props.handleNextModal(0)
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_WELCOME}>
    <div className='popupBox'>
      <div className='popupInner'>
        <div className='lifeplanPopup'>
          <h2>{t('title')}</h2>
          <div className="popupContents">
            <div className='popupContentsCover figBox justify-center'>
              <div className='popupContentsRight p-relative'>
                <div className='popupContentsTxt'>
                  <div className="lifeplan_welcome">
                    <figure><img src={icoHumanL} className="step_image"/></figure>
                    <div className="lifeplan_welcome_logo">
                      <span className="lifeplan_welcome_text1">ナビゲーター</span>
                      <br/>
                      <span className="lifeplan_welcome_text2 text">OTOUFU-X</span>
                    </div>
                  </div>
                  <h3>LIFE SCAPE 1<br/>ライフプランシミュレーションへようこそ</h3>
                  <p>人生100年時代。あなたの人生プランを見える化しませんか？<br/>
                    収入・貯蓄・資産やライフスタイルから、未来の収支やライフプランをより豊かなものにするためにお役立てください。
                  </p>
                  <div className='welcome-slideshow'>
                    <img src="/images/welcome-slideshow.webp" className="pc" alt="image" />
                    <img src="/images/welcome-slideshow-mini.gif" className="sp" alt="image" />
                  </div>
                </div>

                <div className='contactBtnBox marB40'>
                  <p className='alC font20 fontWB'>{t('agree')}</p>
                  <ul className='btnList'>
                    <li><a onClick={handleNextModal} className='btnSty-send'>{t('startNow')}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='cautionBox bgGray'
                 dangerouslySetInnerHTML={{__html: t('cautionBox', {returnObjects: true}).join('')}}></div>
          </div>
        </div>
      </div>
    </div>
  </LifePlanModal>
}
