const updateHeaderInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use((config) => {
        const csrfToken = document.getElementsByName('csrf-token')[0].content;
        if (config.headers) {
            config.headers["Accept"] = "application/json";
            config.headers["Content-Type"] = "application/json";
            config.headers["X-CSRF-Token"] = csrfToken;
        }
        return config;
    }, (error) => {
        return error;
    });
};

export default updateHeaderInterceptor;