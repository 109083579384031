import styled from 'styled-components';
import defaultTheme from '../theme';

const ChatBotContainer = styled.div`
`;

ChatBotContainer.defaultProps = {
  theme: defaultTheme
};

export default ChatBotContainer;
