import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik, useFormikContext } from 'formik';
import iconSave from '../../assets/icons/ico-save.svg';
import { useDispatch, useSelector } from 'react-redux';
import InputTextCustom from './components';
import { setInputLifePlan } from '../../redux/lifePlan';
import SelectFieldSecond from '../../shared/components/select-field-second';
import CommaInput from '../../shared/components/comma-input-field';
import RadioField from '../../shared/components/radio-field';
import { ModalName } from '../../shared/constants/global';
import LifePlanModal from '../../shared/components/modal';
import { FlowCurrent } from '../../shared/components/flow-current';
import { YupSchema } from '../../shared/constants/validators';

const FormObserver = (props) => {
  const { values } = useFormikContext();

  useEffect(() => {
    props.handleFormChangeValues && props.handleFormChangeValues(values)
  }, [values]);

  return null;
};

export default function InputSpendingModal(props) {
  const { t } = useTranslation('inputSpendingModal');
  const tGlobal = useTranslation('global').t;

  const dispatch = useDispatch();
  const person_age = useSelector(state => state.life_plan.person_age || 0)
  const lifePlan = useSelector(state => state.life_plan);
  const childState = useSelector(state => state.life_plan.child);
  const currentYear = new Date().getFullYear();

  const [isSave, setIsSave] = useState(false);
  const [ initialValues, setInitialValues ] = useState({
    residence_type: 2,
    housing_loan: 1,
    rent: '',
    term_insurance_monthly: '',
    term_insurance_end: new Date().getFullYear(),
    housing_monthly_repayment: '',
    housing_loan_balance: '',
    housing_bonus_repayment: '',
    housing_repayment_end: new Date().getFullYear()
  });

  const formRef = useRef();
  const timeoutId = useRef();

  const validateSchema = new YupSchema(t).inputSpendingModal;

  const yearUntilOneHundred = useMemo(() => {
    const yearArray = []
    for(let i = 0; i <= (99 - +person_age); i ++) {
      yearArray.push(currentYear + i)
    }
    return yearArray;
  }, [person_age])

  const renderYearFromNow = (year) => {
    return `${year - currentYear + +person_age}歳（${year}年）`
  }

  const handleNextModal = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();
    if (!formRef.current.isValid) return;

    dispatch(setInputLifePlan({ ...values }));
    timeoutId.current && clearTimeout(timeoutId.current);
    document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPENDING).classList.remove('savePopup');
    props.handleNextModal && props.handleNextModal(3)
  }

  const handlePrevModal = () => {
    timeoutId.current && clearTimeout(timeoutId.current);
    document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPENDING).classList.remove('savePopup');
    props.handlePrevModal && props.handlePrevModal(3)
  }

  const handleSubmit = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    const child = childState.map(ele => {
      return { ...ele }
    })
    setIsSave(true)
    dispatch(setInputLifePlan({ ...values, child }))
  }

  const handleSave = async () => {
    setIsSave(false)
    try {
      props.handleSubmitLifePlan && props.handleSubmitLifePlan(false);
      document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPENDING).classList.add('savePopup');
      timeoutId.current = setTimeout(() => {
        document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPENDING).classList.remove('savePopup');
      }, 3000)
    } catch (e) {
      console.log(e)
    }
  }

  const onResidenceTypeChange = (residence_type) => {
    if (residence_type === 2) {
      formRef.current.setFieldValue("rent", '');
    } else {
      formRef.current.setFieldValue("housing_monthly_repayment", '');
      formRef.current.setFieldValue("housing_loan_balance", '');
      formRef.current.setFieldValue("housing_bonus_repayment", '');
    }
  }

  const onHousingTypeChange = (housing_loan) => {
    if (housing_loan === 1) {
      formRef.current.setFieldValue("housing_monthly_repayment", '');
      formRef.current.setFieldValue("housing_loan_balance", '');
      formRef.current.setFieldValue("housing_bonus_repayment", '');
    }
  }

  useEffect(() => {
    let updateValues = {}
    Object.keys(initialValues).forEach(key => {
      if (lifePlan[key] !== null) {
        updateValues[key] = lifePlan[key]
        if (key == "term_insurance_monthly" || key == "housing_monthly_repayment") {
          updateValues[key] = !!lifePlan[key] ? +lifePlan[key] : ''
        }
      }
    });
    setInitialValues({
      ...initialValues,
      ...updateValues
    })

    if (isSave) handleSave();
  }, [lifePlan])

  const handleFormChangeValues = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    if (props.step != 0) {
      const newObj = {...lifePlan, ...values}
      const lifePlanStr = JSON.stringify(newObj)
      window.lsSession.setItem("life_plans", lifePlanStr)
    }
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_INPUT_SPENDING}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        formRef.current = formikProps;

        return (
          <Form>
            <div className="popupBox">
              <div className="popupInner">
                <div id={'lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPENDING} className="lifeplanPopup">
                  <h2 className="padB44">{t('label')}</h2>
                  <div className="popupContents">
                    <FlowCurrent flow="支出" />

                    <div className="marB14">{t('currentResidenceStatus')}</div>
                    <dl className="formSty marB40">
                      <dt className="padT24">{t('houseType')}</dt>
                      <dd id="selectItem2">
                        <Field name="residence_type" options={[2, 1]} labels={t('houseTypeOption', { returnObjects: true })} component={RadioField} onChange={() => onResidenceTypeChange(values.residence_type)} />
                      </dd>
                      <dt id="selectItem2-ttl" className={`checkShowTtl ${values.residence_type === 1 ? 'padT24' : ''}`}>
                        <span className={`select-item2_1-ttl selectTtlBox ${values.residence_type === 1 ? 'displayNo' : ''}`}>家賃</span>
                        <span className={`select-item2_2-ttl selectTtlBox ${values.residence_type === 2 ? 'displayNo' : ''}`}>{t('houseLoan')}</span>
                      </dt>
                      <dd id="selectItem2-item" className="checkShowItem">
                        <div className={`select-item2_1-box selectItemBox ${values.residence_type === 1 ? 'displayNo' : ''}`}>
                          <Field type="text" name="rent" className="wid120 toggle-item" component={CommaInput} secondLabel={tGlobal('tenThousandYen') + '/月'} setFieldValue={setFieldValue} />
                        </div>
                        <div className={`select-item2_2-box selectItemBox ${values.residence_type === 2 ? 'displayNo' : ''}`}>
                          <div id="checkShow3">
                            <Field name="housing_loan" options={[1, 0]} labels={t('houseLoanOption', { returnObjects: true })} component={RadioField} onChange={() => onHousingTypeChange(values.housing_loan)} />
                          </div>
                          <ul id="checkShow3-item" className={`checkShowItem itemRowList ${values.housing_loan === 0 ? 'hiddenItem' : ''}`}>
                            <li style={{display: 'block'}}>
                              <Field type="text" decimal={1} name="housing_monthly_repayment" className="wid80 toggle-item" component={InputTextCustom} secondLabel={tGlobal('tenThousandYen')} label={t('monthlyRepaymentAmount')} disabled={values.housing_loan === 0} setFieldValue={setFieldValue} />
                            </li>
                            <li style={{display: 'block'}}>
                              <Field type="text" name="housing_loan_balance" className="wid80 toggle-item" component={InputTextCustom} secondLabel={tGlobal('tenThousandYen')} label={t('loanBanlance')} disabled={values.housing_loan === 0} setFieldValue={setFieldValue} />
                            </li>
                            <li style={{display: 'block'}}>
                              <Field type="text" name="housing_bonus_repayment" className="wid80 toggle-item" component={InputTextCustom} label={t('monthlyRepaymentAmount2')} secondLabel={tGlobal('tenThousandYen') + '/1回分'} disabled={values.housing_loan === 0} setFieldValue={setFieldValue} />
                            </li>
                            <li style={{display: 'block'}}>
                              <div>
                                <span>{t('yearRepaymentEnd')}</span>
                                <span className="itemRowListInput">
                                  <Field component={SelectFieldSecond} className="wid155" name="housing_repayment_end" options={yearUntilOneHundred} display_options={renderYearFromNow} disabled={values.housing_loan === 0} />
                                  <span className="formUnit">{tGlobal('untilYear')}</span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </dd>
                      <dt>{t('dropOffIsurance')}</dt>
                      <dd className="noFlex">
                        <div className="dF inputSpending">
                          <div>
                            <Field type="text" name="term_insurance_monthly" decimal={1} className="wid120" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          </div>
                          <dl className="formTtlSty nowrap wid380 mLPc100">
                            <dt>{t('end')}</dt>
                            <dd>
                              <Field component={SelectFieldSecond} width="wid200" name="term_insurance_end" options={yearUntilOneHundred} display_options={renderYearFromNow} />
                              <span className="formUnit">{tGlobal('untilYear')}</span>
                            </dd>
                          </dl>
                        </div>
                        <p className="font14 marT16">{t('insuranceNotice')}</p>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList reverse">
                        <li><button type="submit" onClick={() => handleSubmit(values)} className="linkBtn wid200"><figure><img src={iconSave} alt="" width="16" /></figure><span>{tGlobal('save')}</span></button></li>
                        <li><button type="button" onClick={handlePrevModal} className="btnSty-back">{tGlobal('back')}</button></li>
                        <li><button type="submit" onClick={() => handleNextModal(values)} className="btnSty-send">{tGlobal('next')}</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <FormObserver handleFormChangeValues={handleFormChangeValues} />
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
