import { createSlice } from '@reduxjs/toolkit'
import { remove } from 'lodash';
import { DefaultLifeEvent, DefaultLifePlan, getEventMapping, ModalName, getCycleEventColor } from '../../shared/constants/global';

const createUuid = () => {
  return Math.random().toString().replace("0.", "")
}

const buildLifeEvent = (attributes) => {
  return Object.assign({}, DefaultLifeEvent, attributes)
}

const cycleEventTypes = [5, 6, 7, 8, 9, 10, 11]

const setCycleEventColor = (event) => {
  if (event.event_type == 10 && event.other.detail == 7) {
    event.end_year = +event.year + 3
  }
  if (cycleEventTypes.includes(event.event_type) && (event.end_year - event.year >= (+event.other.frequency_year || 1))) {
    if (event.cycleEventColor && event.cycleEventColor != "#E5E9EB") return
    event.cycleEventColor = getCycleEventColor()
  } else {
    event.cycleEventColor = "#E5E9EB"
  }
}

const removeCycleEvents = (uuid, state) => {
  let cycleLiveEvent = [...state.cycleEvent]
  remove(cycleLiveEvent, e => e.uuid === uuid)
  state.cycleEvent = [...cycleLiveEvent]
}

const addCycleEvents = (event, state) => {
  let frequencyYear = +event.other.frequency_year || 1;
  if (event.event_type == 11 && event.other.detail == 8) {
    frequencyYear = 4
  }

  for(let i = +event.year + frequencyYear; i <= +event.end_year; i += frequencyYear) {
    const cloneLifeEventSelected = {...event}
    cloneLifeEventSelected.year = i
    cloneLifeEventSelected.isClone = true
    cloneLifeEventSelected.cycleEventColor = event.cycleEventColor

    state.cycleEvent.push(cloneLifeEventSelected)
  }
}

const initState = {
  modalOpenName: '',
  subModalOpenName: '',
  life_plan: DefaultLifePlan,
  cycleEvent: [],
  lifeEventSelected: null,
  isDragging: false
}

export const lifePlanSlice = createSlice({
  name: 'lifePlan',
  initialState: initState,
  reducers: {
    openModal: (state, action) => {
      state.modalOpenName = action.payload;
    },
    closeModal: (state) => {
      state.modalOpenName = '';
      state.lifeEventSelected = null;
    },
    openSubModal: (state, action) => {
      state.subModalOpenName = action.payload;
    },
    closeSubModal: (state) => {
      state.subModalOpenName = '';
    },
    setLifeEventSelected: (state, action) => {
      if (!action.payload.year || parseInt(action.payload.year) < parseInt(state.life_plan.person_age)) {
        action.payload.year = state.life_plan.person_age
      }
      state.lifeEventSelected = buildLifeEvent(action.payload)
    },
    showSelectedModel: (state) => {
      if(state.lifeEventSelected) {
        state.modalOpenName = getEventMapping(state.lifeEventSelected).modal_name
      }
    },
    saveSelectedLifeEvent: (state, action) => {
      const lifeEventSelected = state.lifeEventSelected
      if (!lifeEventSelected) return

      if (action.payload) {
        Object.assign(lifeEventSelected, action.payload)
      }

      const eventsInYear = state.life_plan.life_event.filter((event) => parseInt(event.year) === parseInt(lifeEventSelected.year))
      const indexInYear = eventsInYear.findIndex((event) => event.uuid === lifeEventSelected.uuid)
      if (eventsInYear.length >= 8 && (lifeEventSelected.uuid === undefined || indexInYear === -1)) {
        state.lifeEventSelected = null
        state.modalOpenName = ModalName.LIFEPLAN_CANNOT_ADD_EVENT

        return;
      }
      if (lifeEventSelected.uuid) {
        setCycleEventColor(lifeEventSelected)
        const index = state.life_plan.life_event.findIndex((event) => event.uuid === lifeEventSelected.uuid)
        if (index !== -1) {
          if (state.life_plan.life_event[index].year != lifeEventSelected.year) {
            state.life_plan.life_event.splice(index, 1)
            state.life_plan.life_event.push(lifeEventSelected)
          } else {
            state.life_plan.life_event[index] = lifeEventSelected
          }

          // update cycleEvent
          if (cycleEventTypes.includes(lifeEventSelected.event_type)) {
            removeCycleEvents(lifeEventSelected.uuid, state)
            addCycleEvents(lifeEventSelected, state)
          }
        }
      } else {
        lifeEventSelected.uuid = createUuid()
        setCycleEventColor(lifeEventSelected)
        state.life_plan.life_event.push(lifeEventSelected)

        //add cycleEvent
        if (cycleEventTypes.includes(lifeEventSelected.event_type)) {
          addCycleEvents(lifeEventSelected, state)
        }
      }
      window.lsSession.setItem("life_plans", JSON.stringify(state.life_plan))
      state.lifeEventSelected = null
      state.modalOpenName = ""
    },
    removeSelectedLifeEvent: (state, _action) => {
      const lifeEventSelected = state.lifeEventSelected
      if (!lifeEventSelected) return;

      if (lifeEventSelected.uuid) {
        const index = state.life_plan.life_event.findIndex((event) => event.uuid === lifeEventSelected.uuid)
        if (index !== -1) {
          let liveEvents = [...state.life_plan.life_event]
          liveEvents.splice(index, 1);
          state.life_plan.life_event = [...liveEvents]

          //remove cycleEvent
          if (cycleEventTypes.includes(lifeEventSelected.event_type)) {
            removeCycleEvents(lifeEventSelected.uuid, state)
          }
        }
      }
      window.lsSession.setItem("life_plans", JSON.stringify(state.life_plan))
      state.lifeEventSelected = null
      state.modalOpenName = ""
    },
    setIsDragging: (state, action) => {
      state.isDragging = action.payload
    },
    setInputLifePlan: (state, action) => {
      const MAX_AGE = 99
      const values = action.payload
      const isChangePersonAge = values.person_age && state.life_plan.person_age != values.person_age
      const isChangeResidenceType = values.residence_type && values.residence_type == 2
      const number_of_people = (state.life_plan.child.length + state.life_plan.partner) || (values.child.length + values.partner)
      const cloneValues = { ...values, number_of_people: number_of_people}
      state.life_plan = Object.assign(state.life_plan, cloneValues)

      state.life_plan.life_event.forEach((event) => {
        event.uuid ||= createUuid()
        if ([5, 6].includes(event.event_type)) {
          if (event.event_type == 5) {
            event.other.number_of_people = number_of_people
          } else if (event.event_type == 6 && number_of_people < event.other.number_of_people ) {
            event.other.number_of_people = number_of_people
          }
          removeCycleEvents(event.uuid, state)
          addCycleEvents(event, state)
        }
      })

      if (isChangeResidenceType) {
        state.life_plan.life_event = state.life_plan.life_event.filter(event => event.event_type != 4)
      }

      if (isChangePersonAge) {
        const currentYear = new Date().getFullYear()
        const maxYear = currentYear + MAX_AGE - values.person_age

        // remove event has year outside currentYear to 99
        state.life_plan.life_event = state.life_plan.life_event.filter(event => event.year <= maxYear)

        //update event has endyear outside currentYear to 99
        state.life_plan.life_event.forEach((event) => {
          event.uuid ||= createUuid()
          if (event.end_year > maxYear) {
            event.end_year = maxYear
          }
          if (cycleEventTypes.includes(event.event_type) && (event.end_year - event.year >= (+event.other.frequency_year || 1))) {
            setCycleEventColor(event)
            removeCycleEvents(event.uuid, state)
            addCycleEvents(event, state)
          }
        })
      }
    }
  }
})

export const {
  openModal,
  closeModal,
  openSubModal,
  closeSubModal,
  setLifeEventSelected,
  saveSelectedLifeEvent,
  removeSelectedLifeEvent,
  setIsDragging,
  showSelectedModel,
  setInputLifePlan,
} = lifePlanSlice.actions;

export default lifePlanSlice.reducer;
