import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import { closeModal, openSubModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import LifePlanModal from '../../shared/components/modal';
import { LifePlansImages } from '../../assets/images';
import { generateYearsByMemberAge, ModalName, ValidateField } from '../../shared/constants/global';
import InputText from '../../shared/components/input-field';
import CommaInput from '../../shared/components/comma-input-field';
import SelectFieldSecond from '../../shared/components/select-field-second';
import { YupSchema } from '../../shared/constants/validators';
import { cloneDeep } from "lodash";

const {imgTravel1, imgTravel2, imgTravel3, imgTravel4, imgTravel5, imgTravel6, imgTravel7, imgTravel8, imgTravel9, imgTravel10, imgTravel11, imgTravel12} = LifePlansImages;
const arrImages = [imgTravel1, imgTravel2, imgTravel3, imgTravel4, imgTravel5, imgTravel6, imgTravel7, imgTravel8,  imgTravel9, imgTravel10, imgTravel11, imgTravel12];

export default function EventTravelModal(props) {
  const { t } = useTranslation('eventTravelModal');
  const tGlobal = useTranslation('global').t;
  const dispatch = useDispatch();
  const { lifeEvent } = props;
  const numberOfPeople = useSelector(state => state.life_plan.number_of_people);
  const uuid = useSelector(state => state.lifeEventSelected.uuid);
  const storeLifeEvents = useSelector(state => state.life_plan.life_event);

  const formState = useRef();

  const [checkShow, setCheckShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [initialValues, setInitialValues] = useState({ ...props.lifeEvent, other: { ...props.lifeEvent?.other } });

  const validateSchema = useMemo(() => {
    return new YupSchema(t).eventTravelModal;
  }, [])

  useEffect(() => {
    const values = { ...props.lifeEvent, other: { ...props.lifeEvent.other } }
    setInitialValues(values)
    setCheckShow(values.other.detail == 12)
    if (uuid) {
      let cycleYear = storeLifeEvents.find((event) => event.uuid === uuid).year
      formState.current.setFieldValue("year", cycleYear);
    }
  }, [props.lifeEvent]);

  const handleClose = () => {
    dispatch(closeModal());
  }

  const getDetailName = (detail, data) => {
    switch (detail) {
      case 1:
        return "国内温泉旅行"
      case 2:
        return "国内リゾート旅行"
      case 3:
        return "沖縄旅行"
      case 4:
        return "韓国旅行"
      case 5:
        return "グアム旅行"
      case 6:
        return "アメリカ旅行"
      case 7:
        return "ハワイ旅行"
      case 8:
        return "オーロラ鑑賞ツアー"
      case 9:
        return "ヨーロッパ列車の旅"
      case 10:
        return "世界一周旅行"
      case 11:
        return "宇宙旅行"
      case 12:
        return data.other?.custom || ""
      default:
        return ""
    }
  }

  const prepareData = (values) => {
    const tempData = cloneDeep(values);
    const params = {
      ...tempData,
      other: {
        ...tempData.other,
        detail_name: getDetailName(+tempData.other?.detail, tempData)
      }
    }
    return params;
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      const data = prepareData(values);
      dispatch(saveSelectedLifeEvent(data));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    return `${subYear(year)}歳（${year}年）`;
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age;
  }

  const dataFrequency = useMemo(() => {
    let result = []
    for (let i = 1; i <= ValidateField.EventTravelModal.MAX_FREQUENCY; i++) {
      result.push(i)
    }
    return result;
  }, [])

  const dataPeople = useMemo(() => {
    let result = []
    for (let i = 1; i <= numberOfPeople; i++) {
      result.push(i)
    }
    return result;
  }, [])

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  const onSelect = (data) => {
    setSelectedValue(+data)
    if (+formState.current.values.end_year < +data) {
      formState.current.setFieldValue("end_year", +data);
    }
  }

  const dataEndYear = useMemo(() => {
    if (!selectedValue) {
      return Object.keys(yearMapping).filter(ele => {
        if (uuid) {
          let cycleYear = storeLifeEvents.find((event) => event.uuid === uuid).year
          return +ele >= cycleYear
        } else {
          return +ele >= initialValues.year
        }
      });
    } else {
      return Object.keys(yearMapping).filter(ele => {
        return +ele >= +selectedValue;
      });
    }
  }, [selectedValue, initialValues.year])

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_TRAVEL}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>{t('label')}</h2>
                  <div className="popupContents">
                    <div className="popupContentsCover">
                      <div id="checkshow1" className="whiteBox checkShowToggle radioImgBox pad24">
                        <ul className="radioImgBtnList rowList">
                          {[...Array(12)].map((e, i) => {
                            return <li key={i}><label className={`radioBtn ${i + 1 == values.other.detail ? "checked" : ""}`}>
                                    <Field onClick={() => setCheckShow(i == 11 ? true : false)}
                                      type="radio" value={i + 1} name="other.detail" className="toggle_hide" />
                                    <img src={arrImages[i]} /><span>{t(`options.${i}`)}{t(`cost.${i}`)}</span>
                                  </label></li>
                          })}
                        </ul>
                      </div>
                    </div>
                    <dl className="formSty lifeplanImgBtnBox marB40">
                      <dt id="checkShow1-ttl" className={`checkShow1 ${!checkShow ? 'hiddenTtl' : ''}`}>{t('custom')}</dt>
                      <dd id="checkShow1-item" className={`checkShow1 ${!checkShow ? 'hiddenItem' : ''} formTtlSty nowrap`}>
                        <div className="maxWidth400">
                          <Field className="toggle-item" type="text" component={InputText} name="other.custom" placeholder={t('customPlaceholder')} disabled={!checkShow} dF={true} />
                        </div>
                        <dl className="formTtlSty nowrap wid380 marL40">
                          <dt>{t('amount')}</dt>
                          <dd>
                            <Field className="wid120" placeholder="30" type="text" component={CommaInput} name="other.price" disabled={!checkShow} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          </dd>
                        </dl>
                      </dd>
                      <dt>{t('season')}</dt>
                      <dd className="formTtlSty nowrap">
                        <div className="dF aisC maxWidth400">
                          <Field component={SelectFieldSecond} width="wid200" name="year" customChange={onSelect} options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                        </div>
                        <dl className="formTtlSty nowrap wid380 marL40">
                          <dt>終了</dt>
                          <dd>
                            <Field component={SelectFieldSecond} width="wid200" name="end_year" options={dataEndYear} display_options={renderYearFromNow} />
                          </dd>
                        </dl>
                      </dd>
                      <dt>{t('travelFrequency')}</dt>
                      <dd>
                        <div className="dF aisC maxWidth400">
                          <div className="selectStyBox wid120">
                            <Field component={SelectFieldSecond} name="other.frequency_year" options={dataFrequency} />
                          </div>
                          <span className="formUnit">{t('unit')}</span>
                        </div>
                      </dd>
                      <dt>{t('numPeople')}</dt>
                      <dd>
                        <div className="dF aisC maxWidth400">
                          <div className="selectStyBox wid120">
                            <Field component={SelectFieldSecond} name="other.number_of_people" options={dataPeople} />
                          </div>
                          <span className="formUnit">人</span>　<span className="font14">{t('notice')}</span>
                        </div>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList col2">
                        <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                        <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                        { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
