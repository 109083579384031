import React from 'react';
import { getIn, ErrorMessage } from 'formik';
import TextError from '../error-message';

export default function SelectFieldSecond(props) {
  const { field, form, options, width, display_options, customChange, ...selectProps } = props;

  const name = field.name;

  function getStyles(errors, touched, name) {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  const handleChange = (e) => {
    const changeEvent = {
      target: {
        name: e.target.name,
        value: e.target.value
      }
    }
    if (customChange) {
      field && field.onChange(changeEvent);
      customChange(e.target.value);
    } else {
      field && field.onChange(changeEvent);
    }
  }

  return <React.Fragment>
    <ErrorMessage name={name} render={TextError} />
    <div className={`selectStyBox ${width ? width : ''}`}>
      <select
        {...selectProps}
        id={name}
        {...field}
        style={getStyles(form.errors, form.touched, name)}
        onChange={handleChange}
      >
        {options.map((opt) => <option key={opt} value={opt}>{display_options ? display_options(opt) : opt}</option>)}
      </select>
    </div>
  </React.Fragment>
}
