const errorInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error?.status?.code === 401) {
        } else {
        }
        return error;
    });
};

export default errorInterceptor;