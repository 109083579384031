import React from 'react';
import EventGroupHeader from "./EventGroupHeader";
import EventList from "./EventList";

export default function EventGroup(props) {
  const currentYear = new Date().getFullYear()

  return (
    <div className="timelineEventGroup">
      <EventGroupHeader
        label={props.eventGroup.label}
        isShow={ props.eventGroup.label.year == currentYear || props.eventGroup.events.length }
        isShowCurrentYearLabelBorder={  props.eventGroup.label.year == currentYear && !props.eventGroup.events.length }
      />
      <EventList events={props.eventGroup.events} />
    </div>
  )
}
