import React, { useRef } from "react";
import { Form, Field, Formik } from 'formik';
import TextInput from './CustomField/TextInput';

export default function InputStep(props) {
  const formState = useRef();
  const validateSchema = props.validate
  const initialValues = props.initialValues

  const handleTriggerNextStep = async(values) => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const storage = JSON.parse(window.lsSession.getItem(`chatbot-${props.formType}`))
    const newObj = {...storage, ...values}
    const storageStr = JSON.stringify(newObj)
    window.lsSession.setItem(`chatbot-${props.formType}`, storageStr)
    props.triggerNextStep({ trigger: props.trigger, value: values, replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="selectOption">
              <div className="titleSelect">{props.title}</div>
              <Field name={props.name} component={TextInput} numberonly={props.type == "text" ? "false" : "true"} type="text" placeholder="入力してください。" />
            </div>
            <button type="submit" className="btnSubmit">
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
