import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { LifePlansIcons } from "../../assets/icons";
import { LifePlansImages } from "../../assets/images";
import {convertToJapanCurrency} from "../../shared/constants/global";
import { Form, Field, Formik, ErrorMessage } from 'formik';
import { YupSchema } from '../../shared/constants/validators';
import CommaInput from '../../shared/components/comma-input-field';
import TextError from '../../shared/components/error-message';
import { simulationParams } from '../../shared/constants/simulator';
import { LifePlanService } from '../../core/services/life-plans';

const { lpsVsGraphPC, lpsVsGraphSP } = LifePlansImages;
const { iconBldgSmall, iconBldgMiddle, iconBldgBig, iconBldgApartSmall, iconBldgApartBig, iconBldgApartMiddle, iconArrowB} = LifePlansIcons;
export default function EffectOfPurchasingProfitablePropertiesContentWidget(props) {
    const { t } = useTranslation('eventCarModal');
    const { lifePlan, tabPurchaseIndex, loggedIn, totalIncoming, totalSpending } = props;
    const [spendingValue, setSpendingValue] = useState(0)
    const [incomingValue, setIncomingValue] = useState(0)
    const [incomingPercent, setIncomingPercent] = useState(0)
    const [spendingPercent, setSpendingPercent] = useState(0)
    const [initialValues, setInitialValues] = useState({
        ownResourceInp: 0,
        interestRateInp: 0,
        valueProperty: 10000000,
    });
    const formState = useRef();

    const validateSchema = useMemo(() => {
        return new YupSchema(t).aiSaleValidate;
    }, []);

    const [property, setProperty] = useState(1);

    const onSelectedProperty = (selected) => {
        setProperty(selected);
    };

    useEffect(() => {
        let switchValue;
        switch(tabPurchaseIndex){
            case 0:
                setProperty(1)
                switchValue = 10000000
                break
            case 1:
                setProperty(4)
                switchValue = 40000000
                break
            case 2:
                setProperty(7)
                switchValue = 100000000
                break
        }

        formState.current.setFieldValue("valueProperty", switchValue)
    }, [tabPurchaseIndex])

    const lifeEventCustomEventRebuy = (values, personAge) => {
        const valueProperty = +values.valueProperty || 10000000
        let property_type;
        switch(valueProperty) {
            case 10000000:
                property_type = 1
                break;
            case 30000000:
                property_type = 2
                break;
            case 50000000:
                property_type = 3
                break;
            case 40000000:
                property_type = 4
                break;
            case 90000000:
                property_type = 5
                break;
            case 150000000:
                property_type = 6
                break;
            case 100000000:
                property_type = 7
                break;
            case 200000000:
                property_type = 8
                break;
            case 300000000:
                property_type = 9
                break;
        }

        return {
            category: 6,
            event_type: 1,
            event_name: "buy_investment",
            cost_type: 3,
            running_cost: {
                cost_at_first: valueProperty,
                loop_year: null,
                start_age: +personAge,
                end_age: +personAge + 35 - 1,
                cost_for_one_time: 400000,
                how_many_years: 1,
                how_many_times: 1
            },
            re_buy: {
                real_estate_buy_age: +personAge,
                re_buy_model: property_type,
                own_fund: +values.ownResourceInp * 10000,
                interest_rate: +values.interestRateInp,
                repayment_period: 420
            },
            house_buy: {
                start_age: null,
                end_age: null,
                own_fund: 0,
                interest_rate: 0,
                cost_at_first: 0,
                cost_for_one_time: 0,
                how_many_years: null,
                how_many_times: 0
            },
            house_sell: {
                house_sale_age: null,
                house_sale_price: 0,
                month_rent: 0
            }
        }
    }

    const handleSubmitCalculateProperty = async (values) => {
        await formState.current.setFieldTouched();
        await formState.current.validateForm()

        if (!formState.current.isValid) return;

        try {
            const simulationData = {
                ...simulationParams(
                    lifePlan,
                )
            }
            const calculatePropertyData = {
                ...simulationData,
                life_events: simulationData.life_events.concat(lifeEventCustomEventRebuy(values, lifePlan.person_age))
            }
            const results = (await LifePlanService.registerSimulation(calculatePropertyData)).response.data.cumulative_balance;
            setSpendingValue(results.cumulative_total_expenditure)
            setIncomingValue(results.cumulative_total_income)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        setIncomingPercent(incomingValue/(incomingValue + spendingValue) * 100)
        setSpendingPercent(spendingValue/(incomingValue + spendingValue) * 100)
    }, [spendingValue, incomingValue])

    useEffect(() => {
        async function setData() {
            setSpendingValue(totalSpending)
            setIncomingValue(totalIncoming)
            setIncomingPercent(totalIncoming/(totalIncoming + spendingValue) * 100)
            setSpendingPercent(spendingValue/(totalIncoming + spendingValue) * 100)
        }

        setData()
    }, [totalIncoming, totalSpending])

    return (
        <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validateSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmitCalculateProperty}
        >
        {(formikProps) => {
            const { setFieldValue, handleSubmit } = formikProps;
            formState.current = formikProps;

            return (
            <Form onSubmit={handleSubmit}>
                <div className="tabContents revenueBtn active">
                    <div className={`tabPanel effectPropertyCont ${ tabPurchaseIndex === 0 && 'active'}`}>
                        <div className="radioImgBox">
                            <ul className="radioImgBtnList propertyTabBtnList nowrap rowList">
                                <li onClick={() => onSelectedProperty(1)}>
                                    <label className={ property === 1 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={10000000} name="valueProperty" />
                                        <img src={iconBldgSmall} />
                                        <span><span className="font20">郊外1R 築30年</span><span className="font24">1,000万円</span></span>
                                    </label>
                                </li>
                                <li onClick={() => onSelectedProperty(2)}>
                                    <label className={ property === 2 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={30000000} name="valueProperty" />
                                        <img src={iconBldgMiddle} />
                                        <span><span className="font20">都内1LDK 築20年</span><span className="font24">3,000万円</span></span>
                                    </label>
                                </li>
                                <li onClick={() => onSelectedProperty(3)}>
                                    <label className={ property === 3 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={50000000} name="valueProperty" />
                                        <img src={iconBldgBig} />
                                        <span><span className="font20">都心1LDK 築浅</span><span className="font24">5,000万円</span></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`tabPanel apart effectPropertyCont ${ tabPurchaseIndex === 1 && 'active'}`}>
                        <div className="radioImgBox">
                            <ul className="radioImgBtnList propertyTabBtnList nowrap rowList">
                                <li onClick={() => onSelectedProperty(4)}>
                                    <label className={ property === 4 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={40000000} name="valueProperty" />
                                        <img src={iconBldgApartSmall} />
                                        <span><span className="font20">郊外1K×6戸 築30年</span><span className="font24">4,000万円</span></span>
                                    </label>
                                </li>
                                <li onClick={() => onSelectedProperty(5)}>
                                    <label className={ property === 5 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={90000000} name="valueProperty" />
                                        <img src={iconBldgApartMiddle} />
                                        <span><span className="font20">都内1R×8戸 築20年</span><span className="font24">9,000万円</span></span>
                                    </label>
                                </li>
                                <li onClick={() => onSelectedProperty(6)}>
                                    <label className={ property === 6 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={150000000} name="valueProperty" />
                                        <img src={iconBldgApartBig} />
                                        <span><span className="font20">都心1R×10戸 築浅</span><span className="font24">1億5,000万円</span></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`tabPanel effectPropertyCont ${ tabPurchaseIndex === 2 && 'active'}`}>
                        <div className="radioImgBox">
                            <ul className="radioImgBtnList propertyTabBtnList nowrap rowList">
                                <li onClick={() => onSelectedProperty(7)}>
                                    <label className={ property === 7 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={100000000} name="valueProperty" />
                                        <img src={iconBldgSmall} />
                                        <span><span className="font20">郊外1K×10戸 築30年</span><span className="font24">1億円</span></span>
                                    </label>
                                </li>
                                <li onClick={() => onSelectedProperty(8)}>
                                    <label className={ property === 8 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={200000000} name="valueProperty" />
                                        <img src={iconBldgMiddle} />
                                        <span><span className="font20">都内1R×12戸 築20年</span><span className="font24">2億円</span></span>
                                    </label>
                                </li>
                                <li onClick={() => onSelectedProperty(9)}>
                                    <label className={ property === 9 ? 'radioBtn checked' : 'radioBtn' }>
                                        <Field type="radio" value={300000000} name="valueProperty" />
                                        <img src={iconBldgBig} />
                                        <span><span className="font20">都心1K×18戸築浅</span><span className="font24">3億円</span></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <dl className="input">
                        <div className="customErrorMsgContainer">
                            <ErrorMessage name="ownResourceInp" render={TextError}/>
                            <div className="customErrorMsg">
                                <dt>自己資金</dt>
                                <dd>
                                    <Field component={CommaInput} type="text" placeholder="1000" name="ownResourceInp" hideerrormsg="true" secondLabel={"万円"} setFieldValue={setFieldValue} />
                                </dd>
                            </div>

                        </div>
                        <div className="customErrorMsgContainer">
                            <ErrorMessage name="interestRateInp" render={TextError}/>
                            <div className="customErrorMsg">
                                <dt>金利</dt>
                                <dd>
                                    <Field component={CommaInput} type="text" decimal={2}  placeholder="0.1" name="interestRateInp" hideerrormsg="true" setFieldValue={setFieldValue} />&#37;
                                </dd>
                            </div>
                        </div>
                    </dl>
                    <div className="reflection">
                        <button className="linkBtn" type="submit"><span><img src={iconArrowB} alt="" width="14" />効果を反映</span></button>
                    </div>
                    <div className={`resultGraphTxt marB80 ${loggedIn ? '' : 'blurCont'}`}>
                        <div className="vsGraph vsGraphContainer">
                        <div className="incomeRatio" style={{width: `${incomingPercent}%`}}>
                            <div className="incomeRatioTitle">収入</div>
                            <div className="incomeRatioMoney">{convertToJapanCurrency(incomingValue)}</div>
                        </div>
                        <div className="spendingRatio" style={{width: `${spendingPercent}%`}}>
                            <div className="spendingRatioTitle">支出</div>
                            <div className="spendingRatioMoney">{convertToJapanCurrency(spendingValue)}</div>
                        </div>
                        </div>
                        <p>収支合計<strong>{convertToJapanCurrency(incomingValue - spendingValue)}</strong></p>
                    </div>
                </div>
            </Form>
            )
        }}
        </Formik>
    )

}
