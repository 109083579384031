import styled from 'styled-components';
import defaultTheme from '../theme';

const Header = styled.div`
`;

Header.defaultProps = {
  theme: defaultTheme
};

export default Header;
