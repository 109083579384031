import React from 'react';
import NumberFormat from "react-number-format";

export default function InputTextCustom(props) {
  const {secondLabel, setFieldValue, ...inputField} = props;
  const { field, form, type, placeholder, disabled, label, decimal } = inputField;

  const name = field.name;
  const errors = form.errors;
  const touched = form.touched;

  const FIRST_DOT_NOTHING_BEFORE = /^\.+/
  const showError = errors[name] && touched[name]

  return <React.Fragment>
    {showError && <div className="error_msg d-block">{errors[name]}</div>}
    <div className="aisC">
      <span>{label}</span>
      <span className="itemRowListInput">
      <NumberFormat
        {...inputField}
        id={name}
        value={field.value}
        type={type}
        placeholder={placeholder || ''}
        disabled={disabled}
        thousandSeparator={true}
        inputMode="numeric"
        allowNegative={false}
        decimalScale={decimal || 0}
        onInput={(e) => {
          if (decimal && e.target.value !== '') {
            e.target.value = e.target.value.replace(FIRST_DOT_NOTHING_BEFORE, '')
          }
        }}
        onChange={(e) => {
          setFieldValue(
            name,
            e.target.value.replace(/,/g, "")
          );
        }}
      />
        <span className="formUnit">{secondLabel}</span>
      </span>
    </div>
  </React.Fragment>
}
