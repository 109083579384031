import React from "react";


export default function ResultAnalysisRow(props) {
  const {loggedIn, incomeLabel, incomeValue} = props;

  return (
    <>
      <tr>
          <th>{incomeLabel}</th>
          <td className={loggedIn ? '' : 'blurCont'}>{incomeValue}</td>
      </tr>
    </>
  );
}
