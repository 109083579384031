import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/lifePlan';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';
import icoHumanL from 'images/mascot/mascot4.png';


function CanNotAddEventModal(props) {
  const { t } = useTranslation('saveModal')
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_CANNOT_ADD_EVENT}>
    <div className="popupBox">
      <div className="popupInner alsC">
        <div className="favoritePopup favoritePopupCustom">
          <div className="wid150"><img src={icoHumanL} className="" /></div>
          <div className="dF aisC flDCl" >
            <p>{t('cannotAdd')}</p>
            <button type="button" className="btnSty-blue btnClose" onClick={handleClose}>{t('ok')}</button>
          </div>
        </div>
      </div>
    </div>
  </LifePlanModal>;
}

export default React.memo(CanNotAddEventModal);
