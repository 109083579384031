import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, openSubModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import { generateYearsByMemberAge, ModalName, ValidateField } from '../../shared/constants/global';
import LifePlanModal from '../../shared/components/modal';
import { LifePlansImages } from '../../assets/images';
import InputText from '../../shared/components/input-field';
import CommaInput from '../../shared/components/comma-input-field';
import SelectFieldSecond from '../../shared/components/select-field-second';
import { YupSchema } from '../../shared/constants/validators/schema';
import { AffectLoanPeriod } from '../../core/classes/enum';
import { calcLoanPreiod } from '../../shared/utils';
import { cloneDeep } from "lodash";

const {imgRealestate1, imgRealestate2, imgRealestate3, imgRealestate4, imgRealestate5, imgRealestate6, imgRealestate7, imgRealestate8, imgRealestate9, imgRealestate10, imgRealestate11, imgRealestate12} = LifePlansImages;
const arrImages = [imgRealestate1, imgRealestate2, imgRealestate3, imgRealestate4, imgRealestate5, imgRealestate6, imgRealestate7, imgRealestate8, imgRealestate9, imgRealestate10, imgRealestate11, imgRealestate12];

export default function EventPurchaseHomeModal(props) {
  const { t } = useTranslation('eventPurchaseHomeModal');
  const tGlobal = useTranslation('global').t;
  const [checkShow, setCheckShow] = useState(false);
  const dispatch = useDispatch();
  const { lifeEvent } = props;

  const formState = useRef();

  const validateSchema = useMemo(() => {
    return new YupSchema(t).eventPurchaseHomeModal;
  }, []);

  const [initialValues, setInitialValues] = useState(() => {
    const values = calcLoanPreiod(props, AffectLoanPeriod.HOUSE_BUY);
    return values;
  });

  const handleClose = () => {
    dispatch(closeModal());
  }

  const getBuyPropertyName = (buy_property, data) => {
    switch (buy_property) {
      case 1:
        return "注文住宅（土地代を含める）"
      case 2:
        return "注文住宅（土地代を含まない）"
      case 3:
        return "二世帯住宅（一部共有型）"
      case 4:
        return "二世帯住宅（完全分離型）"
      case 5:
        return "建売住宅"
      case 6:
        return "中古・戸建"
      case 7:
        return "中古・マンション（コンパクトタイプ）"
      case 8:
        return "中古・マンション（ファミリータイプ）"
      case 9:
        return "新築・マンション（コンパクトタイプ）"
      case 10:
        return "新築・マンション（ファミリータイプ）"
      case 11:
        return "建て替え"
      case 12:
        return data.house_buy?.custom || ""
      default:
        return ""
    }
  }

  const prepareData = (values) => {
    const tempData = cloneDeep(values);
    const params = {
      ...tempData,
      end_year: (+tempData.year + +tempData.house_buy?.loan_period - 1) || 0,
      house_buy: {
        ...tempData.house_buy,
        buy_property_name: getBuyPropertyName(+tempData.house_buy?.buy_property, tempData)
      }
    }
    delete params.house_buy.loan_period;

    return params;
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      const data = prepareData(values);
      dispatch(saveSelectedLifeEvent(data));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)
  const currentAge = useSelector(state => state.life_plan.person_age)

  const renderYearFromNow = (year) => {
    if (subYear(year) === 0) {
      return `${year}年`
    } else {
      return t('renderYearFromNow', {subYear: subYear(year), yearFromNow: year})
    }
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age - currentAge;
  }

  const dataEndYear = useMemo(() => {
    let result = []
    for (let i = 1; i <= ValidateField.Global.MIN_LOAN_YEAR; i++) {
      result.push(i)
    }
    return result;
  }, []);

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  useEffect(() => {
    const values = calcLoanPreiod(props, AffectLoanPeriod.HOUSE_BUY);
    setInitialValues(values);
    setCheckShow(values.house_buy?.buy_property == 12)
  }, [props.lifeEvent])

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_PURCHASE_HOME} >
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>{t('label')}</h2>
                  <div className="popupContents">
                    <div className="popupContentsCover">
                      <div id="checkshow1" className="whiteBox checkShowToggle radioImgBox pad24" >
                        <ul className="radioImgBtnList rowList">
                          {[...Array(12)].map((e, i) => {
                            return <li key={i}><label className={`radioBtn ${i + 1 == values.house_buy.buy_property ? "checked" : ""}`}>
                                    <Field onClick={() => setCheckShow(i == 11)}
                                      type="radio" value={i + 1} name="house_buy.buy_property" className="toggle_hide" />
                                    <img src={arrImages[i]} /><span>{t(`options.${i}`)}<br className="br_sp"/>{t(`cost.${i}`)}</span>
                                  </label></li>
                          })}
                        </ul>
                      </div>
                    </div>
                    <dl className="formSty lifeplanImgBtnBox marB40">
                      <dt id="checkShow1-ttl" className={`checkShow1 ${!checkShow ? 'hiddenTtl' : ''}`}>{t('custom')}</dt>
                      <dd id="checkShow1-item" className={`checkShow1 formTtlSty nowrap ${!checkShow ? 'hiddenItem' : ''}`}>
                        <div className="maxWidth400">
                          <Field className="toggle-item" type="text" component={InputText} name="house_buy.custom" placeholder={t('customPlaceholder')} disabled={!checkShow} dF={true} />
                        </div>
                        <dl className="formTtlSty nowrap wid380 marL40">
                          <dt>{t('amount')}</dt>
                          <dd>
                            <Field className="wid120" placeholder="500" type="text" component={CommaInput} name="house_buy.buy_cost" disabled={!checkShow} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          </dd>
                        </dl>
                      </dd>
                      <dt>{t('purchaseTime')}</dt>
                      <dd>
                        <Field component={SelectFieldSecond} width="wid200" name="year" options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                      </dd>
                      <dt>{t('ownResources')}</dt>
                      <dd>
                        <Field className="wid120" type="text" component={CommaInput} name="house_buy.own_fund" secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                      </dd>
                      <dt>{t('loanInterestRate')}</dt>
                      <dd>
                        <Field className="wid120" type="text" decimal={2} component={CommaInput} name="house_buy.loan_rate" secondLabel={"%"} setFieldValue={setFieldValue} />
                      </dd>
                      <dt>{t('loanPeriod')}</dt>
                      <dd className="d-flex">
                        <div className="selectStyBox wid200">
                          <Field component={SelectFieldSecond} name="house_buy.loan_period" options={dataEndYear} />
                        </div>
                        <span className="formUnit">{tGlobal('yearly')}</span>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList col2">
                        <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                        <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                        { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>;
}
