export const API_URL = Object.freeze({
    BASE_URL: "",
    BASE_HREF: "v1/",

    REGISTER: "/life_plans",
    REGISTER_SIMULATION: "/life_plans/simulation",
    PROPERTY_ASSESSMENT_AND_REGISTER_RESULT: "/life_plans/assessment_register_results",
    INPUT_CONTACT_INFO: "/life_plans/input_contact_info",

    PREFECTURE: "/prefectures",
    CITY: "/cities",
    REGION: "/regions",
    LINE: "/lines",
    STATION: "/stations",

    SEARCH_ADDRESS_BY_ZIPCODE: `/addresses/search?code=`
});
