import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import icoHumanL from 'images/mascot/mascot2.png';
import { useDispatch, useSelector } from 'react-redux';
import SelectFieldSecond from '../../shared/components/select-field-second';
import { openModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import { ModalName } from '../../shared/constants/global';
import LifePlanModal from '../../shared/components/modal';
import { yearUntilOneHundred } from '../../shared/utils';

export default function Sale2Modal(props) {
  const { t } = useTranslation('sale2Modal');
  const tGlobal = useTranslation('global').t;
  const pattern = /\B(?=(\d{3})+(?!\d))/g;

  const dispatch = useDispatch();
  const person_age = useSelector(state => state.life_plan.person_age || 0);
  const modalName = useSelector(state => state.modalOpenName)

  const formState = useRef();
  const [initialValues, setInitialValues] = useState({ ...props.lifeEvent, re_sell: { ...props.lifeEvent.re_sell } });
  const [sellInvesmentValues, setSellInvesmentValues] = useState();

  const yearUntil100 = useMemo(() => {
    return yearUntilOneHundred(person_age).slice(0, 51);
  }, [person_age]);

  const handleSubmit = async () => {
    const {prediction_result, ...data} = sellInvesmentValues;
    const values = {
      year: formState.current.values.year,
      re_sell: {
        property_id: sellInvesmentValues.prediction_result.id || null,
        ...data
      },
      prediction_result: prediction_result
    }

    window.lsSession.removeItem('sell_investment');
    dispatch(saveSelectedLifeEvent(values));
  }

  const handleClose = () => {
    dispatch(openModal(ModalName.LIFEPLAN_SALE))
  }

  function convertNumber(val, fix = 0) {
    return (val / 10000).toFixed(fix).replace(pattern, ",")
  }

  function convertNumber2(val, fix = 0) {
    return val.toFixed(fix).replace(pattern, ",")
  }

  function convertOku(val) {
    if (val >= 100000000) {
      if (val % 100000000 == 0) {
        return `${convertNumber2(val / 100000000)}億`
      } else {
        return `${convertNumber2(Math.floor(val / 100000000))}億${convertNumber(val % 100000000)}万円`
      }
    } else {
      return `${convertNumber(val)}万円`
    }
  }

  const AICalculatorByYear = (year) => {
    const index = yearUntil100.indexOf(parseInt(year))
    if (typeof(sellInvesmentValues) !== typeof(undefined) && sellInvesmentValues !== null) {
      return `${convertOku(sellInvesmentValues.prediction_result.downside_market_sell_prices[index])}～${convertOku(sellInvesmentValues.prediction_result.upside_market_sell_prices[index])}`
    }
  }

  const renderYearFromNow = (year) => {
    if (year == new Date().getFullYear()) {
      return `${year}年`
    } else {
      return `${year - new Date().getFullYear()}年後（${year}年）`
    }
  }

  useEffect(() => {
    try {
      const sellInvesmentStorage = JSON.parse(window.lsSession.getItem('sell_investment'))
      setSellInvesmentValues(sellInvesmentStorage)
    } catch(e) {}
  }, [modalName])

  useEffect(() => {
    const values = { ...props.lifeEvent, re_sell: { ...props.lifeEvent.re_sell } }
    setInitialValues(values)
  }, [props.lifeEvent]);

  return <LifePlanModal name={ModalName.LIFEPLAN_SALE_2} >
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, isValid } = formikProps;
        formState.current = formikProps;

        return (
          <Form>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>{t('label')}</h2>
                  <div className="popupContents">
                    <div className="popupContentsCover figBox">

                      <figure><img src={icoHumanL} className="step_image" /></figure>
                      <div className="popupContentsRight">
                        <div className="popupContentsTxt pad0">
                          <h3 className="bgGradGray colBlu alC pad20 marB0" dangerouslySetInnerHTML={{ __html: t('saleTimeNotice') }}></h3>
                          <div className="popupContentTxtInner">
                            <div className="dF aisC jcC marB32">
                              <p className="font24 fontWB marR30 txtSeason">{t('expectSaleTime')}</p>
                              <div className="selectStyBox">
                                <Field type="text" name="year" component={SelectFieldSecond} width="wid200" options={yearUntil100} display_options={renderYearFromNow} />
                              </div>
                            </div>
                            <div className="aiAssesBox gradBluBg gradBluBg4 scrollInAnim isScroll">
                              <div className="whiteBox">AI査定は<span>{AICalculatorByYear(values.year)}</span>です</div>
                            </div>
                            <p className="marT16 alC">{t('notice')}</p>
                          </div>
                        </div>
                        <div className="popupBtnBox">
                          <ul className="btnList col2">
                            <li><button type="button" className="btnSty-back" onClick={handleClose}>{t('close')}</button></li>
                            <li><button href="#" className="btnSty-send" onClick={handleSubmit}>{tGlobal('send')}</button></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
