import * as Yup from 'yup';
import { ValidateField } from '../global';

export class YupSchema {
    // chưa biết size dự án như nào nên em tạm để chung nhé!!!

    #translate; // private field

    constructor(translate) {
        this.#translate = translate;
    }

    get familyModal() {
      return Yup.object().shape({
        person_age: Yup.number()
                    .typeError(this.#translate('validate.required'))
                    .required(this.#translate('validate.required'))
                    .max(ValidateField.FamilyModal.MAX_AGE, this.#translate('validate.range'))
                    .min(ValidateField.FamilyModal.MIN_AGE, this.#translate('validate.range')),
        partner_age: Yup.number()
                      .nullable(true)
                      .when('partner', {
                      is: (partner) => partner === 2,
                      then: Yup.number()
                              .typeError(this.#translate('validate.required'))
                              .required(this.#translate('validate.required'))
                              .max(ValidateField.FamilyModal.MAX_AGE, this.#translate('validate.range'))
                              .min(ValidateField.FamilyModal.MIN_AGE, this.#translate('validate.range'))
                    }),
        child: Yup.array().of(
          Yup.object().shape({
            age: Yup.number()
              .typeError(this.#translate('validate.required'))
              .required(this.#translate('validate.required'))
              .max(ValidateField.FamilyModal.MAX_AGE, this.#translate('validate.range_child'))
              .min(ValidateField.FamilyModal.CHILD_MIN_AGE, this.#translate('validate.range_child'))
          })
        )
      })
    }

    get inputSpouseModal() {
      return Yup.object().shape({
        person_income: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        partner_income: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        person_retirement_money: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        partner_retirement_money: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        other_income: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        saving_amount: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        reserve_monthly: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range')),
        future_sum: Yup.number()
                      .nullable(true)
                      .max(ValidateField.InputSpouseModal.MAX_MONEY, this.#translate('validate.range'))
                      .min(ValidateField.InputSpouseModal.MIN_MONEY, this.#translate('validate.range'))
      });
    }

    get inputSpendingModal() {
      return Yup.object().shape({
        rent: Yup.number()
                .nullable(true)
                .when('residence_type', {
                  is: (residence_type) => residence_type === 2,
                  then: Yup.number()
                    .typeError(this.#translate('validate.range'))
                    .max(ValidateField.InputSpendingModal.MAX_MONEY, this.#translate('validate.range'))
                    .min(ValidateField.InputSpendingModal.MIN_MONEY, this.#translate('validate.range')),
                }),
        term_insurance_monthly: Yup.number()
                                  .nullable(true)
                                  .max(ValidateField.InputSpendingModal.MAX_MONEY, this.#translate('validate.range'))
                                  .min(ValidateField.InputSpendingModal.MIN_MONEY, this.#translate('validate.range')),
        housing_monthly_repayment: Yup.number()
                                  .nullable(true)
                                  .when(['residence_type', 'housing_loan'], {
                                    is: (residence_type, housing_loan) => residence_type === 1 && housing_loan === 1,
                                    then: Yup.number()
                                      .typeError(this.#translate('validate.range'))
                                      .max(ValidateField.InputSpendingModal.MAX_MONEY, this.#translate('validate.range'))
                                      .min(ValidateField.InputSpendingModal.MIN_MONEY, this.#translate('validate.range'))
                                  }),
        housing_loan_balance: Yup.number()
                                  .nullable(true)
                                  .when(['residence_type', 'housing_loan'], {
                                    is: (residence_type, housing_loan) => residence_type === 1 && housing_loan === 1,
                                    then: Yup.number()
                                      .typeError(this.#translate('validate.range'))
                                      .max(ValidateField.InputSpendingModal.MAX_MONEY, this.#translate('validate.range'))
                                      .min(ValidateField.InputSpendingModal.MIN_MONEY, this.#translate('validate.range'))
                                  }),
        housing_bonus_repayment: Yup.number()
                                  .nullable(true)
                                  .when(['residence_type', 'housing_loan'], {
                                    is: (residence_type, housing_loan) => residence_type === 1 && housing_loan === 1,
                                    then: Yup.number()
                                      .typeError(this.#translate('validate.range'))
                                      .max(ValidateField.InputSpendingModal.MAX_MONEY, this.#translate('validate.range'))
                                      .min(ValidateField.InputSpendingModal.MIN_MONEY, this.#translate('validate.range'))
                                  }),
      });
    }

    get eventPurcharseRealEsate() {
      return Yup.object({
        re_buy: Yup.object({
          own_fund: Yup.number()
            .max(ValidateField.EventPurchaseRealesateModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventPurchaseRealesateModal.MIN_MONEY, this.#translate('validate.range_money')),
          loan_rate: Yup.number()
            .max(ValidateField.EventPurchaseRealesateModal.MAX_RATE, this.#translate('validate.range_rate'))
            .min(ValidateField.EventPurchaseRealesateModal.MIN_RATE, this.#translate('validate.range_rate')),
        })
      });
    }

    get buildingTab() {
      return Yup.object().shape({
        pref_code: Yup.string().required(this.#translate('validate.pref_code_required')),
        city_code: Yup.string().required(this.#translate('validate.city_code_required')),
        region_code: Yup.string().required(this.#translate('validate.town_required')),
        after_town: Yup.string().required(this.#translate('validate.after_town_required')).max(128, this.#translate('validate.after_town_max')),
        line_code: Yup.string().required(this.#translate('validate.line_code_required')),
        station_code: Yup.string().required(this.#translate('validate.station_code_required')),
        walking_minutes: Yup.number().required(this.#translate('validate.walking_minutes_required'))
                          .min(0, this.#translate('validate.walking_minutes_range'))
                          .max(100, this.#translate('validate.walking_minutes_range')),
        building_structure: Yup.string().required(this.#translate('validate.building_structure_required')),
        built_date: Yup.number().required(this.#translate('validate.built_date_required')),
        walking_minutes: Yup.number().required(this.#translate('validate.walking_minutes_required'))
                          .min(0, this.#translate('validate.walking_minutes_range'))
                          .max(100, this.#translate('validate.walking_minutes_range')),
        number_of_floors: Yup.number().required(this.#translate('validate.number_of_floors_required'))
                          .min(0, this.#translate('validate.number_of_floors_range'))
                          .max(100, this.#translate('validate.number_of_floors_range')),
        total_floor_space: Yup.number().required(this.#translate('validate.total_floor_space_required'))
                          .min(0, this.#translate('validate.total_floor_space_range'))
                          .max(10000, this.#translate('validate.total_floor_space_range')),
        number_of_houses: Yup.number().required(this.#translate('validate.number_of_houses_required'))
                          .min(0, this.#translate('validate.number_of_houses_range'))
                          .max(100, this.#translate('validate.number_of_houses_range')),
        purchase_year: Yup.number().required(this.#translate('validate.purchase_year_required'))
                          .min(Yup.ref('built_date'), this.#translate('validate.gt_built_date')),
        investment_monthly_repayment: Yup.number()
                                        .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                                        .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        investment_bonus_repayment: Yup.number()
                                        .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                                        .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        investment_loan_balance: Yup.number()
                                  .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                                  .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        operating_costs: Yup.number()
                          .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                          .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        renovation_reserves: Yup.number()
                              .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                              .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        property_tax: Yup.number()
                        .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                        .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
      });
    }

    get classTab() {
      return Yup.object().shape({
        pref_code: Yup.string().required(this.#translate('validate.pref_code_required')),
        city_code: Yup.string().required(this.#translate('validate.city_code_required')),
        region_code: Yup.string().required(this.#translate('validate.town_required')),
        after_town: Yup.string().required(this.#translate('validate.after_town_required')).max(128, this.#translate('validate.after_town_max')),
        line_code: Yup.string().required(this.#translate('validate.line_code_required')),
        station_code: Yup.string().required(this.#translate('validate.station_code_required')),
        walking_minutes: Yup.number().required(this.#translate('validate.walking_minutes_required'))
                          .min(0, this.#translate('validate.walking_minutes_range'))
                          .max(100, this.#translate('validate.walking_minutes_range')),
        building_structure: Yup.string().required(this.#translate('validate.building_structure_required')),
        built_date: Yup.number().required(this.#translate('validate.built_date_required')),
        walking_minutes: Yup.number().required(this.#translate('validate.walking_minutes_required'))
                          .min(0, this.#translate('validate.walking_minutes_range'))
                          .max(100, this.#translate('validate.walking_minutes_range')),
        occupation_area: Yup.number().required(this.#translate('validate.occupation_area_required'))
                          .min(0, this.#translate('validate.occupation_area_range'))
                          .max(1000, this.#translate('validate.occupation_area_range')),
        floor_located: Yup.number().required(this.#translate('validate.floor_located_required'))
                          .min(0, this.#translate('validate.floor_located_range'))
                          .max(100, this.#translate('validate.floor_located_range')),
        purchase_year: Yup.number().required(this.#translate('validate.purchase_year_required'))
                          .min(Yup.ref('built_date'), this.#translate('validate.gt_built_date')),
        investment_monthly_repayment: Yup.number()
                                        .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                                        .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        investment_bonus_repayment: Yup.number()
                                        .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                                        .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        investment_loan_balance: Yup.number()
                                  .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                                  .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        operating_costs: Yup.number()
                          .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                          .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        renovation_reserves: Yup.number()
                              .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                              .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
        property_tax: Yup.number()
                        .max(ValidateField.EventSaleModal.MAX_MONEY, this.#translate('validate.range'))
                        .min(ValidateField.EventSaleModal.MIN_MONEY, this.#translate('validate.range')),
      });
    }

    get eventPurchaseHomeModal() {
      return Yup.object({
        house_buy: Yup.object({
          buy_cost: Yup.number()
            .nullable(true)
            .max(ValidateField.EventPurchaseHomeModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventPurchaseHomeModal.MIN_MONEY, this.#translate('validate.range_money')),
          own_fund: Yup.number()
            .max(ValidateField.EventPurchaseHomeModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventPurchaseHomeModal.MIN_MONEY, this.#translate('validate.range_money')),
          loan_rate: Yup.number()
            .max(ValidateField.EventPurchaseHomeModal.MAX_RATE, this.#translate('validate.range_rate'))
            .min(ValidateField.EventPurchaseHomeModal.MIN_RATE, this.#translate('validate.range_rate')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventPurchaseHomeModal.CUSTOM_FORM, this.#translate('validate.custom_form'))
        })
      });
    }

    get eventSaleHomeModal() {
      return Yup.object({
        house_sell: Yup.object({
          sell_price: Yup.number()
            .max(ValidateField.EventSaleHomeModal.MAX_MONEY, this.#translate('validate.range'))
            .min(ValidateField.EventSaleHomeModal.MIN_MONEY, this.#translate('validate.range')),
          rent: Yup.number()
            .max(ValidateField.EventSaleHomeModal.MAX_MONEY, this.#translate('validate.range'))
            .min(ValidateField.EventSaleHomeModal.MIN_MONEY, this.#translate('validate.range')),
        })
      });
    }

    get eventTravelModal() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventTravelModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventTravelModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventTravelModal.CUSTOM_FORM, this.#translate('validate.custom_form'))
        })
      });
    }

    get eventCarModal() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventCarModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventCarModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventCarModal.CUSTOM_FORM, this.#translate('validate.custom_form')),
          cost_at_first: Yup.number()
            .nullable(true)
            .max(ValidateField.EventCarModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventCarModal.MIN_MONEY, this.#translate('validate.range_money')),
        })
      });
    }

    get aiSaleValidate() {
      return Yup.object({
        ownResourceInp: Yup.number()
          .max(ValidateField.EventPurchaseRealesateModal.MAX_MONEY, this.#translate('validate.range_money'))
          .min(ValidateField.EventPurchaseRealesateModal.MIN_MONEY, this.#translate('validate.range_money')),
        interestRateInp: Yup.number()
          .max(ValidateField.EventPurchaseRealesateModal.MAX_RATE, this.#translate('validate.range_rate'))
          .min(ValidateField.EventPurchaseRealesateModal.MIN_RATE, this.#translate('validate.range_rate')),
      })
    }

    get eventHobbyModal() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventHobbyModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventHobbyModal.MIN_MONEY, this.#translate('validate.range_money')),
          cost_at_first: Yup.number()
            .nullable(true)
            .max(ValidateField.EventHobbyModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventHobbyModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventHobbyModal.CUSTOM_FORM, this.#translate('validate.custom_form'))
        })
      })
    }

    get eventEatout() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventEatoutModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventEatoutModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventEatoutModal.CUSTOM_FORM, this.#translate('validate.custom_form'))
        })
      })
    }

    get eventPetModal() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventPetModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventPetModal.MIN_MONEY, this.#translate('validate.range_money')),
          cost_at_first: Yup.number()
            .nullable(true)
            .max(ValidateField.EventPetModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventPetModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventPetModal.CUSTOM_FORM, this.#translate('validate.custom_form'))
        })
      })
    }

    get eventFamilyModal() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventFamilyModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventFamilyModal.MIN_MONEY, this.#translate('validate.range_money')),
          cost_at_first: Yup.number()
            .nullable(true)
            .max(ValidateField.EventFamilyModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventFamilyModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventFamilyModal.CUSTOM_FORM, this.#translate('validate.custom_form'))
        })
      })
    }

    get eventSecondCarrierModal() {
      return Yup.object({
        other: Yup.object({
          price: Yup.number()
            .nullable(true)
            .max(ValidateField.EventSecondcarrierModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventSecondcarrierModal.MIN_MONEY, this.#translate('validate.range_money')),
          custom: Yup.string()
            .nullable(true)
            .max(ValidateField.EventSecondcarrierModal.CUSTOM_FORM, this.#translate('validate.custom_form')),
          cost_at_first: Yup.number()
            .nullable(true)
            .max(ValidateField.EventFamilyModal.MAX_MONEY, this.#translate('validate.range_money'))
            .min(ValidateField.EventFamilyModal.MIN_MONEY, this.#translate('validate.range_money')),
        })
      })
    }
}
