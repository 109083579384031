import React from 'react';
import { generateYearsByMemberAge } from '../../shared/constants/global';
import Axis from './Axis';
import Content from './Content';

export default function TimelineHorizontalWidget(props) {
    const labels = generateYearsByMemberAge({forVerticalTimeLine: true}).map((y) => { return {year: y.year, age: y.age} })

    return (
      <React.Fragment>
        <div className="timelineHorizontal">
          <Axis />
          <Content labels={labels} events={props.events} />
        </div>
      </React.Fragment>
    )
}
