import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { setLifeEventSelected, showSelectedModel, setIsDragging as setIsGlobalDragging, saveSelectedLifeEvent, openModal } from '../../redux/lifePlan';
import { ModalName } from '../../shared/constants/global';
import EventGroupHeader from "./EventGroupHeader";
import EventList from "./EventList";

export default function EventGroup(props) {
  const [isHovered, setIsHovered] = useState(false)
  const lifeEventSelected = useSelector(state => state.lifeEventSelected)
  const storeLifeEvents = useSelector(state => state.life_plan.life_event)
  const age = useSelector(state => state.life_plan.person_age)
  const dispatch = useDispatch()
  const currentYear = new Date().getFullYear()

  const dragOverHandle = (e) => {
    e.preventDefault()
  }

  const dragEnteredHandle = () => {
    // evt.preventDefault(); // needed for IE
    setIsHovered(true)
  }

  const dragLeaveHandle = () => {
    setIsHovered(false)
  }

  const dropHandle = () => {
    let MAX_AGE = 99
    let selectedYear = currentYear
    const arrayEventTypes = [5, 6, 7, 8, 9, 10, 11]

    if (props.eventGroup.label.age > MAX_AGE) {
      selectedYear = props.eventGroup.label.year - (props.eventGroup.label.age - MAX_AGE)
    } else if (!props.eventGroup.label.year || props.eventGroup.label.year > currentYear) {
      selectedYear = props.eventGroup.label.year
    }

    const params = {year: selectedYear}

    let event = {...lifeEventSelected, ...params}
    setIsHovered(false)
    dispatch(setIsGlobalDragging(false))

    if (event.event_type == 9) {
      event = {...event, other: {...event.other, time: selectedYear}
      }
    }

    if (event.uuid) {
      // update event
      if (arrayEventTypes.includes(event.event_type)) {
        const parentEvent = storeLifeEvents.find((e) => e.uuid === event.uuid)
        var drag_years = selectedYear - parentEvent.year
        event.end_year = +parentEvent.end_year + drag_years
        if (event.end_year > currentYear - +age + MAX_AGE) {
          event.end_year = currentYear - +age + MAX_AGE
        }
      }
      dispatch(setLifeEventSelected(event))
      dispatch(saveSelectedLifeEvent())
    } else {
      // new event
      if (arrayEventTypes.includes(event.event_type)) {
        if (selectedYear > event.end_year) {
          event.end_year = selectedYear
        }
      }
      dispatch(setLifeEventSelected(event))
      var mainEvents = props.eventGroup.events.filter(e => !e.isClone)
      if (mainEvents.length >= 8) {
        dispatch(openModal(ModalName.LIFEPLAN_CANNOT_ADD_EVENT))
        return;
      }
      dispatch(showSelectedModel())
    }
  }

  return (
    <React.Fragment>
      <div
        className={["timelineEventGroup", isHovered && "hovered"].filter(e => !!e).join(' ')}
        onDragOver={dragOverHandle}
        onDragEnter={dragEnteredHandle}
        onDragLeave={dragLeaveHandle}
        onDrop={dropHandle}
      >
        <EventGroupHeader label={props.eventGroup.label} isShow={props.eventGroup.label.year == currentYear || props.eventGroup.events.length} />

        {props.eventGroup.label.year === currentYear &&
          <div
            className="timelineCurrentYearLabel"
            style={{borderRadius: props.eventGroup.events.length ? "unset" : "0 0 8px 8px"}}>
            現在
          </div>
        }
        <EventList events={props.eventGroup.events} />
      </div>
    </React.Fragment>
  )
}
