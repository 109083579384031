import React from 'react';
import BgPagination from "./BgPagination";

export default function Content(props) {
  return (
    <React.Fragment>
      <div className="timelineContent">
        <BgPagination labels={props.labels} events={props.events} />
      </div>
    </React.Fragment>
  )
}
