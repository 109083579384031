
export function disabledScrollBodyWhenShowModal() {
    if(document.getElementsByClassName("tutorial").length == 0){
        document.documentElement.style.overflowY = 'hidden';
        document.body.scroll = "no";
    }else{
        window.scrollTo(0, 0);
        document.documentElement.style.overflowY = 'auto';
    };

}

export function enabledScrollWhenCloseModal() {
    document.documentElement.style.overflowY = 'scroll';
    document.body.scroll = "yes";
}
