import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LifePlanSubModal from '../../shared/components/modal/SubModal';
import { closeSubModal, removeSelectedLifeEvent } from '../../redux/lifePlan';
import { ModalName } from '../../shared/constants/global';

function ConfirmDeleteModal(props) {
  const { t } = useTranslation('deleteModal')
  const tGlobal = useTranslation('global').t;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeSubModal());
  }

  const handleDelete = () => {
    dispatch(closeSubModal());
    dispatch(removeSelectedLifeEvent());
  }

  return <LifePlanSubModal name={ModalName.LIFEPLAN_CONFIRM_DELETE}>
    <div className="popupBox">
      <div className="popupInner alsC">
        <div className="favoritePopup">
          <p>{t('text')}</p>
          <ul className="btnList col2">
            <li><button href="#" className="btnSty-send" onClick={handleDelete}>{tGlobal('yes')}</button></li>
            <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('no')}</button></li>
          </ul>
        </div>
      </div>
    </div>
  </LifePlanSubModal>;
}

export default React.memo(ConfirmDeleteModal);
