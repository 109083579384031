import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import { debounce, flatten } from "lodash"
import TimelineHorizontalWidget from '../timeline-horizontal';
import TimelineVerticalWidget from '../timeline-vertical';

export default function TimelineWidget(props) {
  const BREAK_POINT = 768 // max width of SP
  const {lifePlan} = props;
  const cycleEvent = useSelector(state => state.cycleEvent)
  const [isSP, setIsSP] = useState(false);

  const events = useMemo(() => {
    let arrEventSortByUuid = {}
    lifePlan.life_event.concat(cycleEvent).forEach((e) => {
      if (!arrEventSortByUuid[e.uuid]) {
        arrEventSortByUuid[e.uuid] = []
      }
      arrEventSortByUuid[e.uuid].push(e)
    })

    let displayEvents = {}
    flatten(Object.values(arrEventSortByUuid)).forEach((e) => {
      if (!displayEvents[e.year]) {
        displayEvents[e.year] = []
      }
      if (displayEvents[e.year].length < 8) {
        displayEvents[e.year].push(e)
      } else if (!e.isClone && !displayEvents[e.year].length < 8) {
        const eventClones = displayEvents[e.year].filter(f => f.isClone)
        const idx = displayEvents[e.year].indexOf(eventClones[eventClones.length - 1])
        if (idx != -1) {
          displayEvents[e.year].splice(idx, 1)
          displayEvents[e.year].push(e)
        }
      }
    })
    return flatten(Object.values(displayEvents))
  }, [lifePlan.life_event, cycleEvent])

  const setupScreen = () => {
    const check = window.innerWidth <= BREAK_POINT
    if (isSP !== check) setIsSP(check)
  }

  useEffect(() => {
    setupScreen()
  }, [])

  useEffect(() => {
    window.onresize = debounce(setupScreen, 300)
  }, [window.innerWidth])

  return (
    <React.Fragment>
      <div className="timeline">
        {
          isSP ?
            <TimelineHorizontalWidget events={lifePlan.life_event} />
            :
            <TimelineVerticalWidget events={events} />
        }
      </div>
    </React.Fragment>
  )
}
