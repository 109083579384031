import { AffectLoanPeriod } from "../../core/classes/enum";
import { ValidateField } from "../constants/global";

export function calcLoanPreiod(props, key) {
  if(!key) key = AffectLoanPeriod.RE_BUY;
  const value = (+props?.lifeEvent?.end_year || 0) - (+props?.lifeEvent?.year || 0) + 1;
  return {
    ...props.lifeEvent,
    [key]: {
      ...props.lifeEvent?.[key],
      loan_period: value < ValidateField.Global.MIN_LOAN_YEAR ? value : ValidateField.Global.MIN_LOAN_YEAR,
      loan_rate: +props.lifeEvent?.[key].loan_rate
    }
  }
}


export function yearUntilOneHundred(person_age) {
  const currentYear = new Date().getFullYear();
  const yearArray = [];
  for(let i = 0; i <= (99 - person_age); i ++) {
    yearArray.push(currentYear + i)
  }
  return yearArray;
}
