import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import {convertToJapanCurrency} from "../../shared/constants/global";

export default function SpendingChart(props) {
  const { analysisResult } = props;

  const initOptions = {
    labels: ["給与", "その他収入", "公的年金", "貯蓄", "不動産収入"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: '',
              fontWeight: 600,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              formatter: () => '支出内訳'
            },
            value: {
              show: true,
              label: '',
              fontWeight: 600,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              formatter: () => '支出内訳'
            },
            name: {
              show: false,
            }
          }
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return convertToJapanCurrency(val);
        }
      }
    },
    colors: [function({ value, seriesIndex, w }) {
      if(seriesIndex === 0){
        return '#FA5253';
      }else if(seriesIndex === 1) {
        return '#f55f60';
      }else if(seriesIndex === 2) {
        return '#f96a6b';
      }else if(seriesIndex === 3) {
        return '#f57979';
      }else if(seriesIndex === 4) {
        return '#f78b8b';
      }else if(seriesIndex === 5) {
        return '#f9a1a1';
      }else if(seriesIndex === 6) {
        return '#ffadad';
      }else if(seriesIndex === 7) {
        return '#f9b6b6';
      }else if(seriesIndex === 8) {
        return '#FCC5C5';
      }else if(seriesIndex === 9) {
        return '#FCC5C5';
      }else{
        return '#f9cbcb';
      }
    }]
  };

  const initData = [4, 5, 6, 1, 5];

  const [data, setData] = useState([ ...initData ]);
  const [options, setOptions] = useState({ ...initOptions })

  const convertTextCategory = (category_id) => {
    switch (category_id) {
      case 6:
        return "不動産支出"
      case 7:
        return "教育費"
      case 8:
        return "住居費"
      case 9:
        return "生活費"
      case 10:
        return "その他支出"
      case 11:
        return "旅費"
      case 12:
        return "自動車"
      case 13:
        return "ペット"
      case 14:
        return "趣味"
    }
  }

  const calculatorChart = (results) => {
    const data = []
    const labels = []

    results.map(result => {
      if(result.value !== 0) {
        labels.push(convertTextCategory(result.category))
        data.push(result.value)
      }
    })

    const options = { labels: labels }

    return { data, options }
  }

  useEffect(async () => {
    try {
      const { data, options } = calculatorChart(analysisResult);
      setOptions(options)
      setData(data)
    } catch (e) {
      console.log(e)
    }
  }, [analysisResult])

  return <React.Fragment>
    <div id="chart">
      <Chart options={options} series={data} type="donut" height="350" />
    </div>
  </React.Fragment>
}
