import React, {useState} from "react";
export default function FunctionStep(props) {
  const [value, setValue] = useState(props.default || '')
  const handleTriggerNextStep = (value) => {
    props.triggerNextStep({ trigger: props.options.find(e => e.value == value).trigger,
                            value: value,
                            replaceAll: props.step.id })
  }

  return (
    <>
      <div className="titleOption">{props.title}</div>
      {props.options.map((opt) =>
        <div className="option" key={opt.value}>
          <input type="radio" id={props.name + opt.value} name={props.name} value={opt.value} checked={opt.value == value} onChange={() => setValue(opt.value)} />
          <label htmlFor={props.name + opt.value}>{opt.label}</label>
        </div>
      )}
      <button type="submit" className="btnSubmit" onClick={() => handleTriggerNextStep(value)}>
        決定
      </button>
    </>
  )
}
