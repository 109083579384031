import React, { useState, useEffect, useRef } from 'react';
import { Wrapper } from './styled';

export default function DragSwitch(props) {
  const { value, labelProp, indexProp, onChange } = props;
  const checkboxRef = useRef();

  const switchRef = useRef()
  const [check, setCheck] = useState(value === 1);
  const [Xpos, setPos] = useState(check ? 40 : 0)
  const [isMouseDown, setMouseDown] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [dragEnd, setDragEnd] = useState(0)

  const handleChange = () => {
    if (Date.now() - dragEnd > 25) {
      handleChecked(!check)
    }
  }

  const handleChecked = (newChecked) => {
    if (newChecked) {
      setPos(40)
    } else {
      setPos(0)
    }

    if (newChecked !== check) {
      setCheck(newChecked)
      onChange(indexProp, labelProp, newChecked ? 1 : 2)
    }
  }

  const startDrag = (e) => {
    e.preventDefault()

    setMouseDown(true)
  }

  const onMouseUp = (e) => {
    setMouseDown(false)

    if (switchRef.current && dragging) {
      const mouseRelativePos =
        e.clientX - switchRef.current.getBoundingClientRect().left

      setDragEnd(Date.now())
      setDragging(false)

      if (mouseRelativePos >= 40) {
        handleChecked(true)
      } else {
        handleChecked(false)
      }
    }
  }

  const onMouseMove = (e) => {
    if (isMouseDown && !dragging) {
      setDragging(true)
    }

    if (switchRef.current && dragging) {
      const halfHandle = 21
      const newPos =
        e.clientX - switchRef.current.getBoundingClientRect().left - halfHandle

      if (newPos < 0) {
        setPos(0)
      } else if (newPos > 40) {
        setPos(40)
      } else {
        setPos(newPos)
      }
    }
  }

  const handleClick = () => {
    checkboxRef.current.click();
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [dragging, isMouseDown])

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp)

    return () => {
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [dragging])

  return <Wrapper className={`checkToggleBtnCover ${props.className || ''}`}>
    <p>{props.label}</p>
    <div ref={switchRef} onMouseDown={startDrag} className="checkToggleBtnCoverInner">
      <div className={`checkToggleBtn checkToggleBtnR ${check ? 'sw-checked' : 'sw-uncheck'}`} id="checkToggleBtn-1">
        <input className="checkToggleBtnItem" type="checkbox"
          ref={checkboxRef}
          value={check}
          onChange={handleChange} />
        <div onClick={handleClick} className="checkToggleBody">
          <div
            className="checkToggleBodyInner"
            style={{
              cursor: 'pointer',
              transform: `translateX(${Xpos}px)`
            }}>
            <span className="checkToggleA">{props.className == 'univ' ? '国公立' : '公立'}</span><span className="checkToggleB">私立</span>
          </div>
        </div>
        <div className="checkToggleLayer"></div>
      </div>
    </div>
  </Wrapper>;
}
