import React from 'react';

export default function RadioField(props) {
  const { field, form, type, placeholder, disabled, options, labels } = props;

  const { name, value } = field;
  const errors = form.errors;
  const touched = form.touched;

  const showError = errors[name] && touched[name]

  const handleChange = (value) => {
    form.setFieldValue(name, value);
    props.onChange && props.onChange(value);
  }

  return (
    <ul className="radioList rowList">
      {options.map((option, index) => (
        <li key={index}><label className={`radioBtn ${option === value ? 'checked' : ''}`}>
          <input
            key={labels[index] + index}
            onChange={() => handleChange(option)}
            type="radio"
            id={name}
            name={name}
            checked={option === value}
            disabled={disabled}
          />
          {labels[index]}
        </label></li>
      ))}
    </ul>
  )
}
