import React from 'react';
import { getIn, ErrorMessage } from "formik";
import TextError from '../error-message';

export default function InputText(props) {
  const {secondLabel, ...inputField} = props;
  const { field, form, type, placeholder, disabled, numberonly, hideerrormsg, dF } = inputField;

  const name = field.name;

  const NOT_NUMBER = /\D+/

  function getStyles(errors, touched, name) {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  return <React.Fragment>
    <div className={`aisC ${dF ? 'dF' : ''}`}>
      {!hideerrormsg && <ErrorMessage name={name} render={TextError}/>}
      <input
        {...inputField}
        id={name}
        {...field}
        style={getStyles(form.errors, form.touched, name)}
        type={type}
        placeholder={placeholder || ''}
        disabled={disabled}
        onInput={(e) => {
          if (!numberonly) return;
          e.target.value = e.target.value.replace(NOT_NUMBER, '')
        }}
      />
      <span className="formUnit">{secondLabel}</span>
    </div>
  </React.Fragment>
}
