import axios from 'axios';
import { API_URL } from '../../shared/constants/api-url';
import errorInterceptor from './error.interceptor';
import updateHeaderInterceptor from './response.interceptor';


const instance = axios.create({
    baseURL: API_URL.BASE_URL
});

errorInterceptor(instance);
updateHeaderInterceptor(instance);

const get = async (url, params = {}) => {
    try {
        const { cancelToken, ...conf } = params;
        const config = { cancelToken: cancelToken, params: conf };
        const response = await instance.get(url, config);
        return _responseHandler(response);
    } catch (error) {
        return _errorHandler(error);
    }
};

const put = async (url, data = {}) => {
    try {
        let response = {};
        if (data.toLocaleString() === '[object FormData]') {
            response = await instance.put( url, data);
        } else {
            response = await instance.put(url, data);
        }
        return _responseHandler(response);
    } catch (error) {
        _errorHandler(error);
    }
};

const post = async (url, data = {}) => {
    try {
        const response = await instance.post(url, data);
        return _responseHandler(response);
    } catch (error) {
        _errorHandler(error);
    }
};

const del = async (url, data = {}) => {
    try {
        const response = await instance.delete(url, { data });
        return _responseHandler(response);
    } catch (error) {
        _errorHandler(error);
    }
};

const _responseHandler = (response, url = null) => {
    const data = response?.data;
    return data;
};

const _errorHandler = (err) => {
    if (err?.response && err.response.status === 401) {
        // todo
    }
    throw err;
};

const LifePlanAPI = {
    get, post, del, put
};

export default LifePlanAPI;