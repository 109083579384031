import React from 'react';
import { getIn, ErrorMessage } from "formik";
import TextError from '../error-message';
import NumberFormat from "react-number-format";

export default function InputText(props) {
  const {secondLabel, ...inputField} = props;
  const { field, form, type, placeholder, disabled, decimal, hideerrormsg, setFieldValue } = inputField;

  const name = field.name;

  const FIRST_DOT_NOTHING_BEFORE = /^\.+/

  function getStyles(errors, touched, name) {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  return <React.Fragment>
    <div className="aisC">
      {!hideerrormsg && <ErrorMessage name={name} render={TextError}/>}
      <NumberFormat
        {...inputField}
        id={name}
        value={field.value}
        style={getStyles(form.errors, form.touched, name)}
        type={type}
        placeholder={placeholder || ''}
        disabled={disabled}
        thousandSeparator={true}
        inputMode={decimal ? 'text' : 'numeric'}
        allowNegative={false}
        decimalScale={decimal || 0}
        onInput={(e) => {
          if (decimal && e.target.value !== '') {
            e.target.value = e.target.value.replace(FIRST_DOT_NOTHING_BEFORE, '')
          }
        }}
        onChange={(e) => {
          setFieldValue(
            name,
            e.target.value.replace(/,/g, "")
          );
        }}
      />
      <span className="formUnit">{secondLabel}</span>
    </div>
  </React.Fragment>
}
