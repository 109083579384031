import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import iconBuy from '../../assets/icons/ico-buy.png';
import iconSell from '../../assets/icons/ico-sell.png';
import iconLoopPc from '../../assets/icons/ico-loop-pc.svg';
import { setLifeEventSelected, setIsDragging as setIsGlobalDragging, showSelectedModel } from '../../redux/lifePlan';
import { getEventMapping, convertCurrency } from '../../shared/constants/global';

export default function Event(props) {
  const [isDragging, setIsDragging] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const dispatch = useDispatch()
  const cycleEvent = useSelector(state => state.cycleEvent);

  const handleDragStart = () => {
    setIsDragging(true)
    dispatch(setLifeEventSelected(props.event))
    document.querySelector("#stkr").style.display = "none" // FIXME: remove this line

    setTimeout(() => {
      setIsHide(true)
      dispatch(setIsGlobalDragging(true))
    }, 0);
  }

  const handleDragEnd = () => {
    setLifeEventSelected(null)
    setIsDragging(false)
    dispatch(setIsGlobalDragging(false))
    setIsHide(false)
  }

  const handleOpenModal = () => {
    dispatch(setLifeEventSelected(props.event))
    dispatch(showSelectedModel())
  }

  const [eventMapping, setEventMapping] = useState(getEventMapping(props.event))
  const [amount, setAmount] = useState(eventMapping.getAmount(props.event))
  const [eventTitle, setEventTitle] = useState(eventMapping.getEventTitle(props.event))
  const isLoop = cycleEvent.findIndex(e => e.uuid == props.event.uuid) != -1 && !props.event.isClone

  const cycleEventGetAmount = (event) => {
    if (props.event.isClone && ![5, 6, 7].includes(event.event_type)) {
      return convertCurrency(eventMapping.getCycleAmount(event))
    } else {
      return convertCurrency((+eventMapping.getAmount(event) + +eventMapping.getCycleAmount(event)) * (event.other?.number_of_people || 1))
    }
  }

  const getEventName = (event) => {
    if (event.custom) return event.custom

    return eventMapping.name
  }

  useEffect(() => {
    setEventMapping(getEventMapping(props.event))
    setAmount(cycleEventGetAmount(props.event))
    setEventTitle(eventMapping.getEventTitle(props.event))
  }, [props.event])

  return (
    <React.Fragment>
      <div
        className={["timelineEvent", isDragging && "dragging", isHide && "hide"].filter(e => !!e).join(' ')}
        draggable={!props.event.isClone}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleOpenModal}
      >
        { eventMapping.is_buy && <img src={iconBuy} alt="BUY" className="ico" /> }
        { eventMapping.is_sell && <img src={iconSell} alt="SELL" className="ico" /> }
        { isLoop && <div className="cycle-event" style={{"backgroundColor": props.event.cycleEventColor}}>&nbsp;<img src={iconLoopPc} alt="Loop" />&nbsp;<span>{props.event.other.frequency_year || 1}</span>&nbsp;</div> }
        <div className="eventImage" style={{"backgroundImage": `url(${require("images/lps-" + getEventMapping(props.event).type_name + ".png")})`}} />
        <div className="eventTitle" style={{"backgroundColor": props.event.cycleEventColor}} >{eventTitle || getEventName(props.event)}</div>
        <div dangerouslySetInnerHTML={{__html: amount}} className="eventAmount" />
      </div>
    </React.Fragment>
  )
}
