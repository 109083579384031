import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { YupSchema } from '../../../shared/constants/chatbot-validators';
import SelectDisplayOption from './CustomField/SelectDisplayOption';
import TextInput from './CustomField/TextInput';
import { LifePlanService } from '../../../core/services/life-plans';

export default function StationForm(props) {
  const { t } = useTranslation('saleModal');
  const validateSchema = new YupSchema(t).stationStep;
  const formState = useRef();
  const storage = JSON.parse(window.lsSession.getItem(`chatbot-${props.formType}`))
  const pref_code = storage.pref_code
  const initialValues = props.initialValues

  const emptyOptions = {
    line: {
      id: null,
      code: '',
      name: '選択してください'
    },
    station: {
      id: null,
      code: '',
      name: '選択してください'
    }
  }

  const setFormValues = (values) => {
    Object.keys(values).map(key => {
      formState.current.setFieldValue(key, values[key])
    });
  }

  const [lines, setLines] = useState([emptyOptions.line]);
  const [stations, setStations] = useState([emptyOptions.station]);

  const fetchLines = async (data) => {
    $("#preloadStation").fadeIn();
    const result = await LifePlanService.getLines(data);

    if (result.status) {
      setLines([emptyOptions.line, ...result.response]);
      setFormValues({ line_name: $('#line_code').find('option:selected').text() })
    }
    $("#preloadStation").fadeOut();
  }

  const fetchStations = async (data) => {
    $("#preloadStation").fadeIn();
    const result = await LifePlanService.getStations(data);

    if (result.status) {
      setStations([emptyOptions.station, ...result.response]);
      setFormValues({ station_name: $('#station_code').find('option:selected').text() })
    }
    $("#preloadStation").fadeOut();
  }

  const handleLinesChange = async (e) => {
    const $this = $(e.target)
    setFormValues({ line_name: $this.find('option:selected').text() })

    setFormValues({
      station_code: '',
      station_name: ''
    });

    setStations([emptyOptions.station]);

    if ($this.val() === '') return;

    const stationsData = {
      pref_code: pref_code == "01" ? "1" : pref_code,
      line_code: $this.val()
    }

    await fetchStations(stationsData);
  }

  const handleStationsChange = (e) => {
    const $this = $(e.target)
    setFormValues({ station_name: $this.find('option:selected').text() })
  }

  useEffect(() => {
    const dataEvents = initialValues
    let {
      line_code,
    } = dataEvents

    const linesData = {
      pref_code: pref_code == "01" ? "1" : pref_code
    }

    const stationsData = {
      pref_code: pref_code,
      line_code: line_code
    }

    fetchLines(linesData);
    fetchStations(stationsData);
  }, [])

  const handleTriggerNextStep = async(values) => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) return;

    const newObj = {...storage, ...values}
    const storageStr = JSON.stringify(newObj)
    window.lsSession.setItem(`chatbot-${props.formType}`, storageStr)
    props.triggerNextStep({ trigger: props.trigger, value: values, replaceAll: props.step.id })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleTriggerNextStep}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            {/* Component Preloader */}
            <div id="preloadStation" className="preload">
              <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>

            <div className="selectOption">
              <div className="titleSelect">路線</div>
              <Field name="line_code" component={SelectDisplayOption} onChange={handleLinesChange} options={lines} />
            </div>
            <div className="selectOption">
              <div className="titleSelect">駅名</div>
              <Field name="station_code" component={SelectDisplayOption} onChange={handleStationsChange} options={stations} />
            </div>
            <div className="selectOption">
              <div className="titleSelect">徒歩分数</div>
              <Field name="walking_minutes" component={TextInput} numberonly="true" type="text" placeholder="入力してください。" />
            </div>
            <button type="submit" className="btnSubmit">
              決定
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
