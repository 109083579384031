import React from 'react';
import { getLifeEventMapping } from '../../shared/constants/global';
import EventItem from './child-event';

const eventList = Object.values(getLifeEventMapping()).map((v) => v.getDefaultValues())

export default function EventGhostList(props) {
  return (
    <React.Fragment>
      <div className="timelineEventGhostList">
        {eventList.map((event, index) =>
          <div key={index} className={`timelineEventGhost timelineEventGhost_${event.event_type || ''}`}>
            <EventItem event={event} />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
