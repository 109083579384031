import React from 'react';

export default function Axis(props) {
  return (
    <React.Fragment>
      <div className="timelineAxis">
      </div>
    </React.Fragment>
  )
}
