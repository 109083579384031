import * as Yup from 'yup';

export class YupSchema {

    #translate; // private field

    constructor(translate) {
        this.#translate = translate;
    }

    get addressStep() {
      return Yup.object().shape({
        pref_code: Yup.string().required(this.#translate('validate.pref_code_required')),
        city_code: Yup.string().required(this.#translate('validate.city_code_required')),
        region_code: Yup.string().required(this.#translate('validate.town_required')),
        after_town: Yup.string().required(this.#translate('validate.after_town_required')).max(128, this.#translate('validate.after_town_max')),
      });
    }

    get stationStep() {
      return Yup.object().shape({
        line_code: Yup.string().required(this.#translate('validate.line_code_required')),
        station_code: Yup.string().required(this.#translate('validate.station_code_required')),
        walking_minutes: Yup.number().required(this.#translate('validate.walking_minutes_required'))
                          .min(0, this.#translate('validate.walking_minutes_range'))
                          .max(100, this.#translate('validate.walking_minutes_range')),
      });
    }

    get buildingStructure() {
      return Yup.object().shape({
        building_structure: Yup.string().required(this.#translate('validate.building_structure_required')),
      });
    }

    get builtDate() {
      return Yup.object().shape({
        built_date: Yup.number().required(this.#translate('validate.built_date_required')),
      });
    }

    get occupationArea() {
      return Yup.object().shape({
        occupation_area: Yup.number().required(this.#translate('validate.occupation_area_required'))
                          .min(0, this.#translate('validate.occupation_area_range'))
                          .max(1000, this.#translate('validate.occupation_area_range')),
      });
    }

    get floorLocated() {
      return Yup.object().shape({
        floor_located: Yup.number().required(this.#translate('validate.floor_located_required'))
                          .min(0, this.#translate('validate.floor_located_range'))
                          .max(100, this.#translate('validate.floor_located_range')),
      });
    }

    get numberOfFloors() {
      return Yup.object().shape({
        number_of_floors: Yup.number().required(this.#translate('validate.number_of_floors_required'))
                          .min(0, this.#translate('validate.number_of_floors_range'))
                          .max(100, this.#translate('validate.number_of_floors_range')),
      });
    }

    get totalOfFloors() {
      return Yup.object().shape({
        total_floor_space: Yup.number().required(this.#translate('validate.total_floor_space_required'))
                          .min(0, this.#translate('validate.total_floor_space_range'))
                          .max(10000, this.#translate('validate.total_floor_space_range')),
      });
    }

    get numberOfHouses() {
      return Yup.object().shape({
        number_of_houses: Yup.number().required(this.#translate('validate.number_of_houses_required'))
                          .min(0, this.#translate('validate.number_of_houses_range'))
                          .max(100, this.#translate('validate.number_of_houses_range')),
      });
    }

    get rentForm() {
      return Yup.object().shape({
        rental_status_total: Yup.number()
                            .nullable(true)
                            .min(0, this.#translate('validate.rental_status_total_range'))
                            .max(100, this.#translate('validate.rental_status_total_range')),
        rental_status_operation: Yup.number()
                            .nullable(true)
                            .min(0, this.#translate('validate.rental_status_operation_range'))
                            .max(100, this.#translate('validate.rental_status_operation_range')),
        rental_status_remarks: Yup.string()
                .nullable(true)
                .max(128, this.#translate('validate.rental_status_remarks_max')),
      });
    }

    get customerAddressForm() {
      return Yup.object().shape({
        customer_zip_code: Yup.string()
                          .nullable(true)
                          .max(7, this.#translate('validate.customer_zip_code_max')),
      });
    }

    get customerNameForm() {
      const hiraganaRegex = /^[ぁ-ん]*$/
      return Yup.object().shape({
        last_name: Yup.string().required(this.#translate('validate.last_name_required')).max(32, this.#translate('validate.last_name_max')),
        first_name: Yup.string().required(this.#translate('validate.first_name_required')).max(32, this.#translate('validate.first_name_max')),
        last_name_kana: Yup.string().required(this.#translate('validate.last_name_kana_required')).max(32, this.#translate('validate.last_name_kana_max')).matches(hiraganaRegex, this.#translate('validate.last_name_kana_format')),
        first_name_kana: Yup.string().required(this.#translate('validate.first_name_kana_required')).max(32, this.#translate('validate.first_name_kana_max')).matches(hiraganaRegex, this.#translate('validate.first_name_kana_format')),
      });
    }

    get company() {
      return Yup.object().shape({
        company: Yup.string().required(this.#translate('validate.company_required')).max(128, this.#translate('validate.company_max')),
      });
    }

    get email() {
      return Yup.object().shape({
        email: Yup.string().email(this.#translate('validate.email_format')).required(this.#translate('validate.email_required')).max(128, this.#translate('validate.email_max')).min(6, this.#translate('validate.email_min')),
      });
    }

    get phoneNumber() {
      return Yup.object().shape({
        phone_number: Yup.string().required(this.#translate('validate.phone_required')).max(11, this.#translate('validate.phone_length')).min(10, this.#translate('validate.phone_length')),
      });
    }
}
