import { API_URL } from "../../../shared/constants/api-url"
import LifePlanAPI from "../../axios"

export async function registerLifePlans(payload) {
    return new Promise(rs => {
        LifePlanAPI.post(API_URL.REGISTER, payload).then(response => {
            // ...
            // handle response,
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function propertyAssessmentAndRegisterResult(payload) {
    return new Promise(rs => {
        LifePlanAPI.post(API_URL.PROPERTY_ASSESSMENT_AND_REGISTER_RESULT, payload).then(response => {
            // ...
            // handle response
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function inputContactInfo(payload) {
    return new Promise(rs => {
        LifePlanAPI.post(API_URL.INPUT_CONTACT_INFO, payload).then(response => {
            // ...
            // handle response
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}


export async function registerSimulation(payload) {
    return new Promise(rs => {
        LifePlanAPI.post(API_URL.REGISTER_SIMULATION, payload).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function getPrefectures() {
    return new Promise(rs => {
        LifePlanAPI.get(API_URL.PREFECTURE).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function getCities(payload) {
    return new Promise(rs => {
        LifePlanAPI.get(API_URL.CITY, payload).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function getRegions(payload) {
    return new Promise(rs => {
        LifePlanAPI.get(API_URL.REGION, payload).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function getLines(payload) {
    return new Promise(rs => {
        LifePlanAPI.get(API_URL.LINE, payload).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function getStations(payload) {
    return new Promise(rs => {
        LifePlanAPI.get(API_URL.STATION, payload).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

export async function getAddressByZipcode(payload) {
    return new Promise(rs => {
        LifePlanAPI.get(API_URL.SEARCH_ADDRESS_BY_ZIPCODE + payload).then(response => {
            rs({
                status: true,
                response
            })
        })
        .catch(err => {
            rs({
                status: false,
                response: null,
                error: err
            })
        })
    })
}

