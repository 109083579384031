import { Route, Routes } from "react-router-dom";
import React from "react";
import { ROUTES } from "../../routes"

export default function AppMain() {
    const processRoutes = ROUTES.map((route, index) => {
        return (
            <Route key={index} path={route.path} element={route.element} />
        );
    });

    return (
        <Routes>
          {processRoutes}
        </Routes>
    )
}