import React from "react";
import { LifePlansImages } from "../../assets/images";
import iconFormGreen from "../../assets/icons/ico-formGreen.svg";
const { commentCustomer, commentStaff, arrowBGrad } = LifePlansImages;


function AssetReplacementTabContentWidget(props) {
  return (
    <>
      <div className="commentLine">
        <div className="commentDet staff">
          <figure>
            <img src={commentCustomer} alt="" />
            <figcaption>お客様</figcaption>
          </figure>
          <div className="comment">
            <p>
            修繕すべきか、資産入替をするべきか悩んでいます。償却期間も終わろうてしておりますが、よいアドバイスはありますか？
            </p>
          </div>
        </div>
        <div className="commentDet staff">
          <figure>
            <img src={commentStaff} alt="" />
            <figcaption>
              営業スタッフ
            </figcaption>
          </figure>
          <div className="comment">
            <p>
            償却期間が満了してしまうと、税制面での優遇がなくなり、状況によっては、キャッシュフローがマイナスになってしまうリスクがあります。お客様の資産状況を精査し、有効な対策をご提案いたします。
            </p>
          </div>
        </div>
      </div>
      <div className="arrow">
        <img src={arrowBGrad} alt="" />
        <p>
          上記内容も無料で相談。
          <br className="sp" />
          安心のサポートをいたします。
        </p>
      </div>
      <div className="contactGreenSty">
        <a href="/contact/contact_generals/new">
          <h3>
            無料相談は<span className="inlBlock fontWB">かんたん1分で完了</span>
          </h3>
          <span className="whiteLineBtn">
            <img src={iconFormGreen} alt="" width="32" height="26" />
            お問い合わせはこちら
          </span>
          <span className="ttlSty">CONTACT</span>
        </a>
      </div>
    </>
  );
}

export default React.memo(AssetReplacementTabContentWidget);
