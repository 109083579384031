import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getIn, ErrorMessage } from 'formik';
import { cloneDeep } from "lodash";
import DragSwitch from '../../../shared/components/drag-switch';
import TextError from '../../../shared/components/error-message';

export default function ChildField(props) {
  const EDUCATIONS = ['elementary_school', 'middle_school', 'high_school', 'university'];
  const childTemplate = {
    age: '',
    elementary_school: 2,
    middle_school: 2,
    high_school: 2,
    university: 1,
    key: Date.now()
  }
  const { t } = useTranslation('familyModal');
  const { field, form, type, label, placeholder, disabled } = props;

  const { name, value } = field;
  const errors = form.errors;
  const touched = form.touched;
  const showError = errors[name] && touched[name];

  const childs = useMemo(() => {
    const clone = cloneDeep(value);
    return clone;
  }, [value])


  const handleChangeAge = (index, value) => {
    let childsRep = [...childs];
    childsRep[index]['age'] = value;
    form.setFieldValue(name, [...childsRep]);
  }

  const handleChangeEdu = (index, label, value) => {
    let childsRep = [...childs];
    childsRep[index][label] = value;
    form.setFieldValue(name, [...childsRep]);
  }

  const handleAddChild = () => {
    let childsRep = [...childs];
    childsRep.push({...childTemplate});
    form.setFieldValue(name, [...childsRep]);
  }

  const handleRemoveChild = (index) => {
    let childsRep = [...childs];
    childsRep.splice(index, 1);
    form.setFieldValue(name, [...childsRep]);
  }

  const getStyles = (errors, touched, name) => {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  return <React.Fragment>
    <dt className="padT28">{t('children.label')}</dt>
    <dd className="TableList AccountCooperationBox noFlex chidlenNum" id="AppCoverToggleButton">
      {childs.map((child, index) => (
        <dl key={index} className="formTtlStyNarrow bgGray">
          <ErrorMessage name={`child.${index}.age`} render={TextError} />
          <dt>{t('children.chldrenStt', { stt: index + 1 })}</dt>
          <dd>
            <div className="age">
              <input
                type="text"
                className="wid80"
                name={`child.${index}.age`}
                value={child['age']}
                style={getStyles(form.errors, form.touched, `child.${index}.age`)}
                onChange={(event) => handleChangeAge(index, event.target.value)}
              />
              <span className="formUnit">{t('ageNum')}</span>
            </div>
            {EDUCATIONS.map((edu, eduIndex) => (
              <DragSwitch
                key={edu + child['key']}
                labelProp={edu}
                indexProp={index}
                value={child[edu]}
                label={t(`children.listLabel.${eduIndex}`)}
                onChange={handleChangeEdu}
                className={eduIndex === 3 ? 'univ' : ''}
                />
              ))}
            {<button onClick={() => handleRemoveChild(index)} type="button" className="deleteBlackBtn"></button>}
          </dd>
        </dl>
      ))}
      {childs.length < 4 && <button onClick={handleAddChild} type="button" className="plusAddBtn"></button>}
    </dd>
  </React.Fragment>
}
