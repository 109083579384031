import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LifePlanService } from '../../core/services/life-plans';
import { closeModal, openModal } from '../../redux/lifePlan';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';
import BuildingTab from './FormTab/BuildingTab';
import ClassTab from './FormTab/ClassTab';

export default function SaleModal(props) {
  const { t } = useTranslation('saleModal');
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const { lifeEvent } = props;

  const propertyAssessment = async (values) => {
    $("#preloader").fadeIn();
    const result = await LifePlanService.propertyAssessmentAndRegisterResult(values);
    $("#preloader").fadeOut();
    if (typeof(result.response) !== typeof(undefined)) {
      const sellInvesmentValues = {
        ...values,
        prediction_result: { ...result.response.data.result }
      }
      window.lsSession.setItem('sell_investment', JSON.stringify(sellInvesmentValues))
      dispatch(openModal(ModalName.LIFEPLAN_SALE_2))
    }
  }

  const handleClose = () => {
    window.lsSession.removeItem('sell_investment');
    dispatch(closeModal());
  }

  useEffect(() => {
    if (lifeEvent?.re_sell.property_type == 2) {
      setTabIndex(1)
    }
  }, [])
  return <LifePlanModal name={ModalName.LIFEPLAN_SALE}>
    <div className="popupBox">
      <div className="popupInner">
        <div className="lifeplanPopup realestateSale">
          <h2 className="padB44">{t('label')}</h2>
          <div className="popupContents">

            <div className="tabBox saleAItabBox">
              <div className="saleAItabTitSty marT40">
                <h3 className="colBlu font20 fontWB alC">{t('selectPropertyType')}</h3>
                <ul className="tabBtn notMar">
                  <li onClick={() => setTabIndex(0)} className={`hoverItem ${tabIndex === 0 ? 'active': ''}`}><span>{t('typeOptions.0')}</span></li>
                  <li onClick={() => setTabIndex(1)} className={`hoverItem ${tabIndex === 1 ? 'active': ''}`}><span>{t('typeOptions.1')}</span></li>
                </ul>
              </div>

              <div className="tabContents">
                <div className={`tabPanel ${tabIndex === 0 ? 'active': ''}`}>
                  <ClassTab handleClose={handleClose} {...lifeEvent} propertyAssessment={propertyAssessment} />
                </div>

                <div className={`tabPanel ${tabIndex === 1 ? 'active': ''}`}>
                  <BuildingTab handleClose={handleClose} {...lifeEvent} propertyAssessment={propertyAssessment} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LifePlanModal>
}
