import React, {useEffect, useState} from 'react';
import BgPaginationItem from "./BgPaginationItem";
import PrevButton from "./PrevButton";
import NextButton from "./NextButton";
import { useSelector } from "react-redux";

export default function BgPagination(props) {
  const PAGINATION_SIZE = 10
  const currentAge = useSelector(state => state.life_plan.person_age)

  const eventsByYear = {}
  props.events.forEach(event => {
    eventsByYear[event.year] ||= []
    eventsByYear[event.year].push(event)
  })

  const pagination = {}
  let page = 0
  props.labels.forEach(label => {
    if (label.age % PAGINATION_SIZE === 0) {
      page += 1
      pagination[page] = {
        label: label,
        eventGroups: {}
      }
    }

    pagination[page]["eventGroups"][label.year] ||= { label: label }
    pagination[page]["eventGroups"][label.year]["events"] = eventsByYear[label.year] || []
  })

  const paginationRef = React.useRef()
  const [disabledPrev, setDisabledPrev] = useState(true)
  const [disabledNext, setDisabledNext] = useState(false)

  const handleUpdateButtonState = () => {
    const paginationEl = paginationRef.current
    setDisabledPrev(paginationEl.scrollLeft === 0)
    setDisabledNext(paginationEl.offsetWidth + paginationEl.scrollLeft >= paginationEl.scrollWidth)
  }

  useEffect(() => {
    setDisabledNext(currentAge >= 80)
  }, [currentAge])

  return (
    <React.Fragment>
      <PrevButton disabled={disabledPrev} />
      <div className="timelineBgPagination" ref={paginationRef} onScroll={handleUpdateButtonState}>
        { Object.keys(pagination).map((page) => <BgPaginationItem page={page} item={pagination[page]} key={page} />) }
      </div>
      <NextButton disabled={disabledNext} />
    </React.Fragment>
  )
}
