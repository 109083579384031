import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik, useFormikContext } from 'formik';
import icoSave from '../../assets/icons/ico-save.svg';
import { useDispatch, useSelector } from 'react-redux';
import {setInputLifePlan} from "../../redux/lifePlan/slice";
import { ModalName } from '../../shared/constants/global';
import { FlowCurrent } from '../../shared/components/flow-current';
import CommaInput from '../../shared/components/comma-input-field';
import LifePlanModal from '../../shared/components/modal';
import SelectFieldSecond from '../../shared/components/select-field-second';
import { YupSchema } from '../../shared/constants/validators';

const FormObserver = (props) => {
  const { values } = useFormikContext();

  useEffect(() => {
    props.handleFormChangeValues && props.handleFormChangeValues(values)
  }, [values]);

  return null;
};

export default function InputSpouseModal(props) {
  const { t } = useTranslation('inputSpouseModal');
  const tGlobal = useTranslation('global').t;

  const dispatch = useDispatch();
  const partner = useSelector(state => state.life_plan.partner);
  const person_age = useSelector(state => state.life_plan.person_age || 0);
  const lifePlan = useSelector(state => state.life_plan);
  const childState = useSelector(state => state.life_plan.child);
  const currentYear = new Date().getFullYear();

  const [isSave, setIsSave] = useState(false);
  const [ initialValues, setInitialValues ] = useState({
    person_income: '',
    partner_income: '',
    person_retirement_money: 2000,
    partner_retirement_money: '',
    other_income: '',
    other_income_end: new Date().getFullYear(),
    saving_amount: '',
    reserve_monthly: '',
    reserve_end: new Date().getFullYear(),
    maturity: new Date().getFullYear(),
    future_sum: ''
  });

  const formRef = useRef();
  const timeoutId = useRef();

  const validateSchema = new YupSchema(t).inputSpouseModal;

  const yearUntilOneHundred = useMemo(() => {
    const yearArray = []
    for(let i = 0; i <= (99 - +person_age); i ++) {
      yearArray.push(currentYear + i)
    }
    return yearArray;
  }, [person_age]);

  const renderYearFromNow = (year) => {
    return `${year - currentYear + +person_age}歳（${year}年）`
  }

  const handleNextModal = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();
    if (!formRef.current.isValid) return;

    dispatch(setInputLifePlan({ ...values }))
    timeoutId.current && clearTimeout(timeoutId.current);
    document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPOUSE).classList.remove('savePopup');
    props.handleNextModal && props.handleNextModal(2)
  }

  const handlePrevModal = () => {
    timeoutId.current && clearTimeout(timeoutId.current);
    document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPOUSE).classList.remove('savePopup');
    props.handlePrevModal && props.handlePrevModal(2)
  }

  const handleSubmit = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    const child = childState.map(ele => {
      return { ...ele }
    })
    setIsSave(true)
    dispatch(setInputLifePlan({ ...values, child }))
  }

  const handleSave = async () => {
    setIsSave(false)
    try {
      props.handleSubmitLifePlan && props.handleSubmitLifePlan(false);
      document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPOUSE).classList.add('savePopup');
      timeoutId.current = setTimeout(() => {
        document.getElementById('lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPOUSE).classList.remove('savePopup');
      }, 3000)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    }
  }, [])

  useEffect(() => {
    var updateValues = {}
    Object.keys(initialValues).forEach(key => {
      if (lifePlan[key] !== null) {
        if (key == "reserve_monthly") {
          updateValues[key] = !!lifePlan[key] ? +lifePlan[key] : ''
        } else {
          updateValues[key] = lifePlan[key]
        }
      }
    });
    setInitialValues({
      ...initialValues,
      ...updateValues
    })
    if (isSave) handleSave();
  }, [lifePlan])

  const handleFormChangeValues = async (values) => {
    await formRef.current.setFieldTouched();
    await formRef.current.validateForm();

    if (!formRef.current.isValid) return;

    if (props.step != 0) {
      const newObj = {...lifePlan, ...values}
      const lifePlanStr = JSON.stringify(newObj)
      window.lsSession.setItem("life_plans", lifePlanStr)
    }
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_INPUT_SPOUSE}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        formRef.current = formikProps;

        return (
          <Form>
            <div className="popupBox">
              <div className="popupInner">
                <div id={'lifeplanPopup' + ModalName.LIFEPLAN_INPUT_SPOUSE} className="lifeplanPopup">
                  <h2 className="padB44">{t('label')}</h2>
                  <div className="popupContents">
                    <FlowCurrent flow="収入" />

                    <div className="marB14">{t('currentIncomeStatus')}</div>
                    <dl className="formSty marB40">
                      <dt>{t('salaryIncome')}</dt>
                      <dd>
                        <dl className="formTtlSty nowrap">
                          <dt>{t('identity')}</dt>
                          <dd>
                            <Field type="text" className="wid120" name="person_income" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          </dd>
                          <dt className={partner === 1 ? 'hiddenItem' : ''}>{t('wife')}</dt>
                          <dd className={partner === 1 ? 'hiddenItem' : ''}>
                            <Field type="text" className="wid120 toggle-item" name="partner_income" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} disabled={partner === 1} setFieldValue={setFieldValue} />
                          </dd>
                        </dl>
                      </dd>
                      <dt>{t('expectedRetirementAllowance')}</dt>
                      <dd>
                        <dl className="formTtlSty nowrap">
                          <dt>{t('identity')}</dt>
                          <dd>
                            <Field type="text" className="wid120" name="person_retirement_money" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          </dd>
                          <dt className={partner === 1 ? 'hiddenItem' : ''}>{t('wife')}</dt>
                          <dd className={partner === 1 ? 'hiddenItem' : ''}>
                            <Field type="text" className="wid120 toggle-item" name="partner_retirement_money" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} disabled={partner === 1} setFieldValue={setFieldValue} />
                          </dd>
                        </dl>
                      </dd>

                      <dt>{t('otherIncome')}</dt>
                      <dd className="jcFS">
                        <div className="maxWidth432">
                          <Field type="text" className="wid120" name="other_income" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          <p className="font14 marT16">{t('notIncludeRent')}</p>
                        </div>
                        <dl className="formTtlSty nowrap wid380 marB40 marL40">
                          <dt>{t('end')}</dt>
                          <dd>
                            <Field component={SelectFieldSecond} width="wid200" name="other_income_end" options={yearUntilOneHundred} display_options={renderYearFromNow} />
                            <span className="formUnit">{tGlobal('untilYear')}</span>
                          </dd>
                        </dl>
                      </dd>

                      <dt>{t('saveAmount')}</dt>
                      <dd>
                        <Field type="text" className="wid120" name="saving_amount" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                      </dd>

                      <dt>{t('reserveMonthly')}</dt>
                      <dd className="jcFS">
                        <div className="maxWidth432">
                          <Field type="text" decimal={1} className="wid120" name="reserve_monthly" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          <p className="font14 marT16 marB4">{t('enterTheAmount')}</p>
                        </div>
                        <dl className="formTtlSty nowrap wid380 marB40 marL40">
                          <dt>{t('end')}</dt>
                          <dd>
                            <Field component={SelectFieldSecond} width="wid200" name="reserve_end" options={yearUntilOneHundred} display_options={renderYearFromNow} />
                            <span className="formUnit">{tGlobal('untilYear')}</span>
                          </dd>
                        </dl>
                        <dl className="formTtlSty nowrap">
                          <dt>{t('maturity')}</dt>
                          <dd className="wid380">
                            <Field component={SelectFieldSecond} width="wid200" name="maturity" options={yearUntilOneHundred} display_options={renderYearFromNow} />
                            <span className="formUnit">{tGlobal('untilYear')}</span>
                          </dd>
                          <dt>{t('estimateAmount')}</dt>
                          <dd>
                            <Field type="text" className="wid120" name="future_sum" component={CommaInput} secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                          </dd>
                        </dl>
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList reverse">
                        <li><button type="submit" onClick={() => handleSubmit(values)} className="linkBtn wid200"><figure><img src={icoSave} alt="" width="16" /></figure><span>{tGlobal('save')}</span></button></li>
                        <li><button type="button" className="btnSty-back" onClick={handlePrevModal}>{tGlobal('back')}</button></li>
                        <li><button type="submit" href="#" className="btnSty-send" onClick={() => handleNextModal(values)}>{tGlobal('next')}</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <FormObserver handleFormChangeValues={handleFormChangeValues} />
            </div>
          </Form>
          )
        }}
      </Formik>
  </LifePlanModal>
}
