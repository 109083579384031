import { composeInitialProps } from "react-i18next";

const familyStructure = (state) => {

  const { partner, person_age, partner_age, person_pension, partner_pension, child } = state;

  let age_of_spouse = partner == 2 ? parseInt(partner_age) : null
  let pension_classification_of_spouse = partner == 2 ? parseInt(partner_pension) : null

  return {
    family_structure: {
      num_of_parents: parseInt(partner),
      num_of_children: child.length,
      age_of_person: parseInt(person_age),
      age_of_spouse: age_of_spouse,
      pension_classification_of_person: parseInt(person_pension),
      pension_classification_of_spouse: pension_classification_of_spouse
    }
  }
}

const educationCostPlan = (state) => {
  const { person_age, child, life_event } = state;
  let personAge = parseInt(person_age);

  let childsProp = child.map(child => {
    let childAge = parseInt(child.age);

    return {
      age_of_child: childAge,
      childbirth_age: null,
      elementary_school: child.elementary_school,
      junior_high_school: child.middle_school,
      high_school: child.high_school,
      university: child.university
    }
  });

  let childsEvent = [];
  life_event.map((event) => {
    if (event.event_type == 9 && event.other.detail == 2) {
      let childbirth_age = parseInt(event.year) - (new Date().getFullYear()) + personAge;

      childsEvent.push({
        age_of_child: null,
        childbirth_age: childbirth_age,
        elementary_school: event.other.elementary_school,
        junior_high_school: event.other.middle_school,
        high_school: event.other.high_school,
        university: event.other.university
      })
    }
  }, [])

  return {
    education_cost_plan: [...childsProp, ...childsEvent]
  }
}

const income = (state) => {

  const { person_age, person_income, partner_income, person_retirement_money, partner_retirement_money, other_income, other_income_end, future_sum, maturity } = state;

  let other_income_person_end = other_income_end ? (parseInt(person_age) + parseInt(other_income_end) - (new Date().getFullYear())) : parseInt(person_age)
  let other_bulk_income_person_end = maturity ? (parseInt(person_age) + parseInt(maturity) - (new Date().getFullYear())) : parseInt(person_age)

  return {
    income: {
      annual_income_person: parseInt(person_income * 0.7 * 10000) || 0,
      annual_income_spouse: parseInt(partner_income * 0.7 * 10000) || 0,
      severance_pay_person: parseInt(person_retirement_money) * 10000 || 0,
      severance_pay_spouse: parseInt(partner_retirement_money) * 10000 || 0,
      other_income_person: parseInt(other_income) * 10000 || 0,
      other_income_person_end: other_income_person_end,
      other_bulk_income_person: +future_sum * 10000 || 0,
      other_bulk_income_person_end: other_bulk_income_person_end
    }
  }
}

const expenditure = (state) => {

  const { partner, person_age, child, residence_type, rent, housing_loan, housing_loan_balance,
    housing_monthly_repayment, housing_bonus_repayment, housing_repayment_end,
    reserve_monthly, reserve_end, term_insurance_monthly, term_insurance_end
  } = state;

  let cost_of_living = (partner == 1 ? 77240 : 123489) + 42057 * child.length
  let cost_of_living_old_age = partner == 1 ? 76880 : 119916
  let repayment_end_age = housing_repayment_end ? (parseInt(person_age) + parseInt(housing_repayment_end) - (new Date().getFullYear())) : null
  let other_expenditures = [
    {
      other_expenditure: +reserve_monthly * 10000 || 0,
      other_expenditure_end: (parseInt(person_age) + parseInt(reserve_end) - (new Date().getFullYear())) || parseInt(person_age)
    },
    {
      other_expenditure: +term_insurance_monthly * 10000 || 0,
      other_expenditure_end: (parseInt(person_age) + parseInt(term_insurance_end) - (new Date().getFullYear())) || parseInt(person_age)
    }
  ]

  return {
    expenditure: {
      cost_of_living: cost_of_living,
      cost_of_living_old_age: cost_of_living_old_age,
      property_type: residence_type == 1 ? 1 : 2,
      month_rent: residence_type == 1 ? 0 : (parseInt(rent) * 10000 || 0),
      has_loan: residence_type == 2 ? 0 : housing_loan == 1 ? 1 : 0,
      loan_balance: residence_type == 2 ? 0 : (parseInt(housing_loan_balance) * 10000 || 0),
      month_repayment: residence_type == 2 ? 0 : (+housing_monthly_repayment * 10000 || 0),
      bonus_repayment: residence_type == 2 ? 0 : (parseInt(housing_bonus_repayment) * 10000 || 0),
      repayment_end_age: residence_type == 2 ? null : repayment_end_age,
      other_expenditures: other_expenditures
    }
  }
}

const depositSavings = (state) => {
  const { saving_amount } = state;

  return {
    deposit_savings: {
      savings_of_person: parseInt(saving_amount) * 10000 || 0,
      savings_of_spouse: 0
    }
  }
}

const ownedRealEstates = (state) => {
  const { life_event, person_age } = state;
  const currentYear = new Date().getFullYear()
  const currentAge = parseInt(person_age || 20)

  var lifeEventsCustom = []

  life_event.map(event => {
    if (event.event_type == 2) {
      var lifeEventSellInvestment = {}
      if (event.re_sell.investment_property_loan == 2) {
        lifeEventSellInvestment = {
          month_repayment: 0,
          bonus_repayment: 0,
          loan_balance: 0,
          repayment_end_age: null
        }
      } else {
        lifeEventSellInvestment = {
          month_repayment: +event.re_sell.investment_monthly_repayment * 10000 || 0,
          bonus_repayment: event.re_sell.investment_bonus_repayment * 10000 || 0,
          loan_balance: event.re_sell.investment_loan_balance * 10000 || 0,
          repayment_end_age: parseInt(event.re_sell.investment_repayment_end) - currentYear + currentAge
        }
      }
      lifeEventSellInvestment = {
        ...lifeEventSellInvestment,
        management_cost: +event.re_sell.operating_costs * 10000 || 0,
        other_expenditure: (+event.re_sell.renovation_reserves * 10000 || 0) + (+event.re_sell.property_tax * 10000 || 0),
        real_estate_buy_age: parseInt(event.re_sell.purchase_year) - currentYear + currentAge,
        real_estate_sale_age: parseInt(event.year) - currentYear + currentAge,
        prediction_result:  {
                              ...event.prediction_result,
                              market_rents: event.prediction_result.market_rents.map(e => parseInt(e)),
                              upside_market_rents: event.prediction_result.upside_market_rents.map(e => parseInt(e)),
                              downside_market_rents: event.prediction_result.downside_market_rents.map(e => parseInt(e)),
                              market_sell_prices: event.prediction_result.market_sell_prices.map(e => parseInt(e)),
                              upside_market_sell_prices: event.prediction_result.upside_market_sell_prices.map(e => parseInt(e)),
                              downside_market_sell_prices: event.prediction_result.downside_market_sell_prices.map(e => parseInt(e))
                            }
      }

      lifeEventsCustom.push(lifeEventSellInvestment)
    }
  })

  return {
    owned_real_estates: [...lifeEventsCustom]
  }
}

const lifeEventCategory = (event) => {
  var category = 0;
  var costType = 0;
  let eventName = 'custom';
  switch(event.event_type) {
    // temp
    case 1:
      category = 6;
      costType = 3;
      eventName = 'buy_investment';
      break;
    // temp
    case 2:
      category = 5;
      costType = 4;
      eventName = 'sell_investment';
      break;
    case 3:
      category = 8;
      costType = 5;
      eventName = 'buy_housing';
      break;
    // temp
    case 4:
      category = 2;
      costType = 6;
      eventName = 'sell_housing';
      break;
    case 5:
      category = 9;
      costType = 1;
      eventName = 'eatout';
      break;
    case 6:
      category = 11;
      costType = 1;
      eventName = 'trip';
      break;
    case 7:
      category = 12;
      costType = 1;
      eventName = 'car';
      break;
    // temp
    case 8:
      category = 13;
      costType = 1;
      eventName = 'pet';
      break;
    case 9:
      category = event.other.detail == 2 ? 7 : 10;
      costType = 1;
      eventName = 'family';
      break;
    // temp
    case 10:
      category = 10;
      costType = 1;
      eventName = 'secondcarrier';
      break;
    // temp
    case 11:
      category = 14;
      costType = 1;
      eventName = 'hobby';
      break;
  }

  return { category: category, cost_type: costType, event_name: eventName }
}

const lifeEventRunningCost = (event, personAge) => {
  const currentYear = new Date().getFullYear()
  var runningCost = {}

  runningCost['start_age'] = parseInt(personAge) + parseInt(event.year) - currentYear;
  runningCost['end_age'] = event.end_year ? (parseInt(personAge) + parseInt(event.end_year) - currentYear) : null;
  runningCost['loop_year'] = null;

  switch(event.event_type) {
    case 1:
      runningCost['end_age'] = event.end_year ? (parseInt(personAge) + parseInt(event.end_year) - currentYear) : null;
      switch(parseInt(event.re_buy.buy_property)) {
        case 1:
          runningCost['cost_at_first'] = 1000 * 10000
          break;
        case 2:
          runningCost['cost_at_first'] = 3000 * 10000
          break;
        case 3:
          runningCost['cost_at_first'] = 5000 * 10000
          break;
        case 4:
          runningCost['cost_at_first'] = 4000 * 10000
          break;
        case 5:
          runningCost['cost_at_first'] = 9000 * 10000
          break;
        case 6:
          runningCost['cost_at_first'] = 15000 * 10000
          break;
        case 7:
          runningCost['cost_at_first'] = 10000 * 10000
          break;
        case 8:
          runningCost['cost_at_first'] = 20000 * 10000
          break;
        case 9:
          runningCost['cost_at_first'] = 30000 * 10000
          break;
      }

      runningCost['cost_for_one_time'] = 40 * 10000;
      runningCost['how_many_years'] = 1;
      runningCost['how_many_times'] = 1;
      break;
    case 3:
      runningCost['end_age'] = event.end_year ? (parseInt(personAge) + parseInt(event.end_year) - currentYear) : null;
      runningCost['cost_for_one_time'] = 40 * 10000;
      runningCost['how_many_years'] = 1;
      runningCost['how_many_times'] = 1;

      switch(parseInt(event.house_buy.buy_property)) {
        case 1:
          runningCost['cost_at_first'] = 4600 * 10000;
          break;
        case 2:
          runningCost['cost_at_first'] = 3000 * 10000;
          break;
        case 3:
          runningCost['cost_at_first'] = 4000 * 10000;
          break;
        case 4:
          runningCost['cost_at_first'] = 5000 * 10000;
          break;
        case 5:
          runningCost['cost_at_first'] = 3500 * 10000;
          break;
        case 6:
          runningCost['cost_at_first'] = 2500 * 10000;
          break;
        case 7:
          runningCost['cost_at_first'] = 3000 * 10000;
          runningCost['cost_for_one_time'] = 50 * 10000;
          break;
        case 8:
          runningCost['cost_at_first'] = 4500 * 10000;
          runningCost['cost_for_one_time'] = 80 * 10000;
          break;
        case 9:
          runningCost['cost_at_first'] = 4000 * 10000;
          runningCost['cost_for_one_time'] = 50 * 10000;
          break;
        case 10:
          runningCost['cost_at_first'] = 5500 * 10000;
          runningCost['cost_for_one_time'] = 80 * 10000;
          break;
        case 11:
          runningCost['cost_at_first'] = 4000 * 10000;
          runningCost['cost_for_one_time'] = 80 * 10000;
          break;
        case 12:
          runningCost['cost_at_first'] = event.house_buy.buy_cost * 10000 || 0;
          runningCost['cost_for_one_time'] = 0;
          runningCost['how_many_years'] = null;
          runningCost['how_many_times'] = 0;
          break;
      }
      break;
    case 5:
      runningCost['cost_at_first'] = 0;
      runningCost['how_many_years'] = 1;
      runningCost['how_many_times'] = 12;

      switch (parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_for_one_time'] = 0.6 * 10000;
          break;
        case 2:
          runningCost['cost_for_one_time'] = 1.2 * 10000;
          break;
        case 3:
          runningCost['cost_for_one_time'] = 2 * 10000;
          break;
        case 4:
          runningCost['cost_for_one_time'] = 4 * 10000;
          break;
        case 5:
          runningCost['cost_for_one_time'] = 4 * 10000;
          break;
        case 6:
          runningCost['cost_for_one_time'] = 8 * 10000;
          break;
        case 7:
          runningCost['cost_for_one_time'] = parseInt(event.other.price) * 10000 || 0;
          if (+event.other?.price > 0) {
            runningCost['how_many_years'] = 1;
            runningCost['how_many_times'] = 12;
          } else {
            runningCost['how_many_years'] = null;
            runningCost['how_many_times'] = 0;
          }
          break;
      }
      break;
    case 6:
      runningCost['cost_at_first'] = 0;
      runningCost['how_many_years'] = parseInt(event.other.frequency_year);
      runningCost['how_many_times'] = parseInt(event.other.number_of_people);

      switch(parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_for_one_time'] = 3 * 10000
          break;
        case 2:
          runningCost['cost_for_one_time'] = 5 * 10000
          break;
        case 3:
          runningCost['cost_for_one_time'] = 5 * 10000
          break;
        case 4:
          runningCost['cost_for_one_time'] = 8 * 10000
          break;
        case 5:
          runningCost['cost_for_one_time'] = 10 * 10000
          break;
        case 6:
          runningCost['cost_for_one_time'] = 40 * 10000
          break;
        case 7:
          runningCost['cost_for_one_time'] = 50 * 10000
          break;
        case 8:
          runningCost['cost_for_one_time'] = 60 * 10000
          break;
        case 9:
          runningCost['cost_for_one_time'] = 70 * 10000
          break;
        case 10:
          runningCost['cost_for_one_time'] = 300 * 10000
          break;
        case 11:
          runningCost['cost_for_one_time'] = 1300 * 10000
          break;
        case 12:
          runningCost['cost_for_one_time'] = parseInt(event.other.price) * 10000 || 0
          break;
      }
      break;
    case 7:
      runningCost['how_many_years'] = 1;
      runningCost['how_many_times'] = 1;
      runningCost['loop_year'] = parseInt(event.other.frequency_year)

      switch(parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_at_first'] = 200  * 10000
          runningCost['cost_for_one_time'] = 30 * 10000;
          break;
        case 2:
          runningCost['cost_at_first'] = 400  * 10000
          runningCost['cost_for_one_time'] = 30 * 10000;
          break;
        case 3:
          runningCost['cost_at_first'] = 500  * 10000
          runningCost['cost_for_one_time'] = 30 * 10000;
          break;
        case 4:
          runningCost['cost_at_first'] = 800  * 10000
          runningCost['cost_for_one_time'] = 40 * 10000;
          break;
        case 5:
          runningCost['cost_at_first'] = 600  * 10000
          runningCost['cost_for_one_time'] = 40 * 10000;
          break;
        case 6:
          runningCost['cost_at_first'] = 1000  * 10000
          runningCost['cost_for_one_time'] = 40 * 10000;
          break;
        case 7:
          runningCost['cost_at_first'] = 2000  * 10000
          runningCost['cost_for_one_time'] = 40 * 10000;
          break;
        case 8:
          runningCost['cost_at_first'] = parseInt(event.other.cost_at_first) * 10000 || 0;
          runningCost['cost_for_one_time'] = 0;
          runningCost['how_many_years'] = null;
          runningCost['how_many_times'] = 0;
          break;
      }
      break;
    case 8:
      runningCost['how_many_years'] = 1;
      runningCost['how_many_times'] = 1;

      switch(parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_at_first'] = 5 * 10000
          runningCost['cost_for_one_time'] = 3 * 10000;
          break;
        case 2:
          runningCost['cost_at_first'] = 15 * 10000
          runningCost['cost_for_one_time'] = 16 * 10000;
          break;
        case 3:
          runningCost['cost_at_first'] = 20 * 10000
          runningCost['cost_for_one_time'] = 34 * 10000;
          break;
        case 4:
          runningCost['cost_at_first'] = 30 * 10000
          runningCost['cost_for_one_time'] = 34 * 10000;
          break;
        case 5:
          runningCost['cost_at_first'] = 5 * 10000
          runningCost['cost_for_one_time'] = 5 * 10000;
          break;
        case 6:
          runningCost['cost_at_first'] = 8 * 10000
          runningCost['cost_for_one_time'] = 15 * 10000;
          break;
        case 7:
          runningCost['cost_at_first'] = 8 * 10000
          runningCost['cost_for_one_time'] = 10 * 10000;
          break;
        case 8:
          runningCost['cost_at_first'] = parseInt(event.other.cost_at_first) * 10000 || 0;
          runningCost['cost_for_one_time'] = parseInt(event.other.price) * 10000 || 0;
          if (+event.other?.price > 0) {
            runningCost['how_many_years'] = 1;
            runningCost['how_many_times'] = 1;
          } else {
            runningCost['how_many_years'] = null;
            runningCost['how_many_times'] = 0;
          }
          break;
      }
      break;
    case 9:
      runningCost['how_many_years'] = 0;
      runningCost['how_many_times'] = 0;

      switch(parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_at_first'] = 350 * 10000
          runningCost['cost_for_one_time'] = 0;
          runningCost['end_age'] = null;
          break;
        case 2:
          runningCost['cost_at_first'] = 50 * 10000
          runningCost['cost_for_one_time'] = 0;
          runningCost['end_age'] = null;
          break;
        case 3:
          runningCost['cost_at_first'] = 200 * 10000
          runningCost['cost_for_one_time'] = 0;
          runningCost['end_age'] = null;
          break;
        case 4:
          runningCost['cost_at_first'] = 100 * 10000
          runningCost['cost_for_one_time'] = 15 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 5:
          runningCost['cost_at_first'] = parseInt(event.other.cost_at_first) * 10000 || 0;
          runningCost['cost_for_one_time'] = parseInt(event.other.price) * 10000 || 0;
          if (+event.other?.price > 0) {
            runningCost['how_many_years'] = 1;
            runningCost['how_many_times'] = 1;
          } else {
            runningCost['how_many_years'] = null;
            runningCost['how_many_times'] = 0;
          }
          break;
      }
      break;
    case 10:
      runningCost['how_many_years'] = 1;
      runningCost['how_many_times'] = 1;

      switch(parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_at_first'] = 900 * 10000
          runningCost['cost_for_one_time'] = 0;
          break;
        case 2:
          runningCost['cost_at_first'] = 1000 * 10000
          runningCost['cost_for_one_time'] = 0;
          break;
        case 3:
          runningCost['cost_at_first'] = 1000 * 10000
          runningCost['cost_for_one_time'] = 0;
          break;
        case 4:
          runningCost['cost_at_first'] = 1500 * 10000
          runningCost['cost_for_one_time'] = 0;
          break;
        case 5:
          runningCost['cost_at_first'] = 300 * 10000
          runningCost['cost_for_one_time'] = 0;
          break;
        case 6:
          runningCost['cost_at_first'] = 800 * 10000
          runningCost['cost_for_one_time'] = 0;
          break;
        case 7:
          runningCost['cost_at_first'] = 30 * 10000
          runningCost['cost_for_one_time'] = 150 * 10000;
          runningCost['end_age'] = runningCost['start_age'] + 3 > 99 ? 99 : runningCost['start_age'] + 3
          break;
        case 8:
          runningCost['cost_at_first'] = parseInt(event.other.cost_at_first) * 10000 || 0;
          runningCost['cost_for_one_time'] = 0;
      }
      break;
    case 11:
      switch(parseInt(event.other.detail)) {
        case 1:
          runningCost['cost_at_first'] = 1 * 10000
          runningCost['cost_for_one_time'] = 0.8 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 2:
          runningCost['cost_at_first'] = 10 * 10000
          runningCost['cost_for_one_time'] = 1 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 1;
          break;
        case 3:
          runningCost['cost_at_first'] = 5 * 10000
          runningCost['cost_for_one_time'] = 10 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 1;
          break;
        case 4:
          runningCost['cost_at_first'] = 7 * 10000
          runningCost['cost_for_one_time'] = 1 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 5:
          runningCost['cost_at_first'] = 7 * 10000
          runningCost['cost_for_one_time'] = 10 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 1;
          break;
        case 6:
          runningCost['cost_at_first'] = 700 * 10000
          runningCost['cost_for_one_time'] = 40 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 1;
          break;
        case 7:
          runningCost['cost_at_first'] = 10 * 10000
          runningCost['cost_for_one_time'] = 3 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 6;
          break;
        case 8:
          runningCost['cost_at_first'] = 150 * 10000
          runningCost['cost_for_one_time'] = 7 * 10000;
          runningCost['how_many_years'] = 4;
          runningCost['how_many_times'] = 1;
          break;
        case 9:
          runningCost['cost_at_first'] = 100 * 10000
          runningCost['cost_for_one_time'] = 70 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 10:
          runningCost['cost_at_first'] = 800 * 10000
          runningCost['cost_for_one_time'] = 100 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 1;
          break;
        case 11:
          runningCost['cost_at_first'] = 1 * 10000
          runningCost['cost_for_one_time'] = 5 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 12:
          runningCost['cost_at_first'] = 5 * 10000
          runningCost['cost_for_one_time'] = 0.8 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 13:
          runningCost['cost_at_first'] = 1.5 * 10000
          runningCost['cost_for_one_time'] = 1 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 12;
          break;
        case 14:
          runningCost['cost_at_first'] = 50 * 10000
          runningCost['cost_for_one_time'] = 10 * 10000;
          runningCost['how_many_years'] = 1;
          runningCost['how_many_times'] = 1;
          break;
        case 15:
          runningCost['cost_at_first'] = parseInt(event.other.cost_at_first) * 10000 || 0;
          runningCost['cost_for_one_time'] = parseInt(event.other.price) * 10000 || 0;
          if (+event.other?.price > 0) {
            runningCost['how_many_years'] = 1;
            runningCost['how_many_times'] = 1;
          } else {
            runningCost['how_many_years'] = null;
            runningCost['how_many_times'] = 0;
          }
      }
      break;
    default:
      runningCost['cost_at_first'] = 0;
      runningCost['cost_for_one_time'] = 0;
      runningCost['how_many_years'] = null;
      runningCost['how_many_times'] = 0;
      break;
  }

  return { running_cost: runningCost };
}

const lifeEventCustomField = (event, personAge) => {
  var customField = {
    re_buy: {
      real_estate_buy_age: null,
      re_buy_model: null,
      own_fund: 0,
      interest_rate: 0,
      repayment_period: 0
    },
    house_buy: {
      start_age: null,
      end_age: null,
      own_fund: 0,
      interest_rate: 0,
      cost_at_first: 0,
      cost_for_one_time: 0,
      how_many_years: null,
      how_many_times: 0
    },
    house_sell: {
      house_sale_age: null,
      house_sale_price: 0,
      month_rent: 0
    }
  }
  const currentYear = new Date().getFullYear();
  const age = parseInt(personAge) || 0;

  switch(event.event_type) {
    case 1:
      customField = {
        ...customField,
        re_buy: {
          real_estate_buy_age: parseInt(event.year) - currentYear + age,
          re_buy_model: parseInt(event.re_buy.buy_property) || null,
          own_fund: parseInt(event.re_buy.own_fund) * 10000 || 0,
          interest_rate: +event.re_buy.loan_rate,
          repayment_period: (event.end_year - event.year + 1) * 12
        }
      }
      break;
    case 3:
      const { running_cost } = lifeEventRunningCost(event, age);

      customField = {
        ...customField,
        house_buy: {
          start_age: event.year - currentYear + age,
          end_age: event.end_year - currentYear + age,
          own_fund: event.house_buy.own_fund * 10000,
          interest_rate: +event.house_buy.loan_rate,
          cost_at_first: running_cost.cost_at_first,
          cost_for_one_time: running_cost.cost_for_one_time,
          how_many_years: running_cost.how_many_years,
          how_many_times: running_cost.how_many_times
        }
      }
      break;
    case 4:
      customField = {
        ...customField,
        house_sell: {
          house_sale_age: event.year - currentYear + age,
          house_sale_price: event.house_sell.sell_price * 10000,
          month_rent: event.house_sell.rent * 10000
        }
      }
      break;
  }

  return customField;
}

const mapLifeEvents = (liveEvents, personAge) => {
  var lifeEventsCustom = []

  liveEvents.map(event => {
    let eventCustom = {
      ...lifeEventCategory(event),
      event_type: event.event_type,
      ...lifeEventRunningCost(event, personAge),
      ...lifeEventCustomField(event, personAge)
    }

    lifeEventsCustom.push(eventCustom);
  })

  return lifeEventsCustom;
}

const lifeEvents = (state) => {
  const { life_event, person_age } = state;

  return {
    life_events: [...mapLifeEvents(life_event, person_age)]
  }
}

export const simulationParams = (state) => {
  return {
    ...familyStructure(state),
    ...educationCostPlan(state),
    ...income(state),
    ...expenditure(state),
    ...depositSavings(state),
    ...ownedRealEstates(state),
    ...lifeEvents(state)
  }
}
