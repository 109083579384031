import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import LifePlanModal from '../../shared/components/modal';
import { generateYearsByMemberAge, ModalName } from '../../shared/constants/global';
import { closeModal, openSubModal, saveSelectedLifeEvent } from '../../redux/lifePlan';
import SelectFieldSecond from '../../shared/components/select-field-second';
import CommaInput from '../../shared/components/comma-input-field';
import { YupSchema } from '../../shared/constants/validators';

export default function EventSaleHomeModal(props) {
  const { t } = useTranslation('eventSaleHomeModal');
  const tGlobal = useTranslation('global').t;

  const dispatch = useDispatch();
  const currentAge = useSelector(state => state.life_plan.person_age);
  const lifePlan = useSelector(state => state.life_plan);

  const { lifeEvent } = props;
  const [initialValues, setInitialValues] = useState({ ...props.lifeEvent, house_sell: { ...props.lifeEvent.house_sell } });
  const formState = useRef();

  const validateSchema = useMemo(() => {
      return new YupSchema(t).eventSaleHomeModal;
  }, [])


  const handleClose = () => {
    dispatch(closeModal());
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()
    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      dispatch(saveSelectedLifeEvent(values));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    if (subYear(year) === 0) {
      return `${year}年`
    } else {
      return t('renderYearFromNow', {subYear: subYear(year), yearFromNow: year})
    }
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age - currentAge;
  }

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  const calcLoan = (year, sell_price) => {
    var { housing_loan_balance, housing_bonus_repayment, housing_monthly_repayment, housing_loan } = lifePlan;

    if (housing_loan === 0) {
      return 0;
    } else {
      housing_loan_balance ||= 0;
      housing_bonus_repayment ||= 0;
      housing_monthly_repayment ||= 0;

      const result = housing_loan_balance - housing_bonus_repayment * 2 * parseInt(year) - housing_monthly_repayment * 12 * parseInt(year) - parseInt(sell_price);

      return result > 0 ? result : 0
    }
  }

  useEffect(() => {
    setInitialValues({ ...props.lifeEvent, house_sell: { ...props.lifeEvent.house_sell } })
  }, [props.lifeEvent])

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_SELL_HOME} >
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup">
                  <h2>{t('label')}</h2>
                  <div className="popupContents marT40">
                    <dl className="formSty">
                      <dt>{t('expecteSaleTime')}</dt>
                      <dd>
                        <div className="dF aisC maxWidth400">
                          {/* useMemo for options */}
                          <Field component={SelectFieldSecond} width="wid200" name="year" options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                        </div>
                      </dd>
                      <dt>{t('estimateSalePrice')}</dt>
                      <dd>
                        <Field className="wid120" type="text" component={CommaInput} name="house_sell.sell_price" secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                      </dd>
                    </dl>
                    <div className="aiAssesBox gradBluBg gradBluBg4 marB24 scrollInAnim isScroll">
                      <div className="whiteBox">{subYear(values.year) == 0 ? '現在' : `${subYear(values.year)}年後`}のローン残債は<span>{calcLoan(subYear(values.year), values.house_sell.sell_price)}万円</span>です</div>
                    </div>
                    <dl className="formSty marB40">
                      <dt dangerouslySetInnerHTML={{__html: t('afterSale')}} />
                      <dd>
                        <Field className="wid120" type="text" component={CommaInput} name="house_sell.rent" secondLabel={t('unit')} setFieldValue={setFieldValue} />
                      </dd>
                    </dl>
                    <div className="popupBtnBox">
                      <ul className="btnList col2">
                        <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                        <li><button type='submit' className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                        { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
