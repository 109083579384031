import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import CommaInput from '../../shared/components/comma-input-field';
import SelectFieldSecond from '../../shared/components/select-field-second';
import LifePlanModal from '../../shared/components/modal';
import { ModalName, ValidateField, generateYearsByMemberAge } from '../../shared/constants/global';
import { closeModal, openSubModal, saveSelectedLifeEvent} from '../../redux/lifePlan';
import { LifePlansIcons } from '../../assets/icons';
import { YupSchema } from '../../shared/constants/validators';
const {iconBldgSmall, iconBldgMiddle, iconBldgApartSmall, iconBldgApartMiddle, iconBldgBig, iconBldgBig01, iconBldgApartBig} = LifePlansIcons;
import { cloneDeep } from "lodash";
import { calcLoanPreiod } from '../../shared/utils';
import { AffectLoanPeriod } from '../../core/classes/enum';


export default function EventPurchaseRealestateModal(props) {
  const { t } = useTranslation('eventPurchaseRealestateModal');
  const tGlobal = useTranslation('global').t;
  const types = t('types', { returnObjects: true });
  const dispatch = useDispatch();
  const { lifeEvent } = props;

  const currentAge = useSelector(state => state.life_plan.person_age);

  const [tabIndex, setTabIndex] = useState(0);

  const formState = useRef();

  const validateSchema = useMemo(() => {
    return new YupSchema(t).eventPurcharseRealEsate;
  }, [])

  const [initialValues, setInitialValues] = useState(() => {
    const values = calcLoanPreiod(props, AffectLoanPeriod.RE_BUY);
    return values;
  });

  const handleSetTabIndex = (values, index) => {
    setTabIndex(index);
    const newValues = {
      ...values,
      re_buy: {
        ...values?.re_buy,
        buy_property: index === 0 ? 1 : index === 1 ? 4 : 7
      }
    }
    setInitialValues(newValues);
  }

  const handleDataTabIndex = (property) => {
    if ([1, 2, 3].includes(property)) {
      return 0
    } else if ([4, 5, 6].includes(property)) {
      return 1
    } else {
      return 2
    }
  }

  const handleClose = () => {
    dispatch(closeModal());
  }

  const getBuyPropertyName = (buy_property) => {
    switch (buy_property) {
      case 1:
        return "郊外1R"
      case 2:
        return "都内1LDK"
      case 3:
        return "都心1LDK"
      case 4:
        return "郊外1K×6戸"
      case 5:
        return "都内1R×8戸"
      case 6:
        return "都心1R×10戸"
      case 7:
        return "郊外1K×10戸"
      case 8:
        return "都内1R×12戸"
      case 9:
        return "郊外1棟店舗"
      default:
        return ""
    }
  }

  const prepareData = (values) => {
    const tempData = cloneDeep(values);
    let params = {
      ...tempData,
      end_year: (+tempData.year + +tempData.re_buy?.loan_period - 1) || 0,
      re_buy: {
        ...tempData.re_buy,
        buy_property_name: getBuyPropertyName(+tempData.re_buy?.buy_property)
      }
    }
    delete params.re_buy.loan_period;

    return params;
  }

  const handleSubmitFinal = async () => {
    await formState.current.setFieldTouched();
    await formState.current.validateForm()

    if (!formState.current.isValid) {
      console.log('form invalid');
      return;
    }

    const { values } = formState.current;
    try {
      const data = prepareData(values);
      dispatch(saveSelectedLifeEvent(data));
    } catch (e) {
      console.log(e)
    }
  }

  const yearMapping = {}
  generateYearsByMemberAge().map((y) => yearMapping[y.year] = y.age)

  const renderYearFromNow = (year) => {
    if (subYear(year) === 0) {
      return `${year}年`
    } else {
      return t('renderYearFromNow', {subYear: subYear(year), yearFromNow: year})
    }
  }

  const subYear = (year) => {
    const age = yearMapping[year];
    return age - currentAge;
  }

  const dataEndYear = useMemo(() => {
    let result = []
    for (let i = 1; i <= ValidateField.Global.MIN_LOAN_YEAR; i++) {
      result.push(i)
    }
    return result;
  }, [])

  const handleRemove = () => {
    dispatch(openSubModal(ModalName.LIFEPLAN_CONFIRM_DELETE))
  }

  useEffect(() => {
    const values = calcLoanPreiod(props, AffectLoanPeriod.RE_BUY);
    setInitialValues(values)
    setTabIndex(handleDataTabIndex(+values.re_buy.buy_property))
  }, [props.lifeEvent])

  return <LifePlanModal name={ModalName.LIFEPLAN_EVENT_PURCHASE_REALESTATE}>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validateSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        formState.current = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="popupBox">
              <div className="popupInner">
                <div className="lifeplanPopup realestateBuy">
                  <h2 className="padB44">{t('label')}</h2>
                  <div className="popupContents">

                    <div className="tabBox saleAItabBox tabPurchaseBox">
                      <div className="saleAItabTitSty noBg">
                        <ul className="tabBtn tabPurchase">
                          {types.map((type, index) => <li key={index} onClick={() => handleSetTabIndex(values, index)} className={`hoverItem ${tabIndex === index ? 'active' : ''}`}><span>{type}</span></li>)}
                        </ul>
                      </div>

                      <div className="tabContents revenueBtn">
                        <div className={`tabPanel ${tabIndex === 0 ? 'active': ''}`}>
                          <div className="radioImgBox bgGradGray pad24">
                            <ul className="radioImgBtnList propertyTabBtnList nowrap rowList">
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 1 ? "checked" : ""}`}><Field type="radio" value="1" name="re_buy.buy_property" /><img src={iconBldgSmall} />
                                <span><span className="font20">郊外1R<br />築30年</span><span className="font24">1,000万円</span></span></label></li>
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 2 ? "checked" : ""}`}><Field type="radio" value="2" name="re_buy.buy_property" /><img src={iconBldgMiddle} />
                                <span><span className="font20">都内1LDK<br />築20年</span><span className="font24">3,000万円</span></span></label></li>
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 3 ? "checked" : ""}`}><Field type="radio" value="3" name="re_buy.buy_property" /><img src={iconBldgBig01} />
                                <span><span className="font20">都心1LDK<br />築浅</span><span className="font24">5,000万円</span></span></label></li>
                            </ul>
                          </div>
                        </div>

                        <div className={`tabPanel apart ${tabIndex === 1 ? 'active': ''}`}>
                          <div className="radioImgBox bgGradGray pad24">
                            <ul className="radioImgBtnList propertyTabBtnList nowrap rowList">
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 4 ? "checked" : ""}`}><Field type="radio" value="4" name="re_buy.buy_property" /><img src={iconBldgApartSmall} />
                                <span><span className="font20">郊外1K×6戸<br />築30年</span><span className="font24">4,000万円</span></span></label></li>
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 5 ? "checked" : ""}`}><Field type="radio" value="5" name="re_buy.buy_property" /><img src={iconBldgApartMiddle} />
                                <span><span className="font20">都内1R×8戸<br />築20年</span><span className="font24">9,000万円</span></span></label></li>
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 6 ? "checked" : ""}`}><Field type="radio" value="6" name="re_buy.buy_property" /><img src={iconBldgApartBig} />
                                <span><span className="font20">都心1R×10戸<br />築浅</span><span className="font24">1億5,000万円</span></span></label></li>
                            </ul>
                          </div>
                        </div>

                        <div className={`tabPanel ${tabIndex === 2 ? 'active': ''}`}>
                          <div className="radioImgBox bgGradGray pad24">
                            <ul className="radioImgBtnList propertyTabBtnList nowrap rowList">
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 7 ? "checked" : ""}`}><Field type="radio" value="7" name="re_buy.buy_property" /><img src={iconBldgSmall} />
                                <span><span className="font20">郊外1K×10戸<br />築30年</span><span className="font24">1億円</span></span></label></li>
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 8 ? "checked" : ""}`}><Field type="radio" value="8" name="re_buy.buy_property" /><img src={iconBldgMiddle} />
                                <span><span className="font20">都内1R×12戸<br />築20年</span><span className="font24">2億円</span></span></label></li>
                              <li><label className={`radioBtn ${values.re_buy.buy_property == 9 ? "checked" : ""}`}><Field type="radio" value="9" name="re_buy.buy_property" /><img src={iconBldgBig} />
                                <span><span className="font20">郊外1棟店舗<br />築20年</span><span className="font24">3億円</span></span></label></li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <dl className="formSty marB40">
                        <dt>{t('purchaseTime')}</dt>
                        <dd>
                          <div className="dF aisC maxWidth400">
                            <Field component={SelectFieldSecond} width="wid200" name="year" options={Object.keys(yearMapping)} display_options={renderYearFromNow} />
                          </div>
                        </dd>
                        <dt>{t('ownResources')}</dt>
                        <dd>
                          <Field className="wid120" type="text" component={CommaInput} name="re_buy.own_fund" secondLabel={tGlobal('tenThousandYen')} setFieldValue={setFieldValue} />
                        </dd>
                        <dt>{t('interestRate')}</dt>
                        <dd>
                          <Field className="wid120" type="text" decimal={2} component={CommaInput} name="re_buy.loan_rate" secondLabel={"%"} setFieldValue={setFieldValue} />
                        </dd>
                        <dt>{t('loanPeriod')}</dt>
                        <dd className="d-flex">
                          <div className="selectStyBox wid200">
                            <Field component={SelectFieldSecond} name="re_buy.loan_period" options={dataEndYear} />
                          </div>
                          <span className="formUnit">{tGlobal('yearly')}</span>
                        </dd>
                      </dl>
                      <div className="popupBtnBox">
                        <ul className="btnList col2">
                          <li><button type="button" className="btnSty-back" onClick={handleClose}>{tGlobal('cancel')}</button></li>
                          <li><button type="submit" className="btnSty-send" onClick={handleSubmitFinal}>{tGlobal('send')}</button></li>
                          { lifeEvent && lifeEvent.uuid && <li className="btnSty-delete"><button type="button" onClick={handleRemove}>{tGlobal('delete')}</button></li> }
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  </LifePlanModal>
}
