import styled from 'styled-components';
import { scale } from '../../common/animations';
import defaultTheme from '../../theme';

const Bubble = styled.div`
`;

Bubble.defaultProps = {
  theme: defaultTheme
};

export default Bubble;
