import React from 'react';

export default function EventGroupHeader(props) {
  return (
    <React.Fragment>
      <div className="timelineEventGroupHeader" style={{"visibility": props.isShow ? "visible" : "hidden"}}>
        <div className="year">{props.label.year}</div>
        <div className="sub">({props.label.age}歳)</div>
      </div>
    </React.Fragment>
  )
}
