import React, { useState, useEffect } from 'react';
import ResultAnalysisRow from "../lps-analysis-row";
import {convertToJapanCurrency} from "../../shared/constants/global";


export default function ResultGraphSpendingWidget(props) {
    const { loggedIn, results } = props;
    const [total, setTotal] = useState(0)

    const convertTextCategory = (category_id) => {
        switch (category_id) {
            case 7:
              return "教育費"
            case 8:
              return "住居費"
            case 9:
              return "生活費"
            case 10:
              return "その他支出"
            case 11:
              return "旅費"
            case 12:
              return "自動車"
            case 13:
              return "ペット"
            case 14:
              return "趣味"
        }
    }

    const calculateTotal = () => {
      let total = 0;
      results.forEach((result) => {
        total = total + result.value;
      })

      return total;
    }

    useEffect(() => {
      try {
        setTotal(calculateTotal())
      } catch (e) {
        console.log(e)
      }
    }, [results])

    const canShowData = (category, value) => {
      if (category == 6) return false

      const categoryCanHidden = [11, 12, 13, 14];
      if(categoryCanHidden.includes(category) && value === 0){
        return false;
      }
      return true;
    }

    return (
        <div className="resultGraph spending">
            <table>
              <tbody>
                {
                    results.map((result) => canShowData(result.category, result.value) ? (
                        <ResultAnalysisRow key={result.category} loggedIn={loggedIn} incomeLabel={convertTextCategory(result.category)} incomeValue={convertToJapanCurrency(result.value)} />
					          ) : null)
                }
                <ResultAnalysisRow loggedIn={loggedIn} incomeLabel={"合計"} incomeValue={convertToJapanCurrency(total)} />
              </tbody>
            </table>
        </div>
    )
}
