import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/lifePlan';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';

export default function SaveModal(props) {
  const { t } = useTranslation('saveModal')
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_SAVE}>
    <div className="popupBox">
      <div className="popupInner alsC">
        <div className="favoritePopup">
          <p>{t('saved')}</p>
          <button type="button" className="btnSty-blue" onClick={handleClose}>{t('ok')}</button>
        </div>
      </div>
    </div>
  </LifePlanModal>;
}
