import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { openModal, setLifeEventSelected, setIsDragging as setIsGlobalDragging, closeModal, setInputLifePlan } from "../../redux/lifePlan/slice";
import { ModalName } from '../../shared/constants/global';
import { LifePlanService } from '../../core/services/life-plans';
import LpsLifeEventGraphWidget from '../../widgets/lps-life-event-graph-widget';
import PrepareModalWidget from '../../widgets/prepare-modal-widget';
import LpsResultBoxWidget from '../../widgets/lps-result-box-widget';
import MainChart from '../../widgets/lps-main-chart';
import icoHumanL from 'images/mascot/mascot1.png';

export default function LifeEvent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const loggedIn = gon.logged_in;

  const [tabIndex, setTabIndex] = useState(0);
  const [isFadeInFixedButton, setIsFadeIn] = useState(false);

  const dispatch = useDispatch();

  const lifeEventSelected = useSelector(state => state.lifeEventSelected)

  const personAge = useSelector(state => state.life_plan.person_age);
  const numberOfPeople = useSelector(state => state.life_plan.number_of_people);

  const lifePlanState = useSelector(state => state.life_plan);

  const handleOpenModal = (target) => {
    if (typeof target === "string") {
      return dispatch(openModal(target));
    }

    // target eq EventMapping
    const newEvent = target.getDefaultValues()
    dispatch(setLifeEventSelected(newEvent))
    dispatch(openModal(target.modal_name));
  }

  // drag ghost icon
  const handleDragStartEvent = (lifeEvent, event) => {
    const ghostEle = document.querySelector(`.timelineEventGhost.timelineEventGhost_${lifeEvent.id} .timelineEvent`);
    dispatch(setLifeEventSelected(lifeEvent.getDefaultValues()))
    event.dataTransfer.setDragImage(ghostEle, 31, 31); // event width: 63px
    setTimeout(() => {
      dispatch(setIsGlobalDragging(true))
    }, 0);
  }

  const handleSubmitLifePlan = async (openSaveModal = true) => {
    if (loggedIn) {
      try {
        await LifePlanService.registerLifePlans(lifePlanState)
        if (openSaveModal) {
          handleOpenModal(ModalName.LIFEPLAN_SAVE)
        }
      } catch (e) {
        console.log('error', e);
      }
    } else {
      const lifePlanStr = JSON.stringify(lifePlanState)
      window.lsSession.setItem("life_plans", lifePlanStr)
      if (openSaveModal) {
        handleOpenModal(ModalName.LIFEPLAN_SAVE)
      }
    }
  }

  const handleNextModal = (step) => {
    setStep(step + 1)
  }

  const handlePrevModal = (step) => {
    setStep(step - 1)
  }

  const handleDataNewYear = (data) => {
    const cycleEventTypes = [5, 6, 7, 8, 9, 10, 11]
    let life_event = []
    data.life_event.forEach(e => {
      if (+e.year >= new Date().getFullYear()) { return life_event.push(e) }

      if (cycleEventTypes.includes(e.event_type)) {
        if ((+e.year + (+e.other.frequency_year || 1) > +e.end_year) || +e.end_year < new Date().getFullYear()) return

        let y
        for ( let i = +e.year; i <= +e.end_year; i += (+e.other.frequency_year || 1)) {
          if (i >= new Date().getFullYear()) { 
            y = i
            break
          }
        }
        life_event.push({ ...e, year: y })
      }
    })

    const maturity = data?.maturity ? (data?.maturity < new Date().getFullYear() ? new Date().getFullYear() : data?.maturity) : null
    const housing_repayment_end = data?.housing_repayment_end ? (data?.housing_repayment_end < new Date().getFullYear() ? new Date().getFullYear() : data?.housing_repayment_end) : null
    const other_income_end = data?.other_income_end ? (data?.other_income_end < new Date().getFullYear() ? new Date().getFullYear() : data?.other_income_end) : null
    const reserve_end = data?.reserve_end ? (data?.reserve_end < new Date().getFullYear() ? new Date().getFullYear() : data?.reserve_end) : null
    const term_insurance_end = data?.term_insurance_end ? (data?.term_insurance_end < new Date().getFullYear() ? new Date().getFullYear() : data?.term_insurance_end) : null
    
    const setDataLifePlan = {
                      ...data,
                      life_event: life_event,
                      maturity: maturity,
                      housing_repayment_end: housing_repayment_end,
                      other_income_end: other_income_end,
                      reserve_end: reserve_end,
                      term_insurance_end: term_insurance_end
                    }

    return setDataLifePlan
  }

  const updateDataLifePlan = async () => {
    const local_storage_data = window.lsSession.getItem("life_plans")
    let lifePlanStr
    if (local_storage_data) {
      lifePlanStr = handleDataNewYear(JSON.parse(local_storage_data))
      window.lsSession.setItem("life_plans", JSON.stringify(lifePlanStr))
    } else if (loggedIn && gon.life_plan && !local_storage_data) {
      lifePlanStr = handleDataNewYear(gon.life_plan)
      try {
        await LifePlanService.registerLifePlans(lifePlanStr)
      } catch (e) {
        console.log('error', e);
      }
    }

    if (!lifePlanStr) return
    dispatch(setInputLifePlan(lifePlanStr))
    if (lifePlanStr.person_age) return navigate('results', { replace: true })
  }

  useLayoutEffect(() => {
    let pathName = location.pathname.replace('/life_plans/', '').replace('/life_plans', '');
    if (pathName != 'results' && step == 0) {
      handleOpenModal(ModalName.LIFEPLAN_WELCOME)
    } else if (pathName != 'results' && step == 1) {
      handleOpenModal(ModalName.LIFEPLAN_FAMILY)
    } else if (pathName != 'results' && step == 2) {
      handleOpenModal(ModalName.LIFEPLAN_INPUT_SPOUSE)
    } else if (pathName != 'results' && step == 3) {
      handleOpenModal(ModalName.LIFEPLAN_INPUT_SPENDING)
    } else if (pathName != 'results' && step == 4) {
      handleOpenModal(ModalName.LIFEPLAN_TUTORIAL)
      document.getElementById('lspGrapChart').classList.remove('buttonQuestion');
    } else {
      document.getElementById('lspGrapChart').classList.add('buttonQuestion');
      dispatch(closeModal());
    }
  }, [step, location.pathname]);

  useEffect(() => {
    updateDataLifePlan();
  }, [])

  useEffect(() => {
    localStorage.removeItem('yaxis')
  }, [tabIndex])

  return (
    <React.Fragment>

      <main className="mainContents" role="main">
        <div className="wrapper marT16">
          <div id="lspGrapChart" className="lpsGraph pc marB24 buttonQuestion">
            <MainChart />
            <button className="btnChart" onClick={() => handleOpenModal(ModalName.LIFEPLAN_GRAPH_MODAL)}></button>
            <span className="leftCol">収入・支出</span>
            <span className="rightCol">貯蓄額</span>
          </div>
          <div className="sp guideTabAnalysis">
            <div className="guideImage">
              <img src={icoHumanL} className="step_image" />
            </div>
            <div className="guideText">
              画面を横にすると収支グラフを閲覧できます。
              <br />
              <span className="clickTab">（さらにPC版ではより快適に操作が可能です）</span>
            </div>
          </div>

          <div className="tabBox saleAItabBox">
            <ul className="tabBtn marB24">
              <li onClick={() => setTabIndex(0)} className={tabIndex === 0 ? 'hoverItem active' : 'hoverItem' } ><span>ライフイベント</span></li>
              <li onClick={() => {
                setTabIndex(1)
                let event = new Event("activeResult");
                window.dispatchEvent(event)
              }} className={tabIndex === 1 ? 'hoverItem active' : 'hoverItem' } ><span>分析結果</span></li>
            </ul>

            <div className="tabContents">
              <div className={`tabPanel lps-lifeEventGraph ${tabIndex === 0 ? 'active' : ''}`}>
                <LpsLifeEventGraphWidget age={personAge} numberOfPeople={numberOfPeople} lifePlan={lifePlanState} handleDragStartEvent={handleDragStartEvent} handleOpenModal={handleOpenModal} handleNextModal={handleNextModal} handleSubmitLifePlan={handleSubmitLifePlan} />
              </div>

              <div className={`tabPanel lps-resultBox ${tabIndex === 1 ? 'active' : ''}`}>
                <LpsResultBoxWidget loggedIn={loggedIn} setIsFadeIn={setIsFadeIn} lifePlan={lifePlanState} />
                {!loggedIn &&
                  <button onClick={ e => window.location.href=`/login?history_url=${window.location.href}` } type="submit" className={`linkBtnBlue fixed_entryBtn ${isFadeInFixedButton ? 'fixed-button-fadein' : 'fixed-button-fadeout'}`} id="fixed_entryBtn" >詳細を見る<br/>会員ログイン<br/>（無料会員登録）</button>
                }
              </div>
            </div>
          </div>
        </div>
      </main>

      <PrepareModalWidget lifeEventSelected={lifeEventSelected} handleSubmitLifePlan={handleSubmitLifePlan} handleNextModal={handleNextModal} handlePrevModal={handlePrevModal} step={step}/>
    </React.Fragment>
  )
}
