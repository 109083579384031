import React from 'react';
import { getIn, ErrorMessage } from 'formik';
import TextError from '../../../shared/components/error-message';

export default function SelectDisplayOption(props) {
  const { field, form, placeholder, disabled, options, width } = props;

  const name = field.name;

  function getStyles(errors, touched, name) {
    if (getIn(errors, name) && getIn(touched, name)) {
      return {
        border: '1px solid #FF5151',
        background: '#fcd9d4'
      }
    }
  }

  const handleChange = (e) => {
    const changeEvent = {
      target: {
        name: e.target.name,
        value: e.target.value
      }
    }
    if (props.onSelect) {
      field && field.onChange(changeEvent);
      props.onSelect(e.target.value);
    } else {
      field && field.onChange(changeEvent);
    }

    if (props.onChange) { props.onChange(e) }
  }

  return <React.Fragment>
    <ErrorMessage name={name} render={TextError} />
    <div className={`selectStyBox ${width ? width : ''}`}>
      <select
        {...props}
        id={name}
        {...field}
        style={getStyles(form.errors, form.touched, name)}
        onChange={handleChange}
      >
        {options.map((opt) => {
          return <option key={name + opt.id} data-id={opt.id} value={opt.code}>{opt.name}</option>}
        )}
      </select>
    </div>
  </React.Fragment>
}
