import React from 'react';

export default function RadioField(props) {
  const { field, form, options, labels } = props;

  const { name, value } = field;
  const errors = form.errors;
  const touched = form.touched;

  const showError = errors[name] && touched[name]

  const handleChange = (value) => {
    form.setFieldValue(name, value);
  }

  return (
    <>
      {options.map((opt, index) => (
        <div className="option" key={index}>
          <input
            onChange={() => handleChange(opt)}
            type="radio"
            id={name + opt}
            name={name}
            value={opt}
            checked={opt == value}
          />
          <label htmlFor={name + opt}>{labels[index]}</label>
        </div>
      ))}
    </>
  )
}
