import React, { useEffect, useMemo, useState } from "react";
import { LifePlansIcons } from "../../assets/icons";
import { getLifeEventMapping, ModalName } from "../../shared/constants/global";
import TimelineWidget from "../timeline-widget";
import { useNavigate } from "react-router-dom";

const {iconBuy, iconSell, iconCircle, iconSave} = LifePlansIcons;

export default function LpsLifeEventGraphWidget(props) {
  const navigate = useNavigate()
  const [inActiveSellHouse, setInActiveSellHouse] = useState(false);
  const { age, numberOfPeople, lifePlan} = props;

  const prepareData = useMemo(() => {
    return  getLifeEventMapping({
      age: age,
      number_of_people: numberOfPeople
    })
  }, [age, numberOfPeople])

  const onHandleDragStartEvent = (eventMapping, event) => {
    props.handleDragStartEvent && props.handleDragStartEvent(eventMapping, event);
  }

  const onHandleOpenModal = (name) => {
    props.handleOpenModal && props.handleOpenModal(name);
  }

  const onHandleNextModal = () => {
    props.handleNextModal && props.handleNextModal(0);
    navigate('edit')
  }

  const onHandleSubmitLifePlan = () => {
    props.handleSubmitLifePlan && props.handleSubmitLifePlan()
  }

  useEffect(() => {
    const { residence_type, life_event } = lifePlan;
    let isSellHouseExist = false;

    life_event.map(event => {
      if (event.event_type === 4) {
        isSellHouseExist = true;
      }
    })

    setInActiveSellHouse(residence_type === 2 || isSellHouseExist)
  }, [lifePlan])

  return (
    <>
      <div className="life_eventBox">
        <div className="life_eventContents">
          <TimelineWidget lifePlan={lifePlan}/>
        </div>
      </div>

      <div className="lps-eventBtnBox">
        <span className="toggle sp" id="lps-eventBtnToggle"></span>
        <ul className="lps-eventBtn">
          { Array.isArray(Object.values(prepareData)) && Object.values(prepareData).map((eventMapping, index) => {
            return (
              <li
                draggable={true}
                key={index} className={[eventMapping.type_name == "secondcarrier" && "secondcarrier", eventMapping.id === 4 && inActiveSellHouse && "inactive"].filter(e => !!e).join(' ')}
                onDragStart={(e) => onHandleDragStartEvent(eventMapping, e)}
              >
                <button onClick={() => onHandleOpenModal(eventMapping)}>
                <img src={require("../../assets/images/lps-" + eventMapping.type_name + ".png")} alt={eventMapping.name} />
                { eventMapping.is_buy && <img src={iconBuy} alt="BUY" className="ico" /> }
                { eventMapping.is_sell && <img src={iconSell} alt="BUY" className="ico" /> }
                <span>{eventMapping.name}</span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="popupBtnBox lps-eventActBtn">
        <ul className="btnList col2">
          <li><button type="button" className="btnSty-back" onClick={() => onHandleNextModal()}><img src={iconCircle} alt="" width="20" />基本情報編集</button></li>
          <li><button type="submit" className="linkBtn" onClick={onHandleSubmitLifePlan}><span><img src={iconSave} alt="" width="20" />保存</span></button></li>
        </ul>
      </div>
    </>
  )
}
