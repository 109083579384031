import React from 'react';
import { useTranslation } from 'react-i18next';
import DragSwitch from '../drag-switch';

export default function EducationField(props) {
  const { t } = useTranslation("familyModal");
  const { field, form, index, labelProp } = props;

  const { name, value } = field;

  const handleChangeEdu = (_index, _label, value) => {
    form.setFieldValue(name, value);
  };

  return (
    <React.Fragment>
      <DragSwitch
        labelProp={labelProp}
        value={value}
        label={t(`children.listLabel.${index}`)}
        onChange={handleChangeEdu}
        className={index == 3 ? 'univ' : ''}
      />
    </React.Fragment>
  );
}
