import { BrowserRouter } from "react-router-dom";
import App from './app';
import React from "react";
import { Provider } from 'react-redux';
import {store} from "./redux/store";
import "../src/assets/i18n/i18n";


export default function Index(props) {
  return <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
}